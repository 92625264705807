import { useState, useEffect, useCallback, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import homeLogo from '../images/i68-icon.png';
import { useNavigate, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import {
  IoMenuOutline,
  IoCloseOutline
} from 'react-icons/io5';
import {
  HiUserGroup
} from 'react-icons/hi';
import localization from '../localization';

// const useActiveElement = () => {
//   const [active, setActive] = useState(document.activeElement);
  
//   const handleFocusIn = (e) => {
//     console.log('document.activeElement', document.activeElement)
//     setActive(document.activeElement);
//   }
  
//   useEffect(() => {
//     document.addEventListener('focusin', handleFocusIn);
//     document.addEventListener('focusout', handleFocusIn);
//     return () => {
//       document.removeEventListener('focusin', handleFocusIn)
//       document.removeEventListener('focusout', handleFocusIn)
//     };
//   }, [])
  
//   return active;
// }

function Header({ user, logout, showHamburgerMenu, hamburgerMenuActive, onGroupChange }) {
  
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  let [selectedGroupId, setSelectedGroupId] = useState('');
  let [groups, setGroups] = useState([]);
  let location = useLocation();
  
  let navigate = useNavigate();
  
  useEffect(() => {
    
    if (!user.userId) {
      return;
    }
    
    fetchGroups();
    
  }, [user]);
  
  const selectGroupRef = useRef(null);
  
  const fetchGroups = async () => {
    try {
      if (selectedGroupId) {
        return;
      }
      let response = await (
        await window.fetch('/api/groups/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      setGroups(response.data);
      if (user.groups[0]) {
        setSelectedGroupId(user.groups[0]);
      } else if (response.data[0]) {
        setSelectedGroupId(response.data[0].groupId);
      }
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };
  
  useEffect(() => {
    if (selectedGroupId) {
      
    }
  }, [selectedGroupId]);
  
  // const focusedElement = useActiveElement();
  const [selectEnabled, setSelectEnabled] = useState(false);
  
  useEffect(async () => {
    console.log('location.pathname', location.pathname)
    setSelectEnabled(['/admin/slots', '/home'].includes(location.pathname));
  }, [location]);
  
  const handleGroupChange = (value) => {
    setSelectedGroupId(Number(value));
    if (onGroupChange) {
      onGroupChange(Number(value));
    }
    // setSelectFocused(false);
    // fetchLocationsOfGroups(Number(value));
  };
  
  // useEffect(() => {
  //   if (selectGroupRef.current) {
  //     if (selectFocused) {
  //       selectGroupRef.current.focus();
  //       console.log('clicking..', selectGroupRef.current)
  //       // console.log(selectGroupRef.current.click());
  //       if (isMobile) {
  //         const event = new Event('touchstart');
  //       selectGroupRef.current.dispatchEvent(event);
  //       // selectGroupRef.current.initTouchEvent();
  //       } else {
  //       const event = new MouseEvent('mousedown', {bubbles: true});
  //       selectGroupRef.current.dispatchEvent(event);
  //       }
  //       setTimeout(() => {
  //         // console.log(selectGroupRef.current.click());
  //         const event = new MouseEvent('mousedown', {bubbles: true});
  //       selectGroupRef.current.dispatchEvent(event);
  //       }, 1000)
  //     } else {
  //       selectGroupRef.current.blur();
  //     }
  //   }
  // }, [selectFocused]);
  
  if (!user) {
    return;
  }
  
  return (
    <div className="p-1" style={{boxShadow: "0px 15px 10px -15px #d7d7d7"}}>
      <div className="d-flex justify-content-between align-items-center">
        <a href="javascript:void(0)"
          onClick={() => navigate('/home')}><img
          src={homeLogo}
          className="cursor-pointer"
          alt="i68"
          width="42"
          height="42"
        /></a>
        <div className="d-flex align-items-center footer-menu-items">
          <div className={`${isMobile ? '' : 'me-3'} ${user.gender === 1 ? 'male-color' : 'female-color'} fw-bold text-end`}>
            <div>{user.name}</div>
            {selectedGroupId ?
              <div className="text-secondary fs-6 fw-bolder position-relative">
                <label for="group" className="position-relative mt-1 d-flex align-items-center justify-content-end">
                  <span className="d-inline-block">{groups.find(group => group.groupId === selectedGroupId).groupName}</span>
                  <span className="d-inline-block fs-4 ms-2"><HiUserGroup /></span>
                </label>
                <div id="group" className={`${selectEnabled ? 'opacity-0' : 'd-none'} position-absolute top-0 right-0 mt-1`}>
                  {(user.admin === 1 || user.admin === 2) && (
                    <Form.Select
                      className="cursor-pointer"
                      role="button"
                      ref={selectGroupRef}
                      title={localization['Choose Group']}
                      value={selectedGroupId}
                      onChange={(e) => handleGroupChange(e.target.value)}
                    >
                      {groups.map((item, i) => {
                        return <option key={item.groupId} value={item.groupId}>{item.groupName}</option>;
                      })}
                    </Form.Select>
                  )}
                  {(user.admin === 0 || user.admin === 3) && (
                    <Form.Select
                      className="cursor-pointer"
                      role="button"
                      ref={selectGroupRef}
                      title={localization['Choose Group']}
                      value={selectedGroupId}
                      onChange={(e) => handleGroupChange(e.target.value)}
                    >
                      {groups.filter(group => user.groups.includes(group.groupId)).map((item, i) => {
                        return <option key={item.groupId} value={item.groupId}>{item.groupName}</option>;
                      })}
                    </Form.Select>
                  )}
                </div>
              </div>
            : 
              <div className="text-secondary fs-6 fw-bolder position-relative">
                <label for="group" className="position-relative mt-1 d-flex align-items-center justify-content-end">
                  <span className="d-inline-block">&nbsp;</span>
                  <span className="d-inline-block fs-4 ms-2"><HiUserGroup /></span>
                </label>
              </div>
            }
          </div>
          {!isMobile && <div > 
            <div className="container nav-container align-items-center cursor-pointer">
              <label htmlFor="chk_ham"></label>
               <a
                href="javascript:void(0)"
                onClick={showHamburgerMenu}
                className="ham text-black "
              >
                {!hamburgerMenuActive ?
                  <IoMenuOutline />
                :
                  <IoCloseOutline />
                }
              </a>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
  
}

export default Header;