import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import localization from '../../localization';
import Pusher from 'pusher-js';
import Modal from 'react-bootstrap/Modal';
import homeLogo from '../../images/i68-icon.png';
import HeaderNav from '../HeaderNav';
import { isMobile } from 'react-device-detect';
import FooterNav from '../FooterNav';
//import Announcements from '../Announcements';
import Cookies from 'universal-cookie';
import Header from '../Header';

function Users({ user, logout }) {
  let navigate = useNavigate();
  let [users, setUsers] = useState([]);
  let [groups, setGroups] = useState([]);
  let [newUser, setNewUser] = useState({
    name: '',
    phone: '',
    password: '',
    groups: [],
    gender: '',
    email: '',
    language: '',
    emailType: '',
  });
  let [editUser, setEditUser] = useState({});
  let [activeEdit, setActiveEdit] = useState('');
  let [warning, setWarning] = useState('');
  let [success, setSuccess] = useState('');
  let [showAdminPopup, setShowAdminPopup] = useState(0);
  let [adminRole, setAdminRole] = useState(0);
  let [thisUserId, setThisUserId] = useState(0);
  // const [groupName, setGroupName] = useState('');
  // const [groupsNames, setGroupsNames] = useState([]);
  const [increment, setIncrement] = useState(1); // Force Re-Render
  const [currentPageName, setCurrentPageName] = useState('users');
  let [selectedGroupId, setSelectedGroupId] = useState('');
  let [searchText, setSearchText] = useState('');
  let [allUsersData, setAllUsersData] = useState([]);
  // const menuRef = useRef(null);
  let [showAnnouncementBar, setShowAnnouncementBar] = useState(true);
  const cookies = new Cookies();

  const fetchUsers = async () => {
    try {
      let response = await (
        await window.fetch('/api/admin/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: user.userId,
            groups: user.groups,
            adminType: user.admin,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      // let filteredUsers = response.data;
      // if(user.admin == 3) {
      //   filteredUsers = response.data.filter(item => item.groupId == user.groupId);
      // }
      let tempUsers = response.data.filter(
        (item) => item.userId != user.userId
      );
      setUsers(tempUsers.filter((element) => {
        return (
          (element.phone && element.phone.toLowerCase().includes(searchText.toLowerCase())) ||
          (element.name && element.name.toLowerCase().includes(searchText.toLowerCase())) ||
          (element.email && element.email.toLowerCase().includes(searchText.toLowerCase()))
        );
      }));
      setAllUsersData(tempUsers);
      // setUsers(filteredUsers.filter(item => item.userId != user.userId));
      if (user.admin == 3) {
        if (user.groupId) {
          handleChange([user.groupId], 'groups');
        } else {
          handleChange(user.groups, 'groups');
        }
      }
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  const fetchGroupUsers = async (groupId) => {
    if (!groupId) {
      await fetchUsers();
      return;
    }
    try {
      let response = await (
        await window.fetch('/api/admin/groups/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: user.userId,
            groupId: groupId,
            adminType: user.admin,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      // let filteredUsers = response.data;
      // if(user.admin == 3) {
      //   filteredUsers = response.data.filter(item => item.groupId == user.groupId);
      // }
      let tempUsers = response.data.filter(
        (item) => item.userId != user.userId
      );
      setUsers(tempUsers.filter((element) => {
        return (
          (element.phone && element.phone.toLowerCase().includes(searchText.toLowerCase())) ||
          (element.name && element.name.toLowerCase().includes(searchText.toLowerCase())) ||
          (element.email && element.email.toLowerCase().includes(searchText.toLowerCase()))
        );
      }));
      setAllUsersData(tempUsers);
      // setUsers(filteredUsers.filter(item => item.userId != user.userId));
      // if(user.admin == 3) {
      //   handleChange(user.groupId, 'groupId');
      // }
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  const sleep = seconds => new Promise(resolve => setTimeout(resolve, seconds * 1000));

  const fetchGroups = async () => {
    try {
      let response = await (
        await window.fetch('/api/groups/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }

      setGroups(response.data);
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  useEffect(async () => {
    setActiveEdit('');
    setEditUser({});
    setNewUser({});
    fetchUsers();
    fetchGroups();
    // getGroupName();
    // localization.setLanguage(cookies.get('language') || user.language);
    // if (cookies.get('language') != undefined && cookies.get('language') != '') {
    //   await setLanguage(cookies.get('language'));
    // }
  }, []);

  const addUser = async (e) => {
    console.log('user', user);
    // if (user.admin == 3) {
    //   if (user.groupId) {
    //     handleChange([user.groupId], 'groups');
    //   } else {
    //     handleChange(user.groups, 'groups');
    //   }
    // }
    // if (user.admin == 3) {
    //   await sleep(0.2);
    // }
    console.log('newUser', newUser);
    if (newUser.name == undefined || !newUser.name.trim()) {
      setWarning(localization['Enter'] + ` ` + localization['Name']);
      return;
    } else if (newUser.phone == undefined || !newUser.phone.trim()) {
      setWarning(localization['Enter'] + ` ` + localization['Phone Number']);
      return;
    } else if (newUser.password == undefined || !newUser.password.trim()) {
      setWarning(localization['Enter'] + ` ` + localization['Password']);
      return;
    } else if (newUser.groups == undefined || newUser.groups.length <= 0) {
      setWarning(localization['Select Groups']);
      return;
    } else if (newUser.gender == undefined || !newUser.gender.trim()) {
      setWarning(localization['Choose Gender']);
      return;
    } else if (newUser.language == undefined || !newUser.language.trim()) {
      setWarning(localization['Choose Language']);
      return;
    } else if (newUser.email == undefined || !newUser.email.trim()) {
      setWarning(localization['Enter'] + ` ` + localization['Email']);
      return;
    } else if (newUser.emailType == undefined || newUser.emailType == '') {
      setWarning(localization['Choose Email Notification']);
      return;
    }
    e.target.disabled = true;
    try {
      let response = await (
        await window.fetch('/api/admin/users/new', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: newUser,
            userId: user.userId,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status == 'hasdata') {
        alert('Phone number already exists. Please check.');
        e.target.disabled = false;
        return;
      }
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      if (!selectedGroupId) {
        fetchUsers();
      } else {
        fetchGroupUsers(selectedGroupId);
      }
      setSuccess(
        localization['User'] + ` ` + localization['created succesfully']
      );
      setNewUser({
        name: '',
        phone: '',
        password: '',
        groups: [],
        gender: newUser.gender,
        language: '',
        email: '',
        emailType: '',
      });
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
    e.target.disabled = false;
  };

  const handleChange = (value, name) => {
    let user = { ...newUser };
    user[`${name}`] = value;
    console.log('name', name, 'user', user, 'value', value);
    setNewUser(user);
  };
  
  const handleMultiSelectChange = (target, name) => {
    let user = { ...newUser };
    user[`${name}`] = [...target.selectedOptions].map(option => Number(option.value));
    console.log('name', name, 'user', user, 'target', target);
    setNewUser(user);
  };

  const editHandleChange = (value, name) => {
    let user = { ...editUser };
    user[`${name}`] = value;
    setEditUser(user);
  };
  
  const editHandleMultiSelectChange = (target, name) => {
    let user = { ...editUser };
    user[`${name}`] = [...target.selectedOptions].map(option => Number(option.value));
    setEditUser(user);
  };

  const onUpdateUser = async (e) => {
    e.target.disabled = true;
    try {
      let response = await (
        await window.fetch('/api/admin/users/update', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: editUser,
            userId: user.userId,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status == 'hasdata') {
        alert('Phone number already exists. Please check.');
        e.target.disabled = false;
        return;
      }
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      setActiveEdit('');
      setEditUser({});
      fetchGroupUsers(selectedGroupId);
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
    e.target.disabled = false;
  };

  const onEditUser = (item) => {
    setActiveEdit(`${item.userId}`);
    item.language = item.language == null ? 'en' : item.language;
    setEditUser(item);
  };

  const adminPopup = async (userId) => {
    setShowAdminPopup(1);
    setThisUserId(userId);
    //await makeAdmin(userId);
  };
  const makeAdmin = async (userId) => {
    if (userId == 0) {
      setShowAdminPopup(0);
      setThisUserId(0);
      return;
    }
    try {
      let response = await (
        await window.fetch('/api/admin/users/make-admin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId,
            adminId: user.userId,
            roleValue: adminRole,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      if (!selectedGroupId) {
        fetchUsers();
      } else {
        fetchGroupUsers(selectedGroupId);
      }
      setShowAdminPopup(0);
      setThisUserId(0);
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  const unmakeAdmin = async (userId) => {
    try {
      let response = await (
        await window.fetch('/api/admin/users/unmake-admin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId,
            adminId: user.userId,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      if (!selectedGroupId) {
        fetchUsers();
      } else {
        fetchGroupUsers(selectedGroupId);
      }
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  const deleteUser = async (userId) => {
    try {
      if (
        !window.confirm(
          localization['Are you sure you want to delete the user?']
        )
      ) {
        return;
      }
      let response = await (
        await window.fetch('/api/admin/users/delete', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId,
            adminId: user.userId,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      if (!selectedGroupId) {
        fetchUsers();
      } else {
        fetchGroupUsers(selectedGroupId);
      }
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  // const getGroupName = async () => {
  //   try {
  //     let response = await (
  //       await window.fetch('/api/groups/names', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           userId: user.userId,
  //         }),
  //         credentials: 'include',
  //       })
  //     ).json();
  //     if (response.status !== 'success') {
  //       throw Error(response.message);
  //     }
  //     // setGroupName(response.data[0]['groupName']);
  //     setGroupsNames(response.data.map(item => item.groupName));
  //   } catch (err) {
  //     setWarning(localization['Something went wrong']);
  //   }
  // };

  // const setLanguage = async (lang) => {
  //   try {
  //     let response = await (
  //       await window.fetch('/api/admin/users/set-language', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           userId: user.userId,
  //           language: lang,
  //         }),
  //         credentials: 'include',
  //       })
  //     ).json();
  //     if (response.status !== 'success') {
  //       throw Error(response.message);
  //     } else {
  //       user.language = lang;
  //     }
  //   } catch (err) {
  //     setWarning(localization['Something went wrong']);
  //   }
  // };

  const resendEmail = async (e, userId) => {
    e.target.disabled = true;
    try {
      let response = await (
        await window.fetch('/api/admin/users/resend/email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      } else {
        setSuccess('Email Sent');
      }
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
    e.target.disabled = false;
  };
  const home = () => navigate('/home');
  
  const [hamburgerMenuActive, setHamburgerMenuActive] = useState(false);
  const showHamburgerMenu = () => {
    setHamburgerMenuActive(!hamburgerMenuActive);
    // menuRef.current.click();
  };

  const handleGroupChange = (value) => {
    setSelectedGroupId(value);
    fetchGroupUsers(value);
    // fetchLocationsOfGroups(value);
  };

  const onSearchChange = (value) => {
    setSearchText(value);
    let tempUsers = allUsersData.filter((element) => {
      return (
        (element.phone && element.phone.toLowerCase().includes(value.toLowerCase())) ||
        (element.name && element.name.toLowerCase().includes(value.toLowerCase())) ||
        (element.email && element.email.toLowerCase().includes(value.toLowerCase()))
      );
    });
    setUsers(tempUsers);
  };

  const onStateChange = async (e, userId, toState) => {
    e.target.disabled = true;
    try {
      // if (!window.confirm('Are you sure?')) {
      //   return;
      // }
      let response = await (
        await window.fetch('/api/admin/users/changestate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId,
            adminId: user.userId,
            state: toState,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      fetchGroupUsers(selectedGroupId);
      e.target.disabled = false;
    } catch (err) {
      setWarning(localization['Something went wrong']);
      e.target.disabled = false;
    }
  };

  const closeAnnouncementBar = () => {
    setShowAnnouncementBar(false);
  };

  return (
    <div className="home-wrapper">
      {/*showAnnouncementBar &&
      <Announcements user={user} onClose={closeAnnouncementBar}/>
    */}
      <Header 
        user={user}
        logout={logout}
        currentPage={currentPageName}
        showHamburgerMenu={showHamburgerMenu}
        hamburgerMenuActive={hamburgerMenuActive}
      />
      {/*Must wrap in navbar and nav-container in order to work*/}
      <nav className="navbar">
        <div className="nav-container">
          <HeaderNav
            user={user}
            logout={logout}
            showHamburgerMenu={showHamburgerMenu}
            hamburgerMenuActive={hamburgerMenuActive}
          />
        </div>
      </nav>
      <div>
        <div className="header-dropdown-wrapper">
          <h2>{localization['Users']}</h2>
          <Form.Select
            className="ms-4 usergroup-dropdown"
            value={selectedGroupId}
            onChange={(e) => handleGroupChange(Number(e.target.value))}
          >
            <option value={''}>
              {localization['Show All']} {localization['Groups']}
            </option>
            {(user.admin === 3 ? groups.filter(group => user.groups.includes(group.groupId)) : groups).map((item, i) => {
              return <option key={item.groupId} value={item.groupId}>{item.groupName}</option>;
            })}
          </Form.Select>
          <Form.Control
            placeholder={localization['Search']}
            className="search-textbox  ms-4"
            type="text"
            value={searchText}
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </div>
        <hr />
      </div>
      {success && (
        <div className="bg-success user-alert rounded  my-2 text-light px-2 py-1">
          <div>
            {success}{' '}
            <span
              style={{ cursor: 'pointer' }}
              className="float-end px-2"
              onClick={(e) => setSuccess('')}
            >
              x
            </span>
          </div>
        </div>
      )}
      {warning && (
        <div className="bg-warning user-alert rounded  my-2 text-light px-2 py-1">
          <div>
            {warning}{' '}
            <span
              style={{ cursor: 'pointer' }}
              className="float-end px-2"
              onClick={(e) => setWarning('')}
            >
              x
            </span>
          </div>
        </div>
      )}
      {/*<header className="header-inner d-none">
        <nav className="navbar">
          <div className="container nav-container">
            <input
              className="checkbox"
              type="checkbox"
              name=""
              id="chk_ham"
              ref={menuRef}
            />
            <label htmlFor="chk_ham"></label>
            <div className="hamburger-lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>

            <HeaderNav
              user={user}
              logout={logout}
              showHamburgerMenu={showHamburgerMenu}
              hamburgerMenuActive={hamburgerMenuActive}
            />
          </div>
        </nav>
        <div className="d-flex justify-content-end mb-3 nav-custom">
          <Nav variant="pills">
            <Nav.Item>
              <Form.Select
                className="d-inline-block w-auto align-middle me-2 language-dropdown"
                onChange={(e) => {
                  let language = e.target.value;
                  localization.setCookieLanguage(language);
                  setIncrement(increment + 1);
                  setLanguage(language);
                }}
              >
                {localization.getAvailableLanguages().map((language) => {
                  return (
                    <option
                      key={language}
                      value={language}
                      selected={localization.getLanguage() === language}
                    >
                      {language.toUpperCase()}
                    </option>
                  );
                })}
              </Form.Select>
            </Nav.Item>
          </Nav>
        </div>

        <div>
          <img
            onClick={home}
            src={homeLogo}
            alt="i68"
            className="home-button"
          />
          <div className="name-location">
            <strong>
              <span
                className={user.gender == 2 ? 'female-color' : 'male-color'}
              >
                {user.name}
              </span>
              {{groupsNames.length > 0 && groupsNames.map((groupName, i) => {
                return (
                  <span> {groupName}{groupsNames[i + 1] ? ',' : ''}</span>
                );
              })}}
            </strong>
          </div>
        </div>
      </header>*/}
      <section className="home-body mt-2 w-100 overflow-scroll">
        <Table
          striped
          bordered
          hover
          size="md"
        >
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>{localization['Name']}</th>
              <th>{localization['Primary Group']}</th>
              <th>{localization['Groups']}</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>{localization['Phone Number']}</th>
              <th>{localization['Password']}</th>
              <th>{localization['Gender']}</th>
              <th>{localization['Language']}</th>
              <th>{localization['Email']}</th>
              <th>{localization['Email Notification']}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <th>&nbsp;</th>
              <td>
                <Form.Control
                  type="text"
                  title="Enter Name"
                  value={newUser.name}
                  onChange={(e) => handleChange(e.target.value, 'name')}
                />
              </td>
              <td>
                {newUser.groups && newUser.groups.length > 0 &&
                  <Form.Select
                    title={localization['Select Group']}
                    value={newUser.groups[0]}
                    onChange={(e) => {
                      console.log('changed??')
                      let newFirstGroupId = Number(e.target.value);
                      let user = {...newUser};
                      let backupGroups = [...user.groups];
                      user.groups = [];
                      if (newFirstGroupId) {
                        user.groups.push(newFirstGroupId);
                      }
                      user.groups = user.groups.concat(backupGroups.filter(groupId => groupId !== newFirstGroupId));
                      setNewUser(user);
                    }}
                    // disabled={![3, 1].includes(user.admin)}
                  >
                    {groups.filter(group => newUser.groups.includes(group.groupId)).map((item, i) => {
                      return (
                        <option key={item.groupId} value={item.groupId} selected={item.groupId === newUser.groups[0]}>{item.groupName}</option>
                      );
                    })}
                  </Form.Select>
                }
              </td>
              <td>
                <Form.Select
                  multiple
                  title={localization['Select Groups']}
                  value={newUser.groups}
                  onChange={(e) => handleMultiSelectChange(e.target, 'groups')}
                  // disabled={![3, 1].includes(user.admin)}
                >
                  {(user.admin === 3 ? groups.filter(group => user.groups.includes(group.groupId)) : groups).map((item, i) => {
                    return (
                      <option key={item.groupId} value={item.groupId}>{item.groupName}</option>
                    );
                  })}
                </Form.Select>
              </td>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <td>
                <Form.Control
                  type="text"
                  title="Enter phone"
                  value={newUser.phone}
                  onChange={(e) => handleChange(e.target.value, 'phone')}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  title="Enter password"
                  value={newUser.password}
                  onChange={(e) => handleChange(e.target.value, 'password')}
                />
              </td>
              <td>
                <Form.Check
                  type="radio"
                  name={'radio'}
                  id="radio_male"
                  label={localization['Male']}
                  value={1}
                  onChange={(e) => handleChange(e.target.value, 'gender')}
                />
                <Form.Check
                  type="radio"
                  name={'radio'}
                  id="radio_female"
                  label={localization['Female']}
                  value={2}
                  onChange={(e) => handleChange(e.target.value, 'gender')}
                />
              </td>
              <td>
                <Form.Select
                  title={localization['Choose Language']}
                  value={newUser.language}
                  onChange={(e) => handleChange(e.target.value, 'language')}
                >
                  <option value={''}>{localization['Choose Language']}</option>
                  {localization.getAvailableLanguages().map((language) => {
                    return (
                      <option key={language} value={language}>{language.toUpperCase()}</option>
                    );
                  })}
                </Form.Select>
              </td>
              <td>
                <Form.Control
                  type="email"
                  title="Enter email"
                  value={newUser.email}
                  onChange={(e) => handleChange(e.target.value, 'email')}
                />
              </td>
              <td>
                <Form.Select
                  value={newUser.emailType}
                  onChange={(e) => handleChange(e.target.value, 'emailType')}
                >
                  <option value={''}></option>
                  <option value={1}>Automatic login</option>
                  <option value={2}>Login with credentials</option>
                </Form.Select>
              </td>
              <td>
                <Button
                  variant="outline-secondary"
                  onClick={(e) => addUser(e)}
                >
                  {localization['Add'] + ' ' + localization['User']}
                </Button>
              </td>
            </tr>
            {users.map((item, i) => {
              
              let primaryGroup = groups.find(group => item.groups[0] && group.groupId === item.groups[0]);
              
              return (
                <tr key={item.userId}>
                  <td>{i + 1}</td>
                  <td>
                    {item.admin !== 1 && (
                      <>
                        {item.state == 0 ? (
                          <>
                            <Button
                              variant="outline-secondary text-white btn-danger"
                              onClick={(e) => onStateChange(e, item.userId, 1)}
                            >
                              {localization['Activate']}
                            </Button>{' '}
                            <br />
                            {/* <Button variant="outline-secondary text-white btn-danger" onClick={e => onStateChange(e,item.userId,1)}>{localization['activate'] + 'Send Email'}</Button> */}
                          </>
                        ) : (
                          <Button
                            variant="outline-secondary text-white btn-success"
                            onClick={(e) => onStateChange(e, item.userId, 0)}
                          >
                            {localization['Deactivate']}
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    {item.userId == activeEdit ? (
                      <Form.Control
                        type="text"
                        value={editUser.name}
                        onChange={(e) =>
                          editHandleChange(e.target.value, 'name')
                        }
                      />
                    ) : (
                      item.name
                    )}
                  </td>
                  <td>
                    {item.userId == activeEdit ? (
                      <React.Fragment>
                        {editUser.groups && editUser.groups.length > 0 &&
                          <Form.Select
                            title={localization['Select Group']}
                            value={editUser.groups[0]}
                            onChange={(e) => {
                              let newFirstGroupId = Number(e.target.value);
                              let user = {...editUser};
                              let backupGroups = [...user.groups];
                              user.groups = [];
                              if (newFirstGroupId) {
                                user.groups.push(newFirstGroupId);
                              }
                              user.groups = user.groups.concat(backupGroups.filter(groupId => groupId !== newFirstGroupId));
                              setEditUser(user);
                            }}
                            // disabled={![3, 1].includes(user.admin)}
                          >
                            {groups.filter(group => editUser.groups.includes(group.groupId)).map((item, i) => {
                              return (
                                <option key={item.groupId} value={item.groupId} selected={item.groupId === editUser.groups[0]}>{item.groupName}</option>
                              );
                            })}
                          </Form.Select>
                        }
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {primaryGroup &&
                          <Form.Select
                            title={localization['Select Group']}
                            value={primaryGroup.groupId}
                            disabled
                          >
                            <option value={primaryGroup.groupId} selected={true}>{primaryGroup.groupName}</option>
                          </Form.Select>
                        }
                      </React.Fragment>
                    )}
                  </td>
                  <td>
                    {item.userId == activeEdit ? (
                      <Form.Select
                        multiple
                        title={localization['Select Groups']}
                        value={editUser.groups}
                        onChange={(e) => editHandleMultiSelectChange(e.target, 'groups')}
                        // disabled={![3, 1].includes(user.admin)}
                      >
                        {(user.admin === 3 ? groups.filter(group => user.groups.includes(group.groupId)) : groups).map((item, i) => {
                          return (
                            <option key={item.groupId} value={item.groupId}>
                              {item.groupName}
                            </option>
                          );
                        })}
                      </Form.Select>
                    ) : (
                      <Form.Select
                        multiple
                        title={localization['Select Groups']}
                        value={item.groups}
                        disabled
                      >
                        {(user.admin === 3 ? groups.filter(group => user.groups.includes(group.groupId)) : groups).map((item, i) => {
                          return (
                            <option key={item.groupId} value={item.groupId}>
                              {item.groupName}
                            </option>
                          );
                        })}
                      </Form.Select>
                    )}
                  </td>
                  <td>
                    {item.admin !== 1 && item.admin !== 0 ? ( //1
                      <Button
                        variant="outline-primary"
                        onClick={(e) => unmakeAdmin(item.userId)}
                      >
                        {localization['Remove'] + ' ' + localization['Admin']}
                      </Button>
                    ) : null}
                    {item.admin == 0 && (
                      <Button
                        variant="outline-warning"
                        onClick={(e) => adminPopup(item.userId)}
                      >
                        {localization['Make'] + ' ' + localization['Admin']}
                      </Button>
                    )}
                  </td>
                  <td>
                    {user.admin !== 1 && item.admin === 1 ?
                      <React.Fragment>
                      
                      </React.Fragment>
                    :
                      <React.Fragment>
                        {item.userId == activeEdit ? (
                          <Button
                            variant="outline-secondary"
                            onClick={(e) => onUpdateUser(e)}
                          >
                            {localization['Save'] + ' ' + localization['User']}
                          </Button>
                        ) : (
                          <Button
                            variant="outline-secondary"
                            onClick={(e) => onEditUser(item)}
                          >
                            {localization['Edit'] + ' ' + localization['User']}
                          </Button>
                        )}
                      </React.Fragment>
                    }
                  </td>
                  <td>
                    {item.admin !== 1 && (
                      <Button
                        variant="outline-danger"
                        onClick={(e) => deleteUser(item.userId)}
                      >
                        {localization['Delete']}
                      </Button>
                    )}
                  </td>
                  <td>
                    {item.userId == activeEdit ? (
                      <Form.Control
                        type="text"
                        value={editUser.phone}
                        onChange={(e) =>
                          editHandleChange(e.target.value, 'phone')
                        }
                      />
                    ) : (
                      item.phone
                    )}
                  </td>
                  <td>
                    {user.admin !== 1 && item.admin === 1 ?
                      <React.Fragment>
                        
                      </React.Fragment>
                    : 
                      <React.Fragment>
                        {item.userId == activeEdit ? (
                          <Form.Control
                            type="text"
                            value={editUser.password}
                            onChange={(e) =>
                              editHandleChange(e.target.value, 'password')
                            }
                          />
                        ) : (
                          item.password
                        )}
                      </React.Fragment>
                    }
                  </td>
                  <td>
                    {item.userId == activeEdit ? (
                      <>
                        <Form.Check
                          type="radio"
                          name={'radio_edit_user'}
                          id="radio_edit_male"
                          label={localization['Male']}
                          value={1}
                          onChange={(e) =>
                            editHandleChange(e.target.value, 'gender')
                          }
                          defaultChecked={item.gender == 1}
                        />
                        <Form.Check
                          type="radio"
                          name={'radio_edit_user'}
                          id="radio_edit_female"
                          label={localization['Female']}
                          value={2}
                          onChange={(e) =>
                            editHandleChange(e.target.value, 'gender')
                          }
                          defaultChecked={item.gender == 2}
                        />
                      </>
                    ) : (
                      <>
                        {item.gender == 1 ? localization['Male'] : null}
                        {item.gender == 2 ? localization['Female'] : null}
                      </>
                    )}
                  </td>
                  <td>
                    {item.userId == activeEdit ? (
                      <Form.Select
                        title={localization['Choose Language']}
                        value={editUser.language}
                        onChange={(e) =>
                          editHandleChange(e.target.value, 'language')
                        }
                      >
                        {localization
                          .getAvailableLanguages()
                          .map((language) => {
                            return (
                              <option
                                key={language}
                                value={language}
                                selected={language == item.language}
                              >
                                {language.toUpperCase()}
                              </option>
                            );
                          })}
                      </Form.Select>
                    ) : (
                      <Form.Select
                        title={localization['Choose Language']}
                        value={item.langauge}
                        disabled
                      >
                        {localization
                          .getAvailableLanguages()
                          .map((language) => {
                            return (
                              <option
                                key={language}
                                value={language}
                                selected={language == item.language}
                              >
                                {language.toUpperCase()}
                              </option>
                            );
                          })}
                      </Form.Select>
                    )}
                  </td>
                  <td>
                    <div className="email-container">
                      {item.userId == activeEdit ? (
                        <Form.Control
                          type="text"
                          value={editUser.email}
                          onChange={(e) =>
                            editHandleChange(e.target.value, 'email')
                          }
                        />
                      ) : (
                        item.email
                      )}
                    </div>
                  </td>
                  <td>
                    {item.state != 0 && item.emailType && (
                      <>
                        {item.userId == activeEdit ? (
                          <Form.Select
                            value={editUser.emailType}
                            onChange={(e) =>
                              editHandleChange(e.target.value, 'emailType')
                            }
                          >
                            <option
                              value={1}
                              selected={item.emailType == 1}
                            >
                              Automatic login
                            </option>
                            <option
                              value={2}
                              selected={item.emailType == 2}
                            >
                              Login with credentials
                            </option>
                          </Form.Select>
                        ) : (
                          <>
                            {item.emailType == 1 && (
                              <span>
                                {' '}
                                {localization['Automatic login']}
                                <br />
                              </span>
                            )}
                            {item.emailType == 2 && (
                              <span>
                                {' '}
                                {localization['Login with credentials']}
                                <br />
                              </span>
                            )}
                            <Button
                              variant="outline-secondary"
                              onClick={(e) => resendEmail(e, item.userId)}
                            >
                              {localization['Send']}
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </td>
                  <td></td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {showAdminPopup == 1 ? (
          <div className="overlay">
            <div className="popup">
              {(user.admin == 1 || user.admin == 2) && (
                <Form.Check
                  type="radio"
                  name={'radio_admintype'}
                  id="radio_app_admin"
                  label="App Admin"
                  value={2}
                  onChange={(e) => setAdminRole(e.target.value)}
                />
              )}
              <Form.Check
                type="radio"
                name={'radio_admintype'}
                id="radio_group_admin"
                label="Group Admin"
                value={3}
                onChange={(e) => setAdminRole(e.target.value)}
              />{' '}
              <br />
              <Button
                variant="primary"
                className="btn btn-primary"
                onClick={(e) => makeAdmin(thisUserId)}
              >
                Set
              </Button>
              <Button
                variant="outline-danger"
                onClick={(e) => setShowAdminPopup(0)}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : null}
      </section>
      <footer>
        <FooterNav
          user={user}
          logout={logout}
          currentPage={currentPageName}
          showHamburgerMenu={showHamburgerMenu}
          hamburgerMenuActive={hamburgerMenuActive}
        />
      </footer>
    </div>
  );
}

function NewUser({ hideModal }) {
  return (
    <Modal.Dialog>
      <Modal.Header closeButton>
        <Modal.Title>Modal title</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Modal body text goes here.</p>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={hideModal}
        >
          Close
        </Button>
        <Button variant="primary">Save changes</Button>
      </Modal.Footer>
    </Modal.Dialog>
  );
}

export default Users;
