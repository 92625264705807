import Cookies from 'universal-cookie';
const moment = require('moment-timezone');

const cookies = new Cookies();

moment.tz.setDefault(cookies.get('timezone') || moment.tz.guess());
moment.setCookieTimezone = (timezone) => {
  cookies.set('timezone', timezone, { path: '/' });
  moment.tz.setDefault(timezone);
};

window.moment = moment;

export default moment;