import {
  useState,
  useEffect,
  useCallback,
  createRef,
  useRef,
  forwardRef,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import localization from '../../localization';
import Pusher from 'pusher-js';
import moment from '../../moment.js';
import { isMobile } from 'react-device-detect';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import setMinutes from 'date-fns/setMinutes';
import setHours from 'date-fns/setHours';
import homeLogo from '../../images/i68-icon.png';
import HeaderNav from '../HeaderNav';
import FooterNav from '../FooterNav';
import Cookies from 'universal-cookie';
// import { Dayjs } from 'dayjs';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import Header from '../Header';
import { FormGroup, FormControlLabel, Switch, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { AiFillCaretUp, AiFillCaretDown, AiOutlineDownload } from 'react-icons/ai';
import { FaFileDownload } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

let pusher = new Pusher('b534d4fac76717b9872e', {
  cluster: 'us2',
  encrypted: true,
});

let channel = pusher.subscribe('channel');

const sleep = sec => new Promise(resolve => setTimeout(resolve, sec * 1000));

const exportToXLSX = (input, fileName) => {
  const ws = XLSX.utils.json_to_sheet(input);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
  FileSaver.saveAs(data, fileName + '.xlsx');
};

function Slots({ user, logout }) {
  let navigate = useNavigate();
  const navigationData = useLocation();
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const [locations, setLocations] = useState([]);
  const [activeLocation, setActiveLocation] = useState({});
  const [activeEditLocationId, setActiveEditLocationId] = useState(null);
  const [newLocation, setNewLocation] = useState({});
  const [increment, setIncrement] = useState(1); // Force Re-Render
  // const [groupName, setGroupName] = useState('');
  // const [groupsNames, setGroupsNames] = useState([]);
  let [groups, setGroups] = useState([]);
  let [selectedGroupId, setSelectedGroupId] = useState('');
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [currentPageName, setCurrentPageName] = useState('slots');
  // const menuRef = useRef(null);
  const cookies = new Cookies();

  useEffect(async () => {
    fetchGroups();
    // getGroupName();
    // localization.setLanguage(cookies.get('language') || user.language);
    // if (cookies.get('language') != undefined && cookies.get('language') != '') {
    //   await setLanguage(cookies.get('language'));
    // }
  }, []);
  
  const fetchGroups = async () => {
    try {
      // let { groupId } = getIdEditLocations();
      if (selectedGroupId) {
        return;
      }
      let response = await (
        await window.fetch('/api/groups/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      setGroups(response.data);
      if (user.groups[0]) {
        setSelectedGroupId(user.groups[0]);
        fetchLocations({ groupId: user.groups[0] });
      } else if (response.data[0]) {
        setSelectedGroupId(response.data[0].groupId);
        fetchLocations({ groupId: response.data[0].groupId });
      }
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };
  
  const handleGroupChange = (value) => {
    setSelectedGroupId(Number(value));
    fetchLocations({ groupId: Number(value) });
  };
  
  const onGroupChange = (groupId) => {
    setSelectedGroupId(groupId);
    fetchLocations({ groupId });
  };

  const fetchLocations = async ({ groupId, skipLocationSet }) => {
    try {
      // let { locationId } = getIdEditLocations();
      
      let response = null;

      response = await (
        await window.fetch('/api/locations', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            groups: groupId || selectedGroupId ? [groupId || selectedGroupId] : user.groups,
            adminType: user.admin,
          }),
          credentials: 'include',
        })
      ).json();
      
      setLocations(response.data);
      if (response.data[0] && !skipLocationSet) {
        setActiveLocation(response.data[0]);
        setSelectedLocation(response.data[0].locationId);
      }
      if (response.status !== 'success') {
        throw Error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  const [selectedLocation, setSelectedLocation] = useState('');

  const handleSelect = (value) => {
    if (!value) {
      setActiveLocation({
        locationId: '',
      });
    } else {
      setSelectedLocation(value);
      setActiveLocation(locations.find((item) => item.locationId == value));
    }
  };

  const getIdEditLocations = () => {
    //console.log('navigationData ',navigationData);
    //setTimeout(()=>{},2000);
    //alert(1)
    if (
      navigationData != undefined &&
      navigationData != null &&
      navigationData.state != null
    ) {
      //setActiveEditLocationId(navigationData.state.locationId);
      if (navigationData.state.locationId) {
        return navigationData.state;
      } else {
        return '';
      }
      // console.log('Edit ID  ',navigationData.state.locationId);
    } else {
      return '';
    }
  };

  const removeLocation = async () => {
    try {
      if (
        !window.confirm(
          `Please confirm you want to remove location: ${activeLocation.name}. This action is irreversible!`
        )
      ) {
        return;
      }
      let response = await (
        await window.fetch('/api/locations/remove', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            locationId: activeLocation.locationId,
          }),
        })
      ).json();
      fetchLocations();
      // getGroupName();
    } catch (err) {
      console.log(err);
    }
  };

  const home = () => navigate('/home');
  
  const createLocations = () => {
    navigate('/admin/createlocations', {
      state: {
        groupId: selectedGroupId
      },
    });
  }
  
  const editLocations = () => {
    navigate('/admin/editlocations', {
      state: { 
        locationId: activeLocation.locationId,
        groupId: selectedGroupId
      },
    });
  }
  
  const moveLocationUp = async () => {
    try {
      let locationId = selectedLocation;
      let response = await (
        await window.fetch('/api/locations/move-up', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            locationId: locationId,
            groupId: selectedGroupId
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      } else {
        await fetchLocations({ skipLocationSet: true });
      }
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };
  
  const moveLocationDown = async () => {
    try {
      let response = await (
        await window.fetch('/api/locations/move-down', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            locationId: selectedLocation,
            groupId: selectedGroupId
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      } else {
        await fetchLocations({ skipLocationSet: true });
      }
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  const fetchImageGallery = (locationId) => {
    setShowImagePopup(true);
  };

  // const setLanguage = async (lang) => {
  //   try {
  //     let response = await (
  //       await window.fetch('/api/admin/users/set-language', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           userId: user.userId,
  //           language: lang,
  //         }),
  //         credentials: 'include',
  //       })
  //     ).json();
  //     if (response.status !== 'success') {
  //       throw Error(response.message);
  //     } else {
  //       user.language = lang;
  //       // console.log('AFTER SET LANGUAGE called slots',{user});
  //     }
  //   } catch (err) {
  //     setWarning(localization['Something went wrong']);
  //   }
  // };
  
  const [hamburgerMenuActive, setHamburgerMenuActive] = useState(false);
  const showHamburgerMenu = () => {
    setHamburgerMenuActive(!hamburgerMenuActive);
    // menuRef.current.click();
  };

  return (
    <div className="home-wrapper">
      <Header 
        user={user}
        logout={logout}
        currentPage={currentPageName}
        showHamburgerMenu={showHamburgerMenu}
        hamburgerMenuActive={hamburgerMenuActive}
        onGroupChange={onGroupChange}
      />
      {/*Must wrap in navbar and nav-container in order to work*/}
      <nav className="navbar">
        <div className="nav-container">
          <HeaderNav
            user={user}
            logout={logout}
            showHamburgerMenu={showHamburgerMenu}
            hamburgerMenuActive={hamburgerMenuActive}
          />
        </div>
      </nav>
      <div>
        <h2>{localization['Slots']}</h2>
        <hr />
      </div>
      {/*<header className="header-inner">
        <nav className="navbar">
          <div className="container nav-container">
            <input
              className="checkbox"
              type="checkbox"
              name=""
              id="chk_ham"
              ref={menuRef}
            />
            <label htmlFor="chk_ham"></label>
            <div className="hamburger-lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>

            <HeaderNav
              user={user}
              logout={logout}
              showHamburgerMenu={showHamburgerMenu}
              hamburgerMenuActive={hamburgerMenuActive}
            />
          </div>
        </nav>
        <div className="d-flex justify-content-end mb-3 nav-custom">
          {(user.admin === 1 || user.admin === 2) && (
            <Form.Select
              className="group-dropdown"
              title={localization['Choose Group']}
              value={selectedGroupId}
              onChange={(e) => handleGroupChange(e.target.value)}
            >
              {groups.map((item, i) => {
                return <option key={item.groupId} value={item.groupId}>{item.groupName}</option>;
              })}
            </Form.Select>
          )}
          {(user.admin === 0 || user.admin === 3) && (
            <Form.Select
              className="group-dropdown"
              title={localization['Choose Group']}
              value={selectedGroupId}
              onChange={(e) => handleGroupChange(e.target.value)}
            >
              {groups.filter(group => user.groups.includes(group.groupId)).map((item, i) => {
                return <option key={item.groupId} value={item.groupId}>{item.groupName}</option>;
              })}
            </Form.Select>
          )}
          <Nav variant="pills">
            <Nav.Item>
              <Form.Select
                className="d-inline-block w-auto align-middle me-2 language-dropdown"
                onChange={(e) => {
                  let language = e.target.value;
                  localization.setCookieLanguage(language);
                  setIncrement(increment + 1);
                  setLanguage(language);
                }}
              >
                {localization.getAvailableLanguages().map((language) => {
                  return (
                    <option
                      key={language}
                      value={language}
                      selected={localization.getLanguage() === language}
                    >
                      {language.toUpperCase()}
                    </option>
                  );
                })}
              </Form.Select>
            </Nav.Item>
          </Nav>
        </div>

        <div>
          <img
            onClick={home}
            src={homeLogo}
            alt="i68"
            className="home-button"
          />
          <div className="name-location">
            <strong>
              <span
                className={user.gender == 2 ? 'female-color' : 'male-color'}
              >
                {user.name}
              </span>
              {{groupsNames.length > 0 && groupsNames.map((groupName, i) => {
                return (
                  <span> {groupName}{groupsNames[i + 1] ? ',' : ''}</span>
                );
              })}}
            </strong>
          </div>
        </div>
      </header>*/}
      <div className="mb-3 d-none">
        {(user.admin === 1 || user.admin === 2) && (
          <Form.Select
            className=""
            title={localization['Choose Group']}
            value={selectedGroupId}
            onChange={(e) => handleGroupChange(e.target.value)}
          >
            {groups.map((item, i) => {
              return <option key={item.groupId} value={item.groupId}>{item.groupName}</option>;
            })}
          </Form.Select>
        )}
        {(user.admin === 0 || user.admin === 3) && (
          <Form.Select
            className=""
            title={localization['Choose Group']}
            value={selectedGroupId}
            onChange={(e) => handleGroupChange(e.target.value)}
          >
            {groups.filter(group => user.groups.includes(group.groupId)).map((item, i) => {
              return <option key={item.groupId} value={item.groupId}>{item.groupName}</option>;
            })}
          </Form.Select>
        )}
      </div>
      <section className="home-body mt-2 pe-2">
        <div className="d-flex gap-3 align-items-center">
          <div className="w-100">
            <Form.Select
              multiple={isMobile ? false : true}
              value={[selectedLocation]}
              onChange={(e) => handleSelect(e.target.value)}
            >
              {locations.map((item) => {
                return <option key={item.locationId} value={item.locationId}>{item.name}</option>;
              })}
            </Form.Select>
            {isMobile && 
              <div className="text-nowrap mt-1 ms-1">{localization['Order']}: {locations.findIndex(location => location.locationId === Number(selectedLocation)) + 1}</div>
              
            }
          </div>
          <div>
            <Button
              variant={`btn btn-outline-dark ${!isMobile ? 'mb-4' : 'mb-1'}`}
              onClick={moveLocationUp}
            >
              <AiFillCaretUp />
            </Button>
            <br />
            <Button
              variant={`btn btn-outline-dark ${!isMobile ? 'mt-4' : 'm4-1'}`}
              onClick={moveLocationDown}
            >
              <AiFillCaretDown />
            </Button>
          </div>
        </div>
   
        {/* 
    <Nav variant="tabs" onSelect={handleSelect}>
      {locations.map(item => {
        return (
          <Nav.Item key={item.id}>
            <Nav.Link  active={activeLocation.locationId == item.locationId} eventKey={item.locationId}>{item.name}</Nav.Link>
          </Nav.Item>
        );
      })} 
      */}
        <div className="location-details-wrapper">
          <label>{activeLocation.description}</label>
          {activeLocation.imagePath && (
            <img
              src={`${activeLocation.imagePath.split(',')[0]}`}
              onClick={(e) => fetchImageGallery(activeLocation.locationId)}
            />
          )}
        </div>
        {showImagePopup && (
          <ImagesPopup
            activeLocation={activeLocation}
            onClose={setShowImagePopup}
          />
        )}
        <div className="slots-button-wrapper my-4">
          {(user.admin === 1 || user.admin === 2 || user.admin === 3) && (
            <button
              className="btn btn-primary"
              onClick={createLocations}
            >
              {localization['Create New Location']}
            </button>
          )}

          {(user.admin === 1 || user.admin === 2 || user.admin === 3) && (
            <button
              className="btn btn-primary"
              onClick={editLocations}
            >
              {localization['Edit Location']}
            </button>
          )}

          {(user.admin === 1 || user.admin === 2 || user.admin === 3) && (
            <Button
              variant="outline-danger"
              onClick={removeLocation}
            >
              {localization['Remove Location']}
            </Button>
          )}
        </div>
        {activeLocation.locationId && (
          <LocationTable
            activeLocation={activeLocation}
            user={user}
            selectedGroup={groups.find(item => item.groupId === selectedGroupId)}
          />
        )}
      </section>
      <footer>
        <FooterNav
          user={user}
          logout={logout}
          currentPage={currentPageName}
          showHamburgerMenu={showHamburgerMenu}
          hamburgerMenuActive={hamburgerMenuActive}
        />
      </footer>
    </div>
  );
}

function LocationTable({ activeLocation, user, selectedGroup }) {
  let persons = [];
  for (let i = 0; i < activeLocation.peopleNo; i++) {
    if (activeLocation.name === 'Transport') {
      persons.push(localization['Driver']);
    } else {
      persons.push(`${localization['Person']} ${i + 1}`);
    }
  }
  
  const [showExpired, setShowExpired] = useState(false);
  const [slots, setSlotsMiddleware] = useState([]);
  const [originalSlots, setOriginalSlots] = useState([]);
  
  const setSlots = (slots) => {
    setSlotsMiddleware(slots.filter(slot => {
      return !slot.formatted.expired || showExpired
    }));
  };
  
  useEffect(() => {
    setSlots(originalSlots);
  }, [showExpired]);
  
  const [description, setDescription] = useState('');
  const [slotRepeatNumber, setSlotRepeatNumber] = useState(0);
  const [error, setError] = useState('');
  let [warning, setWarning] = useState('');
  
  const [startTimeUTC, setStartTimeUTC] = useState('');
  const [endTimeUTC, setEndTimeUTC] = useState('');
  
  const cancel = async (users) => {
    if (!window.confirm(localization['Are you sure you want to cancel?'])) {
      return;
    }
    let slotIds = [];
    users.map((user) => {
      slotIds.push(user.slotId);
    });
    try {
      let response = await (
        await window.fetch('/api/admin/slots/cancel', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            slotIds: slotIds,
            userId: user.userId,
          }),
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      } else {
        getSlots();
      }
    } catch (err) {}
  };
  
  const restore = async (users) => {
    if (!window.confirm(localization['Are you sure you want to restore?'])) {
      return;
    }
    let slotIds = [];
    users.map((user) => {
      slotIds.push(user.slotId);
    });
    try {
      let response = await (
        await window.fetch('/api/admin/slots/restore', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            slotIds: slotIds,
            userId: user.userId,
          }),
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      } else {
        getSlots();
        // let tempSlots = users.filter((u) => {
        //   return u.userId != null;
        // });
        // let slotIds = [];
        // tempSlots.map((user) => {
        //   slotIds.push(user.slotId);
        // });
        // if (tempSlots.length > 0) {
        //   let response = await (
        //     await window.fetch('/api/admin/slots/user/details', {
        //       method: 'POST',
        //       headers: {
        //         'Content-Type': 'application/json',
        //       },
        //       credentials: 'include',
        //       body: JSON.stringify({
        //         slotIds: slotIds,
        //       }),
        //     })
        //   ).json();
        //   if (response.status !== 'success') {
        //     throw Error(response.message);
        //   } else {
        //     //console.log('@@@!!!###',response.data);
        //     let emailData = [];
        //     let responseData = response.data;
        //     for (let i = 0; i < response.data.length; i++) {
        //       let responseDate = convertToLocal(
        //         responseData[i].date,
        //         responseData[i].startTime
        //       ).date;
        //       responseDate = responseDate.split('-');
        //       let tempDate = new Date(
        //         responseDate[0],
        //         responseDate[1] - 1,
        //         responseDate[2]
        //       );

        //       let tempData = {
        //         email: responseData[i].email,
        //         language: responseData[i].language,
        //         userName: responseData[i].name,
        //         locationName: responseData[i].locationName,
        //         dayName: localization[days[tempDate.getDay()]],
        //         monthName: localization[months[responseDate[1] - 1]],
        //         month: responseDate[1],
        //         day: responseDate[2],
        //         fullYear: responseDate[0],
        //         startTime: convertToLocal(
        //           responseData[i].date,
        //           responseData[i].startTime
        //         ).time,
        //       };
        //       emailData.push(tempData);
        //     }
        //     //console.log('emailDATA restored', emailData);
        //     sendEmail(emailData, 'restored');
        //   }
        // }
      }
    } catch (err) {}
  };
  
  const remove = async (users, expired) => {
    if (!window.confirm(`${localization['Are you sure? \nThis will permanently delete this slot and user data completely from the database. This action is irreversible.']} `)) {
      return;
    }
    let slotIds = [];
    users.map((user) => {
      slotIds.push(user.slotId);
    });
    try {
      let response = await (
        await window.fetch('/api/admin/slots/remove', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            slotIds: slotIds,
            userId: user.userId,
            expired: expired,
          }),
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      } else {
        getSlots();
      }
    } catch (err) {}
  };
  
  const getSlots = async () => {
    try {
      let responseSlot = await (
        await window.fetch('/api/slots', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            locationId: activeLocation.locationId,
          }),
        })
      ).json();
      
      // let isDST = moment.tz().isDST();
      
      let slots = [];
      for (let i in responseSlot.data) {
        let item = responseSlot.data[i];
        
        let slotIndex = slots.findIndex(slot => slot.sTime === item.sTime && slot.eTime === item.eTime);
        
        if (slotIndex === -1) {
          
          /**
           * moment.js already accounts for daylight saving time
           */
          let startTimeMoment = moment.utc(item.sTime).tz(user.timezone);
          // if (startTimeMoment.isDST() && !isDST) {
          //   // move one hour backwards
          //   startTimeMoment = startTimeMoment.subtract(1, 'hours');
          // } else if (!startTimeMoment.isDST() && isDST) {
          //   // move one hour forwards
          //   startTimeMoment = startTimeMoment.add(1, 'hours');
          // }
          
          let endTimeMoment = moment.utc(item.eTime).tz(user.timezone);
          // if (endTimeMoment.isDST() && !isDST) {
          //   // move one hour backwards
          //   endTimeMoment = endTimeMoment.subtract(1, 'hours');
          // } else if (!endTimeMoment.isDST() && isDST) {
          //   // move one hour forwards
          //   endTimeMoment = endTimeMoment.add(1, 'hours');
          // }
          
          let startTime = startTimeMoment.format('HH:mm');
          let endTime = endTimeMoment.format('HH:mm');
          
          slots.push({
            description: `${startTime} - ${endTime}`,
            sTime: item.sTime,
            eTime: item.eTime,
            formatted: {
              date: moment.utc(item.sTime).tz(user.timezone).format('MMMM D, YYYY'),
              month: moment.utc(item.sTime).tz(user.timezone).format('MMMM'),
              dayYear: moment.utc(item.sTime).tz(user.timezone).format('D, YYYY'),
              dayOfWeek: moment.tz().weekday(moment.utc(item.sTime).tz(user.timezone).weekday()).format('dddd'),
              expired: moment.utc(item.eTime).tz(user.timezone).valueOf() - moment.tz().valueOf() < 0 ? true : false
            },
            users: [{
              slotId: item.slotId,
              userId: item.userId,
              name: item.name,
              slotNo: item.slotNo,
              canceled: item.canceled,
            }],
          });
          
        } else {
          slots[slotIndex].users.push({
            slotId: item.slotId,
            userId: item.userId,
            name: item.name,
            slotNo: item.slotNo,
            canceled: item.canceled,
          });
        }
        
      }
      
      slots.sort((a, b) => {
        let an = Number(moment.utc(a.sTime).format('YYYY-MM-DD').split('-').join(''));
        let bn = Number(moment.utc(b.sTime).format('YYYY-MM-DD').split('-').join(''));
        let atn = Number(
          a.description.split(' - ').join('').split(':').join('')
        );
        let btn = Number(
          b.description.split(' - ').join('').split(':').join('')
        );
        if (an === bn) {
          return atn < btn ? -1 : atn > btn ? 1 : 0;
        } else {
          return an < bn ? -1 : 1;
        }
      });
      
      setSlots(slots || []);
      setOriginalSlots(slots || []);

      if (responseSlot.status !== 'success') {
        throw Error(responseSlot.message);
      }
    } catch (err) {}
  };
  
  const validateTimes = () => {
    
    setWarning('');
    
    if (startTimeUTC == undefined || startTimeUTC == '') {
      return false;
    }
    
    if (endTimeUTC == undefined || endTimeUTC == '') {
      return false;
    }
    
    if (moment.utc(endTimeUTC).valueOf() - moment.utc(startTimeUTC).valueOf() < 0) {
      setWarning(localization['End time cannot be before start time']);
      return false;
    }
    
    let diff = moment.utc(endTimeUTC).valueOf() - moment.utc(startTimeUTC).valueOf();
    
    if (diff < (29 * 60 * 1000)) {
      setWarning(localization['Minimum duration is 30 minutes']);
      return false;
    }
    
    if (diff > (12 * 60 * 60 * 1000)) {
      setWarning(localization['Maximum duration is 12 hours']);
      return false;
    }
    
    return true;
    
  };
  
  useEffect(() => {
    validateTimes();
  }, [startTimeUTC, endTimeUTC]);
  
  const createSlots = async () => {
    
    let repeatedSlots = [];
    
    try {
      
      if (startTimeUTC == undefined || startTimeUTC == '') {
        //alert(`${localization['Enter']} ${localization['Shift']}`);
        setWarning(localization['Enter'] + ' ' + localization['Start Time']);
        return;
      }

      if (endTimeUTC == undefined || endTimeUTC == '') {
        setWarning(localization['Enter'] + ' ' + localization['End Time']);
        return;
      }
      
      if (!validateTimes()) {
        return;
      }
      
      repeatedSlots.push({
        startTimeUTC,
        endTimeUTC
      });
      
      if (slotRepeatNumber > 0) {
        for (let i = 0; i < slotRepeatNumber; i++) {
          let startTimeUTC2 = moment.utc(startTimeUTC).add(7 * (i + 1), 'days').format('YYYY-MM-DD HH:mm');
          let endTimeUTC2 = moment.utc(endTimeUTC).add(7 * (i + 1), 'days').format('YYYY-MM-DD HH:mm');
          /**
           * Adjust for daylight saving
           * This seems to be only working if user is currently in daylight saving?
           * Actually program handles this automatically
           */
          // if (moment.utc(startTimeUTC2).format('HH:mm') !== moment.utc(startTimeUTC).format('HH:mm')) {
          //   startTimeUTC2 = moment.utc(startTimeUTC2).add(moment.utc(startTimeUTC2).valueOf() - moment.utc(startTimeUTC).valueOf(), 'miliseconds').format('YYYY-MM-DD HH:mm');
          // }
          repeatedSlots.push({
            startTimeUTC: startTimeUTC2,
            endTimeUTC: endTimeUTC2
          });
        }
      }
      
      let response = await (
        await window.fetch('/api/admin/slots/new/v2', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            repeatedSlots: repeatedSlots,
            userId: user.userId,
            locationId: activeLocation.locationId,
            slotNo: activeLocation.peopleNo
          }),
        })
      ).json();
      
      if (response.status == 'hasdata') {
        alert('Some slots already exists. Please check.');
        //setError(localization['Enter'] + ' ' + localization['Shift']);
        //setWarning(localization['Some slots already exists. Please check.']);
        return;
      }
      
      setSlotRepeatNumber(0);
      setDescription('');
      setStartTimeUTC('');
      setEndTimeUTC('');
      getSlots();
      
      if (response.status !== 'success') {
        throw Error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSlotRepeatNumber = (value) => {
    setSlotRepeatNumber(Number(value));
  };
  
  useEffect(async () => {
    getSlots();
    channel.unbind('update');
    channel.bind('update', (data) => {
      if (data.message === 'update') {
        getSlots();
      }
    });
    // setStartTime(null);
    // setEndTime(null);
    setStartTimeUTC('');
    setEndTimeUTC('');
  }, [activeLocation]);

  useEffect(() => {
    //your code goes here
    return () => {
      channel.unbind('update');
    };
  }, []);
  
  const toExport = () => {
    console.log(activeLocation)
    let data = slots.map(slot => {
      let row = {};
      row[localization['Timeframe']] = slot.description;
      row[localization['Day']] = localization[`${slot.formatted.dayOfWeek}`];
      row[localization['Date']] = `${localization[slot.formatted.month]} ${slot.formatted.dayYear}`;
      slot.users.map((user, i) => {
        if (user.userId) {
          row[`${localization['Position']}`+ ` ${i + 1}`] = user.name;
        }
      })
      let status = '';
      if (!slot.users[0] || !slot.users[0].canceled) {
        if (!slot.formatted.expired) {
          status = 'Active';
        } else {
          status = 'Expired';
        }
      } else {
        if (!slot.formatted.expired) {
          status = 'Disabled';
        } else {
          status = 'Expired';
        }
      }
      row[localization['Status']] = localization[`${status}`];
      row[localization['Location']] = activeLocation.name;
      row[localization['Group']] = selectedGroup.groupName;
      return row;
    }).flat();
    
    exportToXLSX(data, `slot`);
    
  }
  
  return (
    <>
      {warning && (
        <div className="bg-warning rounded  my-2 text-light px-2 py-1">
          <div>
            {warning}{' '}
            <span
              style={{ cursor: 'pointer' }}
              className="float-end px-2"
              onClick={(e) => setWarning('')}
            >
              x
            </span>
          </div>
        </div>
      )}
      <Table
        striped
        bordered
        hover
        responsive="sm"
      >
        <thead>
          <tr key={-2}>
            <th>{localization['Start Time']}</th>
            <th>{localization['End Time']}</th>
            <th>{localization['Date']}</th>
            <th>{localization['Day']}</th>
            <th>{localization['Repeat Weekly']}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr key={-3}>
            <td className="form-inline">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  label=""
                  value={moment.utc(startTimeUTC).tz(user.timezone)}
                  ampm={false}
                  minDate={moment.tz().toDate()}
                  inputFormat="YYYY-MM-DD HH:mm"
                  timezone={user.timezone}
                  onChange={(newValue) => {
                    setStartTimeUTC(moment(newValue).utc().format('YYYY-MM-DD HH:mm'));
                  }}
                  renderInput={(params) => (
                    <div className="react-datepicker-ignore-onclickoutside">
                      <TextField
                        className="text-input"
                        readOnly
                        {...params}
                      />
                    </div>
                  )}
                />
              </LocalizationProvider>
            </td>
            <td className="form-inline">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  label=""
                  value={moment.utc(endTimeUTC).tz(user.timezone)}
                  ampm={false}
                  minDate={moment.tz().toDate()}
                  inputFormat="YYYY-MM-DD HH:mm"
                  timezone={user.timezone}
                  onChange={(newValue) => {
                    setEndTimeUTC(moment(newValue).utc().format('YYYY-MM-DD HH:mm'));
                  }}
                  renderInput={(params) => (
                    <div className="react-datepicker-ignore-onclickoutside">
                      <TextField
                        className="text-input"
                        readOnly
                        {...params}
                      />
                    </div>
                  )}
                />
              </LocalizationProvider>
            </td>
            <td>
              <InputMask
                value={startTimeUTC ? moment.utc(startTimeUTC).tz(user.timezone).format('MMMM D, YYYY') : ''}
                readonly
              />
            </td>
            <td>{startTimeUTC ? localization[moment.tz().weekday(moment.utc(startTimeUTC).tz(user.timezone).weekday()).format('dddd')] : ''}</td>
            <td>
              <Form.Control
                type="number"
                value={slotRepeatNumber}
                onChange={(e) => handleSlotRepeatNumber(e.target.value)}
                min={0}
                max={10}
              />
            </td>
            <td>
              <Button
                variant="outline-success"
                type="submit"
                onClick={createSlots}
              >{localization['Create']}</Button>
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex justify-content-end gap-3">
        <div className="align-self-center fs-4" >
          <Button
            variant="outline-success"
            type="submit"
            onClick={e => toExport()}
          ><FaFileDownload /> {localization['Download']}</Button>
        </div>
        <FormGroup className="d-flex flex-row">
          <FormControlLabel control={<Switch checked={showExpired} onChange={e => setShowExpired(e.target.checked)} />} label={localization['Show Expired']} />
          {/*
          <ToggleButtonGroup
            color="primary"
            value={showImages}
            exclusive
            onChange={(e, label) => {
              setShowImages(!showImages);
            }}
            aria-label={showImages ? ' - ' : ' + '}
          >
            <ToggleButton value="view-images">{showImages ? ' - ' : ' + '}</ToggleButton>
          </ToggleButtonGroup>
          */}
        </FormGroup>
      </div>
      {slots.length > 0 ? (
        <Table
          striped
          bordered
          hover
          responsive="sm"
          className="mt-4"
        >
          <thead>
            <tr key={-1}>
              <th>{localization['Timeframe']}</th>
              <th>{localization['Day']}</th>
              <th>{localization['Date']}</th>
              <th>{localization['Positions']}</th>
              <th>{localization['Status']}</th>
              <th>
                {localization['Remove']} {localization['Slots']}
              </th>
            </tr>
          </thead>
          <tbody>
            {slots.map((slot, i) => {
              return (
                <tr
                  key={slot.slotId}
                  style={{
                    borderWidth:
                      i > 0 && moment.utc(slot.sTime).tz(user.timezone).format('MMMM D, YYYY') !== moment.utc(slots[i - 1].sTime).tz(user.timezone).format('MMMM D, YYYY')
                        ? '2px 0px'
                        : '1px 0px',
                    // backgroundColor: (i % 2 ==0 && activeLocation.bgColor != 'null') ? activeLocation.bgColor : null
                  }}
                  // style={}
                >
                  <td className="font-weight-bold">{slot.description}</td>

                  {/*<td>{toMonth(slot.date.split('-')[1])}</td>*/}
                  <td>{localization[slot.formatted.dayOfWeek]}</td>
                  <td>{localization[slot.formatted.month]} {slot.formatted.dayYear}</td>
                  <td>
                    {slot.users.map((u, ui) => {
                      if (slot.users.length > 2 && ui === 1 && !u.expanded) {
                        return (
                          <div key={u.slotId} role="button" style={{
                            textDecoration: 'underline'
                          }} onClick={e => {
                            let copy = [...slots];
                            copy[i].users = copy[i].users.map(user => {
                              user.expanded = !user.expanded;
                              return user;
                            });
                            setSlots(copy);
                          }}>
                            <span>... {localization['expand']} {slot.users.length - 1} {localization['more']}</span>
                          </div>
                        );
                      } else {
                        if (u.canceled) {
                          return (
                            <div key={u.slotId} className={`${slot.users.length > 2 && ui > 0 && !u.expanded ? 'd-none' : ''}`}>
                              {u.name == '' || u.name == null ? (
                                <span>{localization['Canceled']}</span>
                              ) : (
                                <span disabled>{u.name}</span>
                              )}
                            </div>
                          );
                        } else if (u.userId === user.userId) {
                          return (
                            <div key={u.slotId} className={`${slot.users.length > 2 && ui > 0 && !u.expanded ? 'd-none' : ''}`}>
                              <span>{u.name}</span>
                            </div>
                          );
                        } else if (!u.userId) {
                          return (
                            <div key={u.slotId} className={`${slot.users.length > 2 && ui > 0 && !u.expanded ? 'd-none' : ''}`}>
                              <span>{localization['Open']}</span>
                            </div>
                          );
                        } else {
                          return (
                            <div key={u.slotId} className={`${slot.users.length > 2 && ui > 0 && !u.expanded ? 'd-none' : ''}`}>
                              <span disabled>{u.name}</span>
                            </div>
                          );
                        }
                      }
                    })}
                  </td>
                  <td>
                    {!slot.users[0] || !slot.users[0].canceled ? ( // 0 - 1
                      <>
                        {!slot.formatted.expired ? (
                          <>
                            <span>{localization['Active']}</span>
                            <br />
                            <Button
                              variant="btn btn-outline-dark"
                              onClick={(e) => cancel(slot.users)}
                            >
                              {localization['Cancel']}
                            </Button>
                          </>
                        ) : (
                          <span>{localization['Expired']}</span>
                        )}
                      </>
                    ) : (
                      <>
                        {!slot.formatted.expired ? (
                          <>
                            <span>{localization['Disabled']}</span>
                            <br />
                            <Button
                              variant="btn btn-outline-primary"
                              onClick={(e) => restore(slot.users)}
                            >
                              {localization['Restore']}
                            </Button>
                          </>
                        ) : (
                          <span>{localization['Expired']}</span>
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    <Button
                      variant="outline-danger"
                      onClick={(e) => remove(slot.users, slot.formatted.expired)}
                    >
                      {localization['Remove']}
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <h4 className="no-data">{localization['No slots booked']}</h4>
      )}
    </>
  );
}

function ImagesPopup({ activeLocation, onClose }) {
  const closePopup = (event) => {
    onClose(false);
  };
  let locationImages = activeLocation.imagePath.split(',');
  return (
    <div className="image-popup-wrapper">
      <div
        className="closebutton"
        onClick={(e) => closePopup(e)}
      >
        <span className="line line1"></span>
        <span className="line line3"></span>
      </div>
      <div className="gallery-wrapper">
        {locationImages.map((image) => {
          return <img key={image} src={`${image}`} />;
        })}
      </div>
    </div>
  );
}

export default Slots;
