import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout({ logout }) {
  useEffect(async () => {
    logout();
  }, []);

  return <>Logged Out</>;
}

export default Logout;
