import '../styles/index.scss';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import localization from '../localization';
import { BsFillHouseFill } from 'react-icons/bs';
import { FaClipboard, FaBars } from 'react-icons/fa';
import { MdLogout } from 'react-icons/md';
import { VscNotebook } from 'react-icons/vsc';
import {
  IoHome,
  IoPerson,
  IoClipboard,
  IoCalendarNumberSharp,
  IoLogOutOutline,
  IoMenuOutline,
  IoCloseOutline
} from 'react-icons/io5';

function FooterNav({ user, logout, currentPage, showHamburgerMenu, hamburgerMenuActive }) {
  useEffect(async () => {}, []);

  let navigate = useNavigate();
  //let location = useLocation();

  const adminPanel = () => navigate('/admin/users');
  
  const groupPanel = () => navigate('/admin/groups');
  
  if (!user) {
    return;
  }
  
  return (
    <ul className="footer-menu-items pe-3">
      <li>
        {currentPage == 'home' ? (
          <a
            href="javascript:void(0)"
            onClick={(e) => navigate('/home')}
            className="active"
          >
            <IoHome />
            <span>{localization['Home']}</span>
          </a>
        ) : (
          <a
            href="javascript:void(0)"
            onClick={(e) => navigate('/home')}
          >
            <IoHome />
            <span>{localization['Home']}</span>
          </a>
        )}
      </li>
      {user.admin != 0 && (
        <li>
          {currentPage == 'slots' ? (
            <a
              href="javascript:void(0)"
              onClick={(e) => navigate('/admin/slots')}
              className="active"
            >
              <IoClipboard />
              <span>{localization['Slots']}</span>
            </a>
          ) : (
            <a
              href="javascript:void(0)"
              onClick={(e) => navigate('/admin/slots')}
            >
              <IoClipboard />
              <span>{localization['Slots']}</span>
            </a>
          )}
        </li>
      )}
      <li>
        {currentPage == 'myshift' ? (
          <a
            href="javascript:void(0)"
            onClick={(e) => navigate('/myshift')}
            className="active"
          >
            <IoCalendarNumberSharp />
            <span>{localization['Assignments']}</span>
          </a>
        ) : (
          <a
            href="javascript:void(0)"
            onClick={(e) => navigate('/myshift')}
          >
            <IoCalendarNumberSharp />
            <span>{localization['Assignments']}</span>
          </a>
        )}
      </li>
      <li>
        {currentPage == 'account' ? (
          <a
            href="javascript:void(0)"
            onClick={(e) => navigate('/account')}
            className="active"
          >
            <IoPerson />
            <span>{localization['Account']}</span>
          </a>
        ) : (
          <a
            href="javascript:void(0)"
            onClick={(e) => navigate('/account')}
          >
            <IoPerson />
            <span>{localization['Account']}</span>
          </a>
        )}
      </li>
      
      <li>
        <a
          href="javascript:void(0)"
          onClick={showHamburgerMenu}
          className="ham"
        >
          {!hamburgerMenuActive ?
            <IoMenuOutline />
          :
            <IoCloseOutline />
          }
        </a>
      </li>
      
    </ul>
  );
}

export default FooterNav;
