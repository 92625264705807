import '../styles/App.scss';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import localization from '../localization';

function Announcements({ user, onClose, onLanguageChange, onNavigate }) {
  let navigate = useNavigate();
  //let location = useLocation();
  let [warning, setWarning] = useState('');
  let [data, setData] = useState('');
  let [hyperlink, setHyperlink] = useState('');
  let [allData, setAlldata] = useState([]);
  let [message, setMessage] = useState('');

  useEffect(async () => {
    getAnnouncementData();
  });

  const getAnnouncementData = async () => {
    try {
      let response = await (
        await window.fetch('/api/admin/notifications/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            groupId: user.groupId,
          }),
          credentials: 'include',
        })
      ).json();
      // console.log('response',response);
      if (response.status !== 'success') {
        throw Error(response.message);
      } else if (response.data.length > 0) {
        setAlldata(response.data[0]);
        setMessage(response.data[0].message);
        showMessage(response.data[0].message);
        setHyperlink(response.data[0].hyperlink);
        //console.log('message',message);
        //setData(response.data[0].message);
      }
      //setGroupName(response.data[0]['groupName']);
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  const showMessage = (msg) => {
    let tempMsg = msg.split('^^');
    if (localization.getLanguage() == 'en') {
      setData(tempMsg[0]);
    } else if (localization.getLanguage() == 'pl') {
      setData(tempMsg[1]);
    }
  };

  onNavigate = () => {
    if (hyperlink == '') {
      return;
    }
    if (isURL(hyperlink)) {
      if (window.confirm(localization['This will open external link in new window'])) {
        window.open(hyperlink, '_blank').focus();
      }
    } else {
      alert(localization['The URL looks invalid']);
    }
  };

  const isURL = (val) => {
    return /^https?:\/\/|www\./i.test(val);
  };
  // useEffect(async () => {
  //   getAnnouncementData();
  // }, [onLanguageChange]);

  return (
    <>
      {message.replace(/\^\^/, '').length > 0 ? (
        <div
          className="notifications-bar"
          style={{ backgroundColor: allData['bgColor'] }}
        >
          <div
            className="closebutton"
            onClick={onClose}
          >
            <span className="line line1"></span>
            <span className="line line3"></span>
          </div>
          <div
            className="notifications-data"
            onClick={onNavigate}
          >
            <span>{data}</span>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Announcements;
