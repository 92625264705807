import '../styles/views/Account.scss';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import localization from '../localization';
import Pusher from 'pusher-js';
import moment from '../moment.js';
import { isMobile } from 'react-device-detect';
import { BsFillTelephoneOutboundFill } from 'react-icons/bs';
import homeLogo from '../images/i68-icon.png';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import HeaderNav from './HeaderNav';
import FooterNav from './FooterNav';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Cookies from 'universal-cookie';
import { Notifications } from '../utils/Notifications';
import Header from './Header';
import { FormGroup, FormControlLabel, Switch, ToggleButtonGroup, ToggleButton } from '@mui/material';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

let pusher = new Pusher('b534d4fac76717b9872e', {
  cluster: 'us2',
  encrypted: true,
});

let channel = pusher.subscribe('channel');

function Account({ user, logout, setUser }) {
  
  let navigate = useNavigate();
  const [error, setError] = useState('');
  const [locations, setLocations] = useState([]);
  const [activeLocation, setActiveLocation] = useState({});
  const [increment, setIncrement] = useState(1); // Force Re-Render
  // const [groupName, setGroupName] = useState('');
  // const [groupsNames, setGroupsNames] = useState([]);
  const [warning, setWarning] = useState('');
  let [currentDay, setCurrentDay] = useState(moment.tz().toDate());
  let [daysAdded, setDaysAdded] = useState(0);
  const [showFormatedDate, setShowFormatedDate] = useState('');
  const [apiDate, setApiDate] = useState('');
  let [groups, setGroups] = useState([]);
  let [primaryGroup, setPrimaryGroup] = useState({});
  let [selectedGroupId, setSelectedGroupId] = useState('');
  const cookies = new Cookies();
  const [currentPageName, setCurrentPageName] = useState('account');
  const menuRef = useRef(null);
  //const client = new W3CWebSocket('ws://127.0.0.1:8000');
  //const client = new W3CWebSocket('wss://52.29.50.136:8000/');
  
  const [hamburgerMenuActive, setHamburgerMenuActive] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const [isBeta, setIsBeta] = useState(false);

  const redirectUser = async () => {
    try {
      setIsBeta(true);
      const response = await window.fetch('/api/users/redirect', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.userId })
      });
      const { status, data } = await response.json();
      if(status.toLowerCase() === 'success'){
        window.location.href = `${data.url}${window.location.pathname}`;
      }
    } catch (err) {
      console.log(err);
      setIsBeta(false);
    }
  }
  
  useEffect(async () => {
    
    if (!user.userId) {
      return;
    }
    
    // getGroupsNames();
    
    formatDate();
    
    fetchGroups();
    
    setSelectedTimezone(user.timezone || moment.tz.guess());
    
  }, [user]);

  const handleSelect = (eventKey) => {
    setActiveLocation(locations.find((item) => item.locationId == eventKey));
  };

  const adminPanel = () => navigate('/admin/users');
  if (!user) {
    return;
  }

  const groupPanel = () => navigate('/admin/groups');
  if (!user) {
    return;
  }

  const myShift = () => navigate('/myshift');

  const fetchSlotData = (day) => {
    //new Date(startDate.getDate() + 1);
    if (day == 'today') {
      //await setCurrentDay(new Date());

      //currentDay.setDate(new Date());
      //await currentDay.setDate(new Date());
      currentDay.setDate(currentDay.getDate() - daysAdded);
      setDaysAdded(0);
    } else if (day == 'prev') {
      // date.setDate(date.getDate() + 1);
      currentDay.setDate(currentDay.getDate() - 1);
      setDaysAdded(daysAdded - 1);
    } else if (day == 'next') {
      currentDay.setDate(currentDay.getDate() + 1);
      setDaysAdded(daysAdded + 1);
    } else if (day == '7daysbefore') {
      currentDay.setDate(currentDay.getDate() - 7);
      setDaysAdded(daysAdded - 7);
    } else if (day == '7daysafter') {
      currentDay.setDate(currentDay.getDate() + 7);
      setDaysAdded(daysAdded + 7);
    }
    if (selectedGroupId) {
      setSelectedGroupId(selectedGroupId);
      fetchLocationsOfGroups(selectedGroupId);
    }
    formatDate();
  };

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const formatDate = () => {
    let tempDate = currentDay;
    
    let dayName = localization[days[currentDay.getDay()]];
    let monthName = localization[months[currentDay.getMonth()]];
    let month = currentDay.getMonth() + 1;
    let day = currentDay.getDate();
    let fullYear = currentDay.getFullYear();
    setShowFormatedDate(`${dayName}, ${monthName} ${day}, ${fullYear}`);
    day = day.toString();
    month = month.toString();
    day = day.length == 1 ? '0' + day : day;
    month = month.length == 1 ? '0' + month : month;
    setApiDate(`${fullYear}-${month}-${day}`);
  };

  // const getGroupsNames = async () => {
  //   try {
  //     let response = await (
  //       await window.fetch('/api/groups/names', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           userId: user.userId,
  //         }),
  //         credentials: 'include',
  //       })
  //     ).json();
  //     if (response.status !== 'success') {
  //       throw Error(response.message);
  //     }
  //     setGroupsNames(response.data.map(item => item.groupName));
  //     if (response.data[0]) {
  //       setSelectedGroupId(response.data[0].groupId);
  //       fetchLocationsOfGroups(response.data[0].groupId);
  //     }
  //   } catch (err) {
  //     setWarning(localization['Something went wrong']);
  //   }
  // };

  const handleGroupChange = (value) => {
    setSelectedGroupId(Number(value));
    fetchLocationsOfGroups(Number(value));
  };

  const fetchLocationsOfGroups = async (groupId) => {
    try {
      let response = await (
        await window.fetch('/api/groups/locations', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            groupId: groupId,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      let tempLocations = response.data;
      tempLocations.forEach((item) => {
        if (item.imagePath && item.imagePath != 'null') {
          let tempImagePath = item.imagePath.split(',');
          tempImagePath = tempImagePath.filter((n) => n);
          item.imagePath = tempImagePath;
        } else {
          item.imagePath = [];
        }
      });
      setLocations(tempLocations);
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  const fetchGroups = async () => {
    try {
      if (selectedGroupId) {
        return;
      }
      let response = await (
        await window.fetch('/api/groups/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }

      setGroups(response.data);
      if (user.groups[0]) {
        setPrimaryGroup(response.data.find(group => user.groups[0] === group.groupId) || {});
      }
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  const setLanguage = async (lang) => {
    try {
      let response = await (
        await window.fetch('/api/users/set-language', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: user.userId,
            language: lang,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      } else {
        let copy = {...user};
        copy.language = lang;
        setUser(copy);
      }
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };
  
  const showHamburgerMenu = () => {
    setHamburgerMenuActive(!hamburgerMenuActive);
    menuRef.current.click();
  };
  
  const setTimezone = async (timezone) => {
    try {
      let response = await (
        await window.fetch('/api/users/set-timezone', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: user.userId,
            timezone: timezone,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      } else {
        let copy = {...user};
        copy.timezone = timezone;
        setUser(copy);
        moment.setCookieTimezone(user.timezone);
        localization.setCookieLanguage(response.data.language);
        setSelectedTimezone(timezone);
      }
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };
  
  return (
    <div className="Account pt-12">
      {/*
      //commented old section 
      <Nav variant="tabs" onSelect={handleSelect}>
      {locations.map(item => {
        return (
          <Nav.Item key={item.id}>
            <Nav.Link  active={activeLocation.locationId == item.locationId} eventKey={item.locationId}>{item.name}</Nav.Link>
          </Nav.Item>
        );
      })}
      </Nav>
      {activeLocation.locationId && 
        <LocationTable activeLocation={activeLocation} user={user}/>
      }
      //old section end
      */}
      {/* new design */}
      <Header 
        user={user}
        logout={logout}
        currentPage={currentPageName}
        showHamburgerMenu={showHamburgerMenu}
        hamburgerMenuActive={hamburgerMenuActive}
      />
      {/*Must wrap in navbar and nav-container in order to work*/}
      <nav className="navbar">
        <div className="nav-container">
          <HeaderNav
            user={user}
            logout={logout}
            showHamburgerMenu={showHamburgerMenu}
            hamburgerMenuActive={hamburgerMenuActive}
          />
        </div>
      </nav>
      <div className="">
        <div className="d-flex justify-content-between  m-2">
            <h2>{localization['Account Settings']}</h2>
            <Form.Select
              style={{width: 'auto'}}
              className=""
              onChange={(e) => {
                let language = e.target.value;
                localization.setCookieLanguage(language);
                setIncrement(increment + 1);
                // formatDate();
                setLanguage(language);
              }}
            >
              {localization.getAvailableLanguages().map((language) => {
                return (
                  <option
                    key={language}
                    value={language}
                    selected={localization.getLanguage() === language}
                  >
                    {language.toUpperCase()}
                  </option>
                );
              })}
            </Form.Select>
          </div>
          <hr />
        </div>
      <div className="home-wrapper">
        <header className="header-inner d-none">
          <nav className="navbar">
            <div className="container nav-container">
              <input
                className="checkbox"
                type="checkbox"
                name=""
                id="chk_ham"
                ref={menuRef}
              />
              <label htmlFor="chk_ham"></label>
              <div className="hamburger-lines">
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
              </div>

              <HeaderNav
                user={user}
                logout={logout}
                showHamburgerMenu={showHamburgerMenu}
                hamburgerMenuActive={hamburgerMenuActive}
              />
            </div>
          </nav>
          <div className="d-flex justify-content-end mb-3 p-0 nav-custom">
            {(user.admin === 1 || user.admin === 2) && (
              <Form.Select
                className="group-dropdown"
                title={localization['Choose Group']}
                value={selectedGroupId}
                onChange={(e) => handleGroupChange(e.target.value)}
              >
                {groups.map((item, i) => {
                  return <option key={item.groupId} value={item.groupId}>{item.groupName}</option>;
                })}
              </Form.Select>
            )}
            {(user.admin === 0 || user.admin === 3) && (
              <Form.Select
                className="group-dropdown"
                title={localization['Choose Group']}
                value={selectedGroupId}
                onChange={(e) => handleGroupChange(e.target.value)}
              >
                {groups.filter(group => user.groups.includes(group.groupId)).map((item, i) => {
                  return <option key={item.groupId} value={item.groupId}>{item.groupName}</option>;
                })}
              </Form.Select>
            )}
            <Nav variant="pills">
              <Nav.Item>
                <Form.Select
                  className="d-inline-block w-auto align-middle me-2 language-dropdown"
                  onChange={(e) => {
                    let language = e.target.value;
                    localization.setCookieLanguage(language);
                    setIncrement(increment + 1);
                    formatDate();
                    setLanguage(language);
                  }}
                >
                  {localization.getAvailableLanguages().map((language) => {
                    return (
                      <option
                        key={language}
                        value={language}
                        selected={localization.getLanguage() === language}
                      >
                        {language.toUpperCase()}
                      </option>
                    );
                  })}
                </Form.Select>
              </Nav.Item>
            </Nav>
          </div>
          <div>
            <img
              src={homeLogo}
              alt="i68"
              className="home-button"
            />
            <div className="name-location mt-2">
              <strong>
                <span
                  className={user.gender == 2 ? 'female-color' : 'male-color'}
                >
                  {user.name}
                </span>
                {/*{groupsNames.length > 0 && groupsNames.map((groupName, i) => {
                  return (
                    <span> {groupName}{groupsNames[i + 1] ? ',' : ''}</span>
                  );
                })}*/}
              </strong>
            </div>
          </div>
        </header>
        <section className="home-body mt-2 pe-2">
          <div className="m-2 mt-4">
            <Form.Label>{localization['Name']}</Form.Label>
            <Form.Control
              type="name"
              value={user.name}
              disabled={true}
            />
          </div>
          <div className="m-2 mt-4">
            <Form.Label>{localization['Email']}</Form.Label>
            <Form.Control
              type="email"
              value={user.email}
              disabled={true}
            />
          </div>
          <div className="m-2 mt-4">
            <Form.Label>{localization['Phone Number']}</Form.Label>
            <Form.Control
              type="phone"
              value={user.phone}
              disabled={true}
            />
          </div>
          <div className="m-2 mt-4">
            <Form.Label>{localization['Your Timezone']}</Form.Label>
            <Form.Select
              value={selectedTimezone}
              onChange={(e) => setTimezone(e.target.value)}
            >
              {moment.tz.names().map(timezone => {
                return <option key={timezone} value={timezone} selected={timezone === selectedTimezone}>{timezone}</option>;
              })}
            </Form.Select>
          </div>
          {/*<div className="m-2 mt-4">
           <Form.Label>{localization['Language']}</Form.Label>
            <Form.Select
              className=""
              onChange={(e) => {
                let language = e.target.value;
                localization.setCookieLanguage(language);
                setIncrement(increment + 1);
                // formatDate();
                setLanguage(language);
              }}
            >
              {localization.getAvailableLanguages().map((language) => {
                return (
                  <option
                    key={language}
                    value={language}
                    selected={localization.getLanguage() === language}
                  >
                    {language.toUpperCase()}
                  </option>
                );
              })}
            </Form.Select>
          </div>*/}
          {user.userId &&
            <div className="m-2 mt-4">
              <Form.Label>{localization['Groups']}</Form.Label>
                {groups.filter(group => user.groups.includes(group.groupId)).map((item, i) => {
                  return <Form.Text className="d-block ms-2" key={item.groupId} value={item.groupId}>{item.groupName}</Form.Text>;
                })}
            </div>
          }
          <div className="m-2 mt-4">
            <Form.Label>{localization['Primary Group']}</Form.Label>
            <Form.Text className="d-block ms-2">{primaryGroup.groupName}</Form.Text>
          </div>
          <div className="m-2 mt-4">
            <Form.Label>{localization['Application Version']}</Form.Label>
            <div>
              <Form.Label className="d-inline-block ms-2 form-text">{localization['Join Beta']}</Form.Label>
              <Switch color="primary" checked={isBeta} onChange={e => {
                redirectUser();
              }} />
            </div>
          </div>
        </section>
        <footer>
          <FooterNav
            user={user}
            logout={logout}
            currentPage={currentPageName}
            showHamburgerMenu={showHamburgerMenu}
            hamburgerMenuActive={hamburgerMenuActive}
          />
        </footer>
      </div>
    </div>
  );
}

export default Account;
