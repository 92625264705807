import '../styles/index.scss';
import '../styles/HeaderNav.scss';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import localization from '../localization';
import {
  HiOutlineExternalLink
} from 'react-icons/hi';

function HeaderNav({ user, logout, showHamburgerMenu, hamburgerMenuActive }) {
  
  let navigate = useNavigate();
  
  if (!user) {
    return;
  }
  
  return (
    <div className="menu-items" style={{ transform: hamburgerMenuActive ? 'translateX(0)' : '' }}>
      <li className="hide-from-mobile">
        <a
          href="javascript:void(0)"
          onClick={() => navigate('/home')}
        >
          {localization['Home']}
        </a>
      </li>
      <li className="hide-from-mobile">
        <a
          href="javascript:void(0)"
          onClick={() => navigate('/myshift')}
        >
          {localization['Assignments']}
        </a>
      </li>
      <li className="hide-from-mobile">
        <a
          href="javascript:void(0)"
          onClick={() => navigate('/account')}
        >
          {localization['My Account']}
        </a>
      </li>
      {(user.admin === 1 || user.admin === 2) && (
        <li>
          <a
            href="javascript:void(0)"
            onClick={() => navigate('/admin/groups')}
          >
            {localization['Groups']}
          </a>
        </li>
      )}
      {user.admin != 0 && (
        <li>
          <a
            href="javascript:void(0)"
            onClick={() => navigate('/admin/users')}
          >
            {localization['Users']}
          </a>
        </li>
      )}
      {user.admin != 0 && (
        <li className="hide-from-mobile">
          <a
            href="javascript:void(0)"
            onClick={(e) => navigate('/admin/slots')}
          >
            {localization['Slots']}
          </a>
        </li>
      )}
      {user.directEntry == 'false' && (
        <li>
          <a
            href="javascript:void(0)"
            onClick={logout}
          >
            {localization['Logout']}
          </a>
        </li>
      )}
      <li>
        <a href="javascript:void(Tawk_API.toggle())" onClick={showHamburgerMenu}>{localization['Support']}</a>
      </li>
      <li className="mt-auto">
        <a
          style={{ backgroundColor: 'rgb(27 118 210)', color: 'rgb(255, 255, 255)'}}
          className="py-2"
          href="https://buy.stripe.com/dR615KbUh9SofLy28i"
          target="_blank"
        >
          {localization['Donate']}
          <HiOutlineExternalLink className="external-link"/>
        </a>
      </li>
    </div>
  );
}

export default HeaderNav;
