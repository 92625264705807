import '../styles/views/Login.scss';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import localization from '../localization';
import moment from '../moment.js';

function Login({ setUser }) {
  const [error, setError] = useState('');

  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [increment, setIncrement] = useState(1);

  let navigate = useNavigate();
  let location = useLocation();
  //localization.setLanguage('pl');
  const onSubmit = async (e) => {
    e.preventDefault();
    let tempPhone = phone;
    tempPhone = tempPhone.toString().replace(/\s/g, '');
    tempPhone = tempPhone.charAt(0) == '+' ? tempPhone : '+' + tempPhone;
    try {
      let response = await (
        await window.fetch('/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            phone: tempPhone,
            password: password,
            directEntry: 'false',
            timezone: moment.tz.guess()
          }),
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      setUser(response.data);
      moment.setCookieTimezone(response.data.timezone);
      localization.setCookieLanguage(response.data.language);
      navigate('/home');
    } catch (err) {
      setError(err.toString());
    }
  };

  const getQueryParams = async () => {
    try {
      const URL = window.location.href;
      let query = URL.split('?')[1];
      if (query != undefined || query != null) {
        let decodedQuery = atob(query);
        //console.log(decodedQuery);
        let queryParts = decodedQuery.split('&');
        let phone = queryParts[0].split('=')[1];
        let pwd = queryParts[1].split('=')[1];
        await setPhone(phone.toString().trim());
        await setPassword(pwd.toString());

        let response = await (
          await window.fetch('/api/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
              phone: phone,
              password: pwd,
              directEntry: 'true',
              timezone: moment.tz.guess()
            }),
          })
        ).json();
        if (response.status !== 'success') {
          throw Error(response.message);
        }
        setUser(response.data);
        moment.setCookieTimezone(response.data.timezone);
        localization.setCookieLanguage(response.data.language);
        navigate('/home');
      }
      //console.log(loginId);
      //console.log(pwd);
    } catch (err) {
      setError(err.toString());
    }
  };

  useEffect(async () => {
    getQueryParams();
    try {
      let response = await (
        await window.fetch('/api/user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            timezone: moment.tz.guess()
          }),
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      moment.setCookieTimezone(response.data.timezone);
      localization.setCookieLanguage(response.data.language);
      navigate('/home');
    } catch (err) {}
  }, []);

  // const test = async () => {
  //   try {

  //     let response = await (await window.fetch('/api/admin/notifications/slots/reminder', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       credentials: 'include',
  //       body: JSON.stringify({

  //       })
  //     })).json();

  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <div className="Login">
      <Container>
        <Row>
          <Col sm={12}></Col>
          <Col sm={12}>
            {error && (
              <Alert
                variant="danger"
                onClose={(e) => setError('')}
                dismissible
              >
                {error}
              </Alert>
            )}

            <Form onSubmit={onSubmit}>
              <Form.Select
                className="d-inline-block w-auto align-middle me-2 language-dropdown-login"
                onChange={(e) => {
                  let language = e.target.value;
                  localization.setCookieLanguage(language);
                  setIncrement(increment + 1);
                }}
              >
                {localization.getAvailableLanguages().map((language) => {
                  return (
                    <option
                      key={language}
                      value={language}
                      selected={localization.getLanguage() === language}
                    >
                      {language.toUpperCase()}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Group
                className="mb-3"
                controlId="phone"
              >
                <Form.Label>{localization['Phone Number']}</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder={localization['+1...']}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="formBasicPassword"
              >
                <Form.Label>{localization['Password']}</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                id="btnlogin"
              >
                {localization['Login']}
              </Button>
              {/*<br/><br/><Button variant="primary" type="button" id="btnlogin"  onClick={e => test()}>TEST</Button>*/}
            </Form>
          </Col>
          <Col sm={12}></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
