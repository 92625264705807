import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import localization from '../../localization';
import Pusher from 'pusher-js';
import Modal from 'react-bootstrap/Modal';
import homeLogo from '../../images/i68-icon.png';
import ReactColorPicker from '@super-effective/react-color-picker';
import Loader from '../Loader';
import Header from '../Header';
import HeaderNav from '../HeaderNav';
import { isMobile } from 'react-device-detect';
import FooterNav from '../FooterNav';
import Cookies from 'universal-cookie';

function CreateLocations({ user, logout }) {
  let navigate = useNavigate();
  const navigationData = useLocation();
  let [users, setUsers] = useState([]);
  let [groups, setGroups] = useState([]);
  // const [groupName, setGroupName] = useState('');
  // const [groupsNames, setGroupsNames] = useState([]);
  let [warning, setWarning] = useState('');
  let [success, setSuccess] = useState('');
  let [error, setError] = useState('');
  const [increment, setIncrement] = useState(1); // Force Re-Render
  let [name, setName] = useState('');
  let [bgColor, setBGColor] = useState('#0000ff');
  let [peopleNo, setPeopleNo] = useState('');
  let [selectedFiles, setSelectedFiles] = useState([]);
  let [disableButton, setDisableButton] = useState(false);
  const [fileLimit, setFileLimit] = useState(false);
  //let [selectedFileName, setSelectedFileName] = useState(null);
  let [locationDescription, setLocationDescription] = useState('');
  let [showColorPicker, setShowColorPicker] = useState(false);
  const fileInputRef = useRef(null);
  const MAX_COUNT = 5;
  const allowedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  const [showLoader, setShowLoader] = useState(false);
  const [currentPageName, setCurrentPageName] = useState('createlocations');
  const menuRef = useRef(null);
  const [newLocationId, setNewLocationId] = useState('');
  const cookies = new Cookies();
  const [hamburgerMenuActive, setHamburgerMenuActive] = useState(false);
  
  // const getGroupName = async () => {
  //   //console.log(user.userId);//return;
  //   try {
  //     let response = await (
  //       await window.fetch('/api/groups/names', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           userId: user.userId,
  //         }),
  //         credentials: 'include',
  //       })
  //     ).json();
  //     if (response.status !== 'success') {
  //       throw Error(response.message);
  //     }
  //     // setGroupName(response.data[0]['groupName']);
  //     setGroupsNames(response.data.map(item => item.groupName));
  //     //console.log('USER ',{user});
  //   } catch (err) {
  //     setWarning(localization['Something went wrong']);
  //   }
  // };

  const fetchGroups = async () => {
    try {
      let response = await (
        await window.fetch('/api/groups/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }

      setGroups(response.data);
      //console.log(response.data);
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  const onColorChange = (updatedColor) => {
    setBGColor(updatedColor);
  };
  const onColorPickerInputFocus = () => {
    setShowColorPicker(true);
  };
  const closePopup = (event) => {
    setShowColorPicker(false);
  };

  const selectLocationImage = (event) => {
    let chosenFiles = Array.prototype.slice.call(event.target.files);
    if (chosenFiles.length > 5) {
      setError('Max 5 files allowed');
      setSelectedFiles([]);
      event.target.value = null;
      return;
    }
    if (hasMaxSizeLimitExceeded(chosenFiles)) {
      setError('Max 5Mb allowed');
      setSelectedFiles([]);
      event.target.value = null;
      return;
    }
    chosenFiles.forEach((file) => {
      if (allowedFileTypes.indexOf(file.type) < 0) {
        setError('Only png and jpg files are allowed');
        setSelectedFiles([]);
        event.target.value = null;
        return;
      }
    });
    console.log('event.target.files ', chosenFiles);
    setSelectedFiles(chosenFiles);
    chosenFiles = null;
    //setSelectedFileName(event.target.files[0].name);
  };

  const hasMaxSizeLimitExceeded = (filesArray) => {
    let totalFileSize = 0;
    filesArray.forEach((file) => {
      totalFileSize += file.size;
    });
    let permittedSize = 5242880; //5Mb in bytes
    return totalFileSize > permittedSize ? true : false;
  };

  const create = async () => {
    // console.log('newLocationId ', newLocationId);
    // return;
    setDisableButton(true);
    try {
      if (!name.trim()) {
        setError(localization['Enter'] + ' ' + localization['Name']);
        setDisableButton(false);
        return;
      }
      if (!locationDescription) {
        setError(
          localization['Enter'] + ' ' + localization['Location Description']
        );
        setDisableButton(false);
        return;
      }
      if (!peopleNo) {
        setError(
          localization['Enter'] + ' ' + localization['Number of people']
        );
        setDisableButton(false);
        return;
      }
      /*if (selectedFiles.length == 0) {
        setError(localization['Choose Images']);
        setDisableButton(false);
        return;
      }*/

      //console.log("selectedFiles", selectedFiles);
      setShowLoader(true);
      const formData = new FormData();
      formData.append('name', name.trim());
      formData.append('peopleNo', peopleNo);
      formData.append('locationDescription', locationDescription.trim());
      formData.append('bgColor', bgColor.trim());
      // formData.append('groupId', user.groupId);
      formData.append('groupId', Number(navigationData.state.groupId));
      formData.append('adminType', user.admin);
      // await selectedFiles.forEach((file,index)=>{
      //   console.log(index);
      //   formData.set(("file" + parseInt(index+1)).toString() , file[index]);
      // });
      // for (let i = 0; i < selectedFiles.length; i++) {
      //       formData.append(selectedFiles[i].name, selectedFiles[i])
      //       //console.log("*********", files[i])
      //   }
      if (selectedFiles.length == 1) {
        formData.append('file1', selectedFiles[0]);
      }
      if (selectedFiles.length == 2) {
        formData.append('file1', selectedFiles[0]);
        formData.append('file2', selectedFiles[1]);
      }
      if (selectedFiles.length == 3) {
        formData.append('file1', selectedFiles[0]);
        formData.append('file2', selectedFiles[1]);
        formData.append('file3', selectedFiles[2]);
      }
      if (selectedFiles.length == 4) {
        formData.append('file1', selectedFiles[0]);
        formData.append('file2', selectedFiles[1]);
        formData.append('file3', selectedFiles[2]);
        formData.append('file4', selectedFiles[3]);
      }
      if (selectedFiles.length == 5) {
        formData.append('file1', selectedFiles[0]);
        formData.append('file2', selectedFiles[1]);
        formData.append('file3', selectedFiles[2]);
        formData.append('file4', selectedFiles[3]);
        formData.append('file5', selectedFiles[4]);
      }
      // formData.append("file1",selectedFiles[0]);
      // formData.append("file2",selectedFiles[1]);
      formData.append('totalFiles', selectedFiles.length);

      //return;
      //let response = await axios.post("/api/locations/new", formData);
      let response = await (
        await window.fetch('/api/locations/new', {
          method: 'POST',
          /* headers: {
          'Content-Type': 'application/json'
          //accept: 'application/json',
        },*/
          body: formData,
          /*body: JSON.stringify({
          name: name.trim(),
          peopleNo: peopleNo,
          locationDescription: locationDescription.trim(),
          groupId: user.groupId,
          adminType: user.admin,
          image: selectedFile
        }),*/
          credentials: 'include',
        })
      ).json();

      //console.log(' LOCATION ', response);
      if (response.status == 'error') {
        setError('Something went wrong. Please try again later.');
      } else if (response.status == 'success') {
        setSuccess('Location created successfully');
        //console.log(response.locationId);
        setNewLocationId(response.locationId.locationId);
      }
      setName('');
      setBGColor('#0000ff');
      setPeopleNo('');
      setLocationDescription('');
      setSelectedFiles([]);
      fileInputRef.current.value = '';
      setDisableButton(false);
      setShowLoader(false);
      //fetchLocations();
    } catch (err) {
      console.log(err.toString());
      setError('Something went wrong');
      setDisableButton(false);
      setShowLoader(false);
    }
    //let locationId = navigationData.state.locationId;
  };
  useEffect(async () => {
    fetchGroups();
    // getGroupName();
    // localization.setLanguage(cookies.get('language') || user.language);
    // if (cookies.get('language') != undefined && cookies.get('language') != '') {
    //   await setLanguage(cookies.get('language'));
    // }
  }, []);

  const adminPanel = () => navigate('/admin/users');
  if (!user) {
    return;
  }
  
  const groupPanel = () => navigate('/admin/groups');
  if (!user) {
    return;
  }

  // const setLanguage = async (lang) => {
  //   try {
  //     let response = await (
  //       await window.fetch('/api/admin/users/set-language', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           userId: user.userId,
  //           language: lang,
  //         }),
  //         credentials: 'include',
  //       })
  //     ).json();
  //     if (response.status !== 'success') {
  //       throw Error(response.message);
  //     } else {
  //       user.language = lang;
  //       // console.log('AFTER SET LANGUAGE called CREATE locations',user.language);
  //     }
  //   } catch (err) {
  //     setWarning(localization['Something went wrong']);
  //   }
  // };
  
  const home = () => navigate('/home');
  
  const goBack = () => navigate('/admin/slots');
  
  const showHamburgerMenu = () => {
    setHamburgerMenuActive(!hamburgerMenuActive);
    // menuRef.current.click();
  };

  return (
    <div className="home-wrapper">
      {error && (
        <Alert
          variant="danger"
          onClose={(e) => setError('')}
          dismissible
        >
          {error}
        </Alert>
      )}
      {success && (
        <Alert
          variant="success"
          onClose={(e) => setSuccess('')}
          dismissible
        >
          {success}
        </Alert>
      )}
      <Header 
        user={user}
        logout={logout}
        currentPage={currentPageName}
        showHamburgerMenu={showHamburgerMenu}
        hamburgerMenuActive={hamburgerMenuActive}
        onGroupChange={(e) => {}}
      />
      <nav className="navbar pb-0">
        <div className="nav-container">
          <HeaderNav
            user={user}
            logout={logout}
            showHamburgerMenu={showHamburgerMenu}
            hamburgerMenuActive={hamburgerMenuActive}
          />
        </div>
      </nav>
      <div className="">
        <div className="">
          <h2>{`${localization['Add']} ${localization['Location']}`}</h2>
          <button
            className="btn btn-primary float-right"
            onClick={goBack}
          >
            {localization['Back']}
          </button>
          <hr />
        </div>
        {/*<Nav variant="pills">
          <Nav.Item>
            <Form.Select
              className="d-inline-block w-auto align-middle me-2 language-dropdown"
              onChange={(e) => {
                let language = e.target.value;
                localization.setCookieLanguage(language);
                setIncrement(increment + 1);
                setLanguage(language);
              }}
            >
              {localization.getAvailableLanguages().map((language) => {
                return (
                  <option
                    key={language}
                    value={language}
                    selected={localization.getLanguage() === language}
                  >
                    {language.toUpperCase()}
                  </option>
                );
              })}
            </Form.Select>
          </Nav.Item>
        </Nav>*/}
      </div>
      <section className="home-body mt-2 pe-3">
        <div>
          <Form enctype="multipart/form-data">
            <Form.Group className="mb-3">
              <Form.Label>{localization['Location']}</Form.Label>
              <Form.Control
                type="text"
                placeholder={localization['Location']}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{localization['Location Description']}</Form.Label>
              <textarea
                placeholder={localization['Location Description']}
                className="form-control"
                value={locationDescription}
                onChange={(e) => setLocationDescription(e.target.value)}
              ></textarea>
              {/*<Form.Control type="text" placeholder={localization['Location Description']} value={locationDescription} onChange={e => setLocationDescription(e.target.value)}/> */}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{localization['Number of people']}</Form.Label>
              <Form.Control
                type="number"
                min="1"
                value={peopleNo}
                placeholder={localization['Number of people']}
                onChange={(e) => setPeopleNo(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{localization['Background Color']}</Form.Label>
              <Form.Control
                type="text"
                placeholder={localization['Background Color']}
                value={bgColor}
                onFocus={(e) => {
                  onColorPickerInputFocus();
                }}
                readOnly={true}
              />
              {showColorPicker && (
                <div className="color-picker-wrapper">
                  <div
                    className="closebutton"
                    onClick={(e) => closePopup(e)}
                  >
                    <span className="line line1"></span>
                    <span className="line line3"></span>
                  </div>
                  <ReactColorPicker
                    color={bgColor}
                    onChange={onColorChange}
                  />
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                {localization['Location'] + ' ' + localization['Picture']}
              </Form.Label>
              <Form.Control
                ref={fileInputRef}
                type="file"
                multiple
                accept="image/png, image/jpg, image/jpeg"
                onChange={(e) => selectLocationImage(e)}
              />
            </Form.Group>

            <Button
              variant="outline-success"
              type="button"
              onClick={(e) => create()}
              disabled={disableButton}
            >
              Create Location
            </Button>
          </Form>
        </div>
      </section>
      <footer>
        <FooterNav
          user={user}
          logout={logout}
          currentPage={currentPageName}
          showHamburgerMenu={showHamburgerMenu}
          hamburgerMenuActive={hamburgerMenuActive}
        />
      </footer>
      {showLoader && <Loader />}
    </div>
  );
}

export default CreateLocations;
