import './styles/App.scss';
import { useState, useEffect, useRef } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Login from './views/Login';
import Home from './views/Home';
import Assignments from './views/Assignments';
import Users from './views/admin/Users';
import Slots from './views/admin/Slots';
import Groups from './views/admin/Groups';
// import Notifications from './views/admin/Notifications';
import CreateLocations from './views/admin/CreateLocations';
import EditLocations from './views/admin/EditLocations';
import HeaderNav from '../src/views/HeaderNav';
import FooterNav from '../src/views/FooterNav';
import Logout from './views/Logout';
import Announcements from '../src/views/Announcements';
import Account from './views/Account';
import { Notifications } from './utils/Notifications';
import moment from './moment.js';
import localization from './localization.js';

function App() {
  let navigate = useNavigate();
  let location = useLocation();
  let [user, setUser] = useState({});
  // let [count, setCount] = useState(0);

  useEffect(async () => {
    try {
      let response = await (
        await window.fetch('/api/user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            timezone: moment.tz.guess()
          }),
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      setUser(response.data);
      moment.setCookieTimezone(response.data.timezone);
      localization.setCookieLanguage(response.data.language);
      // setCount(count + 1);
      if (location.pathname === '/') {
        navigate('/home');
      }
    } catch (err) {
      navigate('/login');
    }
    window.addEventListener('beforeunload', function (e) {
      //onLogout();
    });
  }, []);
  
  const checksum = useRef('');
  
  const checkSum = async () => {
    try {
      let response = await (await window.fetch(`/checksum?rnd=${Math.random()}`)).text();
      if (!response.trim()) {
        setTimeout(checkSum, 30 * 1000);
      } else if (!checksum.current) {
        checksum.current = response.trim();
        setTimeout(checkSum, 30 * 1000);
      } else if (checksum.current !== response.trim()) {
        setTimeout(() => {
          window.location.reload();
        }, 30 * 1000);
      } else {
        setTimeout(checkSum, 30 * 1000);
      }
    } catch (err) {
      console.log(err);
      setTimeout(checkSum, 30 * 1000);
    }
  };
  
  useEffect(() => {
    checkSum();
  }, []);
  
  useEffect(() => {
    if (user.email && !window.Tawk_API) {
      
      window.Tawk_API = {};
      window.Tawk_LoadStart = new Date();
      
      window.Tawk_API.onLoad = () => {
        window.Tawk_API.setAttributes({
        'name' : user.name,
        'email': user.email,
        'hash' : user.tawkHash
        }, (err) => {
          if (err) {
            console.log(err);
          }
        });
      };
      
      (() => {
        let s1 = document.createElement("script");
        let s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/62ffd72554f06e12d88fa415/1gqkbcqjg';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1, s0);
      })();
      
    }
  }, [user, window.Tawk_API]);

  const onLogout = async () => {
    try {
      let response = await (
        await window.fetch('/api/logout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
      ).json();
      // if (response.status !== 'success') {
      //   throw Error(response.message);
      // }
      setUser({});
      navigate('/login');
    } catch (err) {
      navigate('/home');
    }
  };

  return (
    <div className="App" checksum={checksum.current}>
      <Routes>
        <Route
          path="/login"
          element={<Login setUser={setUser} />}
        />
        <Route
          path="/home"
          element={
            <Home
              user={user}
              logout={onLogout}
            />
          }
        />
        <Route
          path="/myshift"
          element={
            <Assignments
              user={user}
              logout={onLogout}
            />
          }
        />
        <Route
          path="/account"
          element={
            <Account
              user={user}
              logout={onLogout}
              setUser={setUser}
            />
          }
        />
        <Route
          path="/logout"
          element={<Logout logout={onLogout} />}
        />
        {user.admin && (
          <Route
            path="/admin/createlocations"
            element={
              <CreateLocations
                user={user}
                logout={onLogout}
              />
            }
          />
        )}
        {user.admin && (
          <Route
            path="/admin/editlocations"
            element={
              <EditLocations
                user={user}
                logout={onLogout}
              />
            }
          />
        )}
        {user.admin && (
          <Route
            path="/admin/users"
            element={
              <Users
                user={user}
                logout={onLogout}
              />
            }
          />
        )}
        {user.admin && (
          <Route
            path="/admin/slots"
            element={
              <Slots
                user={user}
                logout={onLogout}
              />
            }
          />
        )}
        {user.admin && (
          <Route
            path="/admin/groups"
            element={
              <Groups
                user={user}
                logout={onLogout}
              />
            }
          />
        )}
        {/*
        {user.admin && (
          <Route
            path="/admin/notifications"
            element={
              <Notifications
                user={user}
                logout={onLogout}
              />
            }
          />
        )}
        */}
      </Routes>
      {/*<Notifications user={user} />*/}
    </div>
  );
}

export default App;
