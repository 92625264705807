import { useState, useEffect, useCallback, useRef, createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import localization from '../../localization';
import Pusher from 'pusher-js';
import Modal from 'react-bootstrap/Modal';
import homeLogo from '../../images/i68-icon.png';
import HeaderNav from '../HeaderNav';
import { isMobile } from 'react-device-detect';
import FooterNav from '../FooterNav';
import Cookies from 'universal-cookie';
import Header from '../Header';

function Groups({ user, logout }) {
  let navigate = useNavigate();
  let [groups, setGroups] = useState([]);
  let [locations, setLocations] = useState([]);
  let [newGroupName, setNewGroupName] = useState('');
  let [newLocations, setNewLocations] = useState([]);
  let [editLocations, setEditLocations] = useState([]);
  let [editGroup, setEditGroup] = useState({});
  let [activeEdit, setActiveEdit] = useState('');
  let [warning, setWarning] = useState('');
  let selectDropdown = createRef();
  // const [groupName, setGroupName] = useState('');
  // const [groupsNames, setGroupsNames] = useState([]);
  const [increment, setIncrement] = useState(1); // Force Re-Render
  const [currentPageName, setCurrentPageName] = useState('groups');
  const [usersFetched, setUsersFetched] = useState({});
  // const menuRef = useRef(null);
  const cookies = new Cookies();

  const fetchLocations = async () => {
    try {
      let response = await (
        await window.fetch('/api/locations', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            groups: user.admin === 3 ? user.groups : undefined,
            adminType: user.admin,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      setLocations(response.data);
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  const fetchGroups = async () => {
    try {
      let response = await (
        await window.fetch('/api/groups/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      // let tempArr = response.data.reduce((group, location) => {
      //   const { groupName } = location;
      //   group[groupName] = group[groupName] ?? [];
      //   group[groupName].push(location);
      //   return group;
      // }, {});
      // tempArr = Object.values(tempArr);
      setGroups(response.data);
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };
  
  const fetchUsers = async (groupId) => {
    try {
      let response = await (
        await window.fetch('/api/group/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            groupId
          })
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      // let tempArr = response.data.reduce((group, location) => {
      //   const { groupName } = location;
      //   group[groupName] = group[groupName] ?? [];
      //   group[groupName].push(location);
      //   return group;
      // }, {});
      // tempArr = Object.values(tempArr);
      let modifiedGroup = [...groups];
      for (let i in modifiedGroup) {
        if (modifiedGroup[i].groupId === groupId) {
          modifiedGroup[i].users = response.data;
        }
      }
      setGroups(modifiedGroup);
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };
  
  useEffect(() => {
    if (groups.length > 0) {
      for (let i in groups) {
        if (!groups[i].users && !usersFetched[groups[i].groupId]) {
          let fetchedUsers = {...usersFetched};
          fetchedUsers[groups[i].groupId] = 1;
          usersFetched[groups[i].groupId] = 1;
          setUsersFetched(fetchedUsers);
          fetchUsers(groups[i].groupId);
        }
      }
      setTimeout(() => {
        setUsersFetched({});
      }, 2000);
    }
  }, [groups]);

  const addGroup = async () => {
    if (newGroupName.trim() == '') {
      setWarning(localization['Enter'] + ` ` + localization['Group Name']);
      return;
    } else if (newLocations.length == 0) {
      setWarning(localization['Choose Location']);
      return;
    }

    // let sel = document.getElementById('selectLocationDropdown');
    // for (var i = 0; i < sel.options.length; ++i) {
    //   if (newLocations.indexOf(sel.options[i].value) > -1) {
    //     sel.options[i].style.display = 'none';
    //     //sel.remove(i);
    //   }
    // }
    //sel.remove(sel.selectedIndex);return;
    //selectDropdown.current.remove(selectDropdown.current.selectedIndex);
    try {
      let response = await (
        await window.fetch('/api/admin/groups/new', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            groupName: newGroupName,
            locationIds: newLocations,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      fetchGroups();
      fetchLocations();
      setNewGroupName({
        groupName: '',
      });
      setNewLocations({
        newLocations: [],
      });
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  const onUpdateGroup = async () => {
    try {
      let response = await (
        await window.fetch('/api/admin/groups/update', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            editGroup: editGroup,
            locationIds: editLocations,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      setActiveEdit('');
      setEditGroup({});
      setEditLocations([]);
      fetchGroups();
      fetchLocations();
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  const onDeleteGroup = async (groupId) => {
    try {
      if (!window.confirm(localization['Are you sure you want to delete this group?'])) {
        return;
      }

      let response = await (
        await window.fetch('/api/admin/groups/delete', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            groupId: groupId,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      fetchGroups();
      fetchLocations();
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  const onEditGroup = (group) => {
    setActiveEdit(`${group.groupId}`);
    setEditGroup({
      groupName: group.groupName,
      groupId: group.groupId,
    });
    setEditLocations(locations.filter(location => location.groupId === group.groupId).map(location => location.locationId.toString()));
  };

  useEffect(async () => {
    setActiveEdit('');
    setEditGroup({
      groupName: '',
      groupIds: [],
    });
    fetchGroups();
    fetchLocations();
    // getGroupName();
    // localization.setLanguage(cookies.get('language') || user.language);
    // if (cookies.get('language') != undefined && cookies.get('language') != '') {
    //   await setLanguage(cookies.get('language'));
    // }
  }, []);

  const handleChange = (value) => {
    // let user = {...newUser};
    // user[`${name}`] = value;
    // setNewUser(user);
    setNewGroupName(value);
  };

  const handleLocationChange = (selectObj) => {
    //return;
    let result = [];
    let options = selectObj && selectObj.options;
    let opt;

    for (let i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(opt.value || opt.text);
      }
    }
    setNewLocations(result);
  };

  const handleLocationClick = (e, locationId) => {
    // e.preventDefault();
    // e.stopPropagation();
    // if (newLocations.includes(`${locationId}`)) {
    //   setNewLocations(newLocations.filter(el => el !== `${locationId}`));
    // } else {
    //   setNewLocations(newLocations.concat([`${locationId}`]));
    // }
    // return false;
  };

  const handleEditLocationChange = (selectObj) => {
    //return;
    let result = [];
    let options = selectObj && selectObj.options;
    let opt;

    for (let i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(opt.value || opt.text);
      }
    }
    setEditLocations(result);
  };
  const handleEditLocationClick = (e, locationId) => {
    // value={editLocations}
    // e.preventDefault();
    // e.stopPropagation();
    // if (editLocations.includes(`${locationId}`)) {
    //   setEditLocations(editLocations.filter(el => el !== `${locationId}`));
    // } else {
    //   setEditLocations(editLocations.concat([`${locationId}`]));
    // }
    // return false;
  };

  const editHandleChange = (value, groupName) => {
    let group = { ...editGroup };
    group[`${groupName}`] = value;
    setEditGroup(group);
  };

  // const getGroupName = async () => {
  //   try {
  //     let response = await (
  //       await window.fetch('/api/groups/names', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           userId: user.userId,
  //         }),
  //         credentials: 'include',
  //       })
  //     ).json();
  //     if (response.status !== 'success') {
  //       throw Error(response.message);
  //     }
  //     // setGroupName(response.data[0]['groupName']);
  //     setGroupsNames(response.data.map(item => item.groupName));
  //   } catch (err) {
  //     setWarning(localization['Something went wrong']);
  //   }
  // };

  // const setLanguage = async (lang) => {
  //   try {
  //     let response = await (
  //       await window.fetch('/api/admin/users/set-language', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           userId: user.userId,
  //           language: lang,
  //         }),
  //         credentials: 'include',
  //       })
  //     ).json();
  //     if (response.status !== 'success') {
  //       throw Error(response.message);
  //     } else {
  //       user.language = lang;
  //     }
  //   } catch (err) {
  //     setWarning(localization['Something went wrong']);
  //   }
  // };

  const home = () => navigate('/home');
  
  const [hamburgerMenuActive, setHamburgerMenuActive] = useState(false);
  const showHamburgerMenu = () => {
    setHamburgerMenuActive(!hamburgerMenuActive);
    // menuRef.current.click();
  };

  return (
    <div className="home-wrapper">
      <Header 
        user={user}
        logout={logout}
        currentPage={currentPageName}
        showHamburgerMenu={showHamburgerMenu}
        hamburgerMenuActive={hamburgerMenuActive}
      />
      {/*Must wrap in navbar and nav-container in order to work*/}
      <nav className="navbar">
        <div className="nav-container">
          <HeaderNav
            user={user}
            logout={logout}
            showHamburgerMenu={showHamburgerMenu}
            hamburgerMenuActive={hamburgerMenuActive}
          />
        </div>
      </nav>
      <div>
        <h2>{localization['Groups']}</h2>
        <hr />
      </div>
      {/*<header className="header-inner d-none">
        <nav className="navbar">
          <div className="container nav-container">
            <input
              className="checkbox"
              type="checkbox"
              name=""
              id="chk_ham"
              ref={menuRef}
            />
            <label htmlFor="chk_ham"></label>
            <div className="hamburger-lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
            <HeaderNav
              user={user}
              logout={logout}
              showHamburgerMenu={showHamburgerMenu}
              hamburgerMenuActive={hamburgerMenuActive}
            />
          </div>
        </nav>
        <div className="d-flex justify-content-end mb-3 nav-custom">
          <Nav variant="pills">
            <Nav.Item>
              <Form.Select
                className="d-inline-block w-auto align-middle me-2 language-dropdown"
                onChange={(e) => {
                  let language = e.target.value;
                  localization.setCookieLanguage(language);
                  setIncrement(increment + 1);
                  setLanguage(language);
                }}
              >
                {localization.getAvailableLanguages().map((language) => {
                  return (
                    <option
                      key={language}
                      value={language}
                      selected={localization.getLanguage() === language}
                    >
                      {language.toUpperCase()}
                    </option>
                  );
                })}
              </Form.Select>
            </Nav.Item>
          </Nav>
        </div>

        <div>
          <img
            onClick={home}
            src={homeLogo}
            alt="i68"
            className="home-button"
          />
          <div className="name-location">
            <strong>
              <span
                className={user.gender == 2 ? 'female-color' : 'male-color'}
              >
                {user.name}
              </span>
              {{groupsNames.length > 0 && groupsNames.map((groupName, i) => {
                return (
                  <span> {groupName}{groupsNames[i + 1] ? ',' : ''}</span>
                );
              })}}
            </strong>
          </div>
        </div>
      </header>*/}
      <section className="home-body mt-2 pe-2">
        {warning && (
          <div className="bg-warning rounded  my-2 text-light px-2 py-1">
            <div>
              {warning}{' '}
              <span
                style={{ cursor: 'pointer' }}
                className="float-end px-2"
                onClick={(e) => setWarning('')}
              >
                x
              </span>
            </div>
          </div>
        )}
        <Table
          striped
          bordered
          hover
          size="md"
        >
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>{localization['Group Name']}</th>
              <th>{localization['Location']}</th>
              <th>{localization['Users']}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td>
                <Form.Control
                  type="text"
                  title={localization['Group Name']}
                  value={newGroupName.groupName}
                  onChange={(e) => handleChange(e.target.value)}
                />
              </td>

              <td>
                <Form.Select
                  multiple
                  value={newLocations}
                  title={localization['Choose Location']}
                  onChange={(e) => handleLocationChange(e.target)}
                  id="selectLocationDropdown"
                >
                  {locations.filter(location => !groups.find(group => group.groupId === location.groupId)).map((item, i) => {
                    return (
                      <option
                        key={item.locationId}
                        value={item.locationId}
                        onClick={(e) => handleLocationClick(e, item.locationId)}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </td>
              <td></td>
              <td>
                <Button
                  variant="outline-secondary"
                  onClick={addGroup}
                >
                  {localization['Add'] + ' ' + localization['Group']}
                </Button>
              </td>
            </tr>
            {groups.map((group, i) => {
              return (
                <tr key={group.groupId}>
                  <td>{i + 1}</td>
                  <td>
                    {group.groupId == activeEdit ? (
                      <Form.Control
                        type="text"
                        value={editGroup.groupName}
                        onChange={(e) =>
                          editHandleChange(e.target.value, 'groupName')
                        }
                      />
                    ) : (
                      group.groupName
                    )}
                  </td>
                  <td>
                    {group.groupId == activeEdit ? (
                      <Form.Select
                        multiple
                        value={editLocations}
                        title={localization['Choose Location']}
                        onChange={(e) => handleEditLocationChange(e.target)}
                      >
                        {locations.filter(location => (!groups.find(group => group.groupId === location.groupId)) || location.groupId === editGroup.groupId).map((location, j) => {
                          return (
                            <option
                              key={location.locationId}
                              value={location.locationId}
                            >
                              {location.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    ) : (
                      locations.filter(location => location.groupId === group.groupId).map((location, j) => {
                        return (
                          <span key={location.locationId}>
                            {j > 0 ? ', ' : ''}
                            {location.name}
                          </span>
                        );
                      })
                    )}
                  </td>
                  <td>
                    {group.users ? group.users.length : '0'} {localization['Users']}
                  </td>
                  <td>
                    {group.groupId == activeEdit ? (
                      <Button
                        variant="outline-secondary"
                        onClick={(e) => onUpdateGroup()}
                      >
                        {localization['Save'] + ' ' + localization['Group']}
                      </Button>
                    ) : (
                      <Button
                        variant="outline-secondary"
                        onClick={(e) => onEditGroup(group)}
                      >
                        {localization['Edit'] + ' ' + localization['Group']}
                      </Button>
                    )}
                  </td>
                  <td>
                    <Button
                      variant="outline-secondary"
                      onClick={(e) => onDeleteGroup(group.groupId)}
                      disabled={!group.users || group.users.length > 0}
                    >
                      {localization['Delete'] + ' ' + localization['Group']}
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>
      <footer>
        <FooterNav
          user={user}
          logout={logout}
          currentPage={currentPageName}
          showHamburgerMenu={showHamburgerMenu}
          hamburgerMenuActive={hamburgerMenuActive}
        />
      </footer>
    </div>
  );
}

function NewUser({ hideModal }) {
  return (
    <Modal.Dialog>
      <Modal.Header closeButton>
        <Modal.Title>Modal title</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Modal body text goes here.</p>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={hideModal}
        >
          Close
        </Button>
        <Button variant="primary">Save changes</Button>
      </Modal.Footer>
    </Modal.Dialog>
  );
}

export default Groups;
