import '../styles/views/Home.scss';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import localization from '../localization';
import Pusher from 'pusher-js';
import moment from '../moment.js';
import { isMobile } from 'react-device-detect';
import homeLogo from '../images/i68-icon.png';
import HeaderNav from './HeaderNav';
import FooterNav from './FooterNav';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Announcements from './Announcements';
import Cookies from 'universal-cookie';
import Header from './Header';
import { FormGroup, FormControlLabel, Switch, ToggleButtonGroup, ToggleButton } from '@mui/material';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

let pusher = new Pusher('b534d4fac76717b9872e', {
  cluster: 'us2',
  encrypted: true,
});

let channel = pusher.subscribe('channel');

function Assignments({ user, logout }) {
  let navigate = useNavigate();
  const [error, setError] = useState('');
  const [locations, setLocations] = useState([]);
  const [increment, setIncrement] = useState(1); // Force Re-Render
  // const [groupName, setGroupName] = useState('');
  // const [groupsNames, setGroupsNames] = useState([]);
  const [warning, setWarning] = useState('');
  const [currentPageName, setCurrentPageName] = useState('myshift');
  let [currentDay, setCurrentDay] = useState(moment.tz().toDate());
  let [daysAdded, setDaysAdded] = useState(0);
  //const [showFormatedDate, setShowFormatedDate] = useState('');
  //const [apiDate, setApiDate] = useState('');
  const cookies = new Cookies();
  let [groups, setGroups] = useState([]);
  let [selectedGroupId, setSelectedGroupId] = useState('');
  let [showAnnouncementBar, setShowAnnouncementBar] = useState(true);
  // const menuRef = useRef(null);
  const [hamburgerMenuActive, setHamburgerMenuActive] = useState(false);
  const [showExpired, setShowExpired] = useState(false);
  const [slots, setSlots] = useState({});
  const [groupedSlots, setGroupedSlots] = useState({});
  
  useEffect(async () => {
    
    if (!user.userId) {
      return;
    }
    
    // localization.setLanguage(cookies.get('language') || user.language);
    // if (cookies.get('language') != undefined && cookies.get('language') != '') {
    //   await setLanguage(cookies.get('language'));
    // }

    try {
      
      let response = await (
        await window.fetch('/api/user/locations', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: user.userId,
          }),
          credentials: 'include',
        })
      ).json();

      if (response.status == 'inactive') {
        logout();
      }
      console.log('response.data', response.data)
      setLocations(response.data);
      
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      
    } catch (err) {
      console.log(err);
    }
    
    // getGroupName();
    
  }, [user]);

  const getSlots = async () => {
    try {
      
      let responseSlot = await (
        await window.fetch('/api/slots/assignments/v2', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            userId: user.userId,
            locationIds: locations.map(location => location.locationId)
          }),
        })
      ).json();
      
      // let isDST = moment.tz().isDST();
      
      let slots = {};
      for (let i in responseSlot.data) {
        let item = responseSlot.data[i];
        let locationId = item.locationId;

        if (!slots[locationId]) {
          slots[locationId] = [];
        }
        
        let slotIndex = slots[locationId].findIndex(slot => slot.sTime === item.sTime && slot.eTime === item.eTime);
        
        if (slotIndex === -1) {
          
          let startTimeMoment = moment.utc(item.sTime).tz(user.timezone);
          // if (startTimeMoment.isDST() && !isDST) {
          //   // move one hour backwards
          //   startTimeMoment = startTimeMoment.subtract(1, 'hours');
          // } else if (!startTimeMoment.isDST() && isDST) {
          //   // move one hour forwards
          //   startTimeMoment = startTimeMoment.add(1, 'hours');
          // }
          
          let endTimeMoment = moment.utc(item.eTime).tz(user.timezone);
          // if (endTimeMoment.isDST() && !isDST) {
          //   // move one hour backwards
          //   endTimeMoment = endTimeMoment.subtract(1, 'hours');
          // } else if (!endTimeMoment.isDST() && isDST) {
          //   // move one hour forwards
          //   endTimeMoment = endTimeMoment.add(1, 'hours');
          // }
          
          let startTime = startTimeMoment.format('HH:mm');
          let endTime = endTimeMoment.format('HH:mm');
          
          slots[locationId].push({
            description: `${startTime} - ${endTime}`,
            sTime: item.sTime,
            eTime: item.eTime,
            formatted: {
              date: moment.utc(item.sTime).tz(user.timezone).format('MMMM D, YYYY'),
              month: moment.utc(item.sTime).tz(user.timezone).format('MMMM'),
              dayYear: moment.utc(item.sTime).tz(user.timezone).format('D, YYYY'),
              dayOfWeek: moment.tz().weekday(moment.utc(item.sTime).tz(user.timezone).weekday()).format('dddd'),
              expired: moment.utc(item.eTime).tz(user.timezone).valueOf() - moment.tz().valueOf() < 0 ? true : false
            },
            users: [{
              slotId: item.slotId,
              userId: item.userId,
              name: item.name,
              phone: item.phone,
              slotNo: item.slotNo,
              canceled: item.canceled,
              gender: item.gender,
            }],
          });
          
        } else {
          slots[locationId][slotIndex].users.push({
            slotId: item.slotId,
            userId: item.userId,
            name: item.name,
            phone: item.phone,
            slotNo: item.slotNo,
            canceled: item.canceled,
            gender: item.gender,
          });
        }
        
      }

      for (let [locationId, value] of Object.entries(slots)) {

        slots[locationId].sort((a, b) => {
          let an = Number(moment.utc(a.sTime).format('YYYY-MM-DD').split('-').join(''));
          let bn = Number(moment.utc(b.sTime).format('YYYY-MM-DD').split('-').join(''));
          let atn = Number(
            a.description.split(' - ').join('').split(':').join('')
          );
          let btn = Number(
            b.description.split(' - ').join('').split(':').join('')
          );
          if (an === bn) {
            return atn < btn ? -1 : atn > btn ? 1 : 0;
          } else {
            return an < bn ? -1 : 1;
          }
        });

      }
      
      setSlots(slots);
      
      let groupedSlots = {};
      for (let [locationId, value] of Object.entries(slots)) {
        if (!groupedSlots[locationId]) {
          groupedSlots[locationId] = {};
        }
        slots[locationId].forEach(slot => {
          let date = slot.formatted.date;
          if (!groupedSlots[locationId][date]) {
            groupedSlots[locationId][date] = [];
          }
          groupedSlots[locationId][date].push(slot);
        });
      }
      
      setGroupedSlots(groupedSlots);
      
      if (responseSlot.status !== 'success') {
        throw Error(responseSlot.message);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (locations.length > 0) {
      getSlots();
      channel.unbind('update');
      channel.bind('update', (data) => {
        if (data.message === 'update') {
          getSlots();
        }
      });
    }
  }, [locations]);
  
  const adminPanel = () => navigate('/admin/users');
  if (!user) {
    return;
  }
  const groupPanel = () => navigate('/admin/groups');
  if (!user) {
    return;
  }

  const myShift = () => navigate('/myshift');
  const home = () => navigate('/home');

  const handleGroupChange = (value) => {
    setSelectedGroupId(value);
  };
  
  // const setLanguage = async (lang) => {
  //   try {
  //     let response = await (
  //       await window.fetch('/api/admin/users/set-language', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           userId: user.userId,
  //           language: lang,
  //         }),
  //         credentials: 'include',
  //       })
  //     ).json();
  //     if (response.status !== 'success') {
  //       throw Error(response.message);
  //     } else {
  //       user.language = lang;
  //       //console.log('AFTER SET LANGUAGE called my shift',{user});
  //     }
  //   } catch (err) {
  //     setWarning(localization['Something went wrong']);
  //   }
  // };
  
  const showHamburgerMenu = () => {
    setHamburgerMenuActive(!hamburgerMenuActive);
    // menuRef.current.click();
  };

  const navigateToURL = () => {
    //console.log(path);
  };

  const closeAnnouncementBar = () => {
    setShowAnnouncementBar(false);
  };
  
  return (
    <div className="home-wrapper">
      {/*
      {showAnnouncementBar && (
        <Announcements
          user={user}
          onClose={closeAnnouncementBar}
          onNavigate={navigateToURL}
        />
      )}
      */}
      <Header 
        user={user}
        logout={logout}
        currentPage={currentPageName}
        showHamburgerMenu={showHamburgerMenu}
        hamburgerMenuActive={hamburgerMenuActive}
      />
      {/*Must wrap in navbar and nav-container in order to work*/}
      <nav className="navbar">
        <div className="nav-container">
          <HeaderNav
            user={user}
            logout={logout}
            showHamburgerMenu={showHamburgerMenu}
            hamburgerMenuActive={hamburgerMenuActive}
          />
        </div>
      </nav>
      <div>
        <div className="d-flex justify-content-between">
          <h2>{localization['Assignments']}</h2>
          <FormGroup className="d-flex flex-row">
            <FormControlLabel control={<Switch checked={showExpired} onChange={e => setShowExpired(e.target.checked)} />} label={localization['Show Expired']} />
            {/*
            <ToggleButtonGroup
              color="primary"
              value={showImages}
              exclusive
              onChange={(e, label) => {
                setShowImages(!showImages);
              }}
              aria-label={showImages ? ' - ' : ' + '}
            >
              <ToggleButton value="view-images">{showImages ? ' - ' : ' + '}</ToggleButton>
            </ToggleButtonGroup>
            */}
          </FormGroup>
        </div>
        <hr />
      </div>
      {/*<header className="header-inner d-none">
        <nav className="navbar">
          <div className="container nav-container">
            <input
              className="checkbox"
              type="checkbox"
              name=""
              id="chk_ham"
              ref={menuRef}
            />
            <label htmlFor="chk_ham"></label>
            <div className="hamburger-lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
   
          </div>
        </nav>
        <div className="d-flex justify-content-end mb-3 nav-custom">
          <Nav variant="pills">
            <Nav.Item>
              <Form.Select
                className="d-inline-block w-auto align-middle me-2 language-dropdown"
                onChange={(e) => {
                  let language = e.target.value;
                  localization.setCookieLanguage(language);
                  setIncrement(increment + 1);
                  setLanguage(language);
                }}
              >
                {localization.getAvailableLanguages().map((language) => {
                  return (
                    <option
                      key={language}
                      value={language}
                      selected={localization.getLanguage() === language}
                    >
                      {language.toUpperCase()}
                    </option>
                  );
                })}
              </Form.Select>
            </Nav.Item>
          </Nav>
        </div>
        
        <div>
          <img
            onClick={home}
            src={homeLogo}
            alt="i68"
            className="home-button"
          />
          <div className="name-location">
            <strong>
              <span
                className={user.gender == 2 ? 'female-color' : 'male-color'}
              >
                {user.name}
              </span>
              {{groupsNames.length > 0 && groupsNames.map((groupName, i) => {
                return (
                  <span> {groupName}{groupsNames[i + 1] ? ',' : ''}</span>
                );
              })}}
            </strong>
          </div>
        </div>
      </header>*/}
      <section className="home-body inner-body mt-0 pe-2">
        <div>
          {locations.map(location => {
            return (
              <Location
                key={location.locationId}
                location={location}
                user={user}
                showExpired={showExpired}
                slots={slots[location.locationId] || []}
                groupedSlots={groupedSlots[location.locationId] || {}}
                getSlots={getSlots}
              />
            );
          })}
        </div>
      </section>
      <footer>
        <FooterNav
          user={user}
          logout={logout}
          currentPage={currentPageName}
          showHamburgerMenu={showHamburgerMenu}
          hamburgerMenuActive={hamburgerMenuActive}
        />
      </footer>
    </div>
  );
}

function ImageSlider({ data }) {
  
  if (data.length == 1) {
    return (
      <div className="carousel mt-2">
        <Carousel>
          <img src={data[0]} />
        </Carousel>
      </div>
    );
  }
  if (data.length == 2) {
    return (
      <div className="carousel mt-2">
        <Carousel>
          <img src={data[0]} />
          <img src={data[1]} />
        </Carousel>
      </div>
    );
  }
  if (data.length == 3) {
    return (
      <div className="carousel mt-2">
        <Carousel>
          <img src={data[0]} />
          <img src={data[1]} />
          <img src={data[2]} />
        </Carousel>
      </div>
    );
  }
  if (data.length == 4) {
    return (
      <div className="carousel mt-2">
        <Carousel>
          <img src={data[0]} />
          <img src={data[1]} />
          <img src={data[2]} />
          <img src={data[3]} />
        </Carousel>
      </div>
    );
  }
  if (data.length == 5) {
    return (
      <div className="carousel mt-2">
        <Carousel>
          <img src={data[0]} />
          <img src={data[1]} />
          <img src={data[2]} />
          <img src={data[3]} />
          <img src={data[4]} />
        </Carousel>
      </div>
    );
  }
}

function Location({ location, user, showExpired, slots, groupedSlots, getSlots }) {
  
  let persons = [];
  
  for (let i = 0; i < location.peopleNo; i++) {
    if (location.name === 'Transport') {
      persons.push(localization['Driver']);
    } else {
      persons.push(`${localization['Person']} ${i + 1}`);
    }
  }

  const cancelButton = useRef(null);
  // const [slots, setSlots] = useState([]);
  // const [groupedSlots, setGroupedSlots] = useState({});

  const cancel = async (e, slotId, description, date, sTime) => {
    if (!window.confirm(localization['Are you sure you want to cancel this slot?'])) {
      return;
    }
    
    // e.target.disabled = true;
    let hours = moment.duration(moment.utc().diff(moment.utc(sTime))).asHours();
    // if (user.admin !== 1 && hours <= 2) {
    //   alert(localization['Cannot cancel as less than 2 hours left']);
    //   e.target.disabled = false;
    //   return;
    // }

    try {
      let response = await (
        await window.fetch('/api/slot/cancel', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            slotId: slotId,
            userId: user.userId,
          }),
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      getSlots();
    } catch (err) {
      console.log(err);
    }
    e.target.disabled = false;
    
  };
  
  const request = async (slotId) => {
    try {
      let response = await (
        await window.fetch('/api/slot/request', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            slotId: slotId,
            userId: user.userId,
          }),
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      getSlots();
    } catch (err) {
      console.log(err);
    }
  };
  
  const removeAfterExpiration = async (slotId) => {
    
    if (!window.confirm(`${localization['Are you sure? \nThis action will delete the historical record for users previously at this location/ time/day on their ‘Assignments’ page.']} `)) {
      return;
    }
    
    let slotIds = [];
    slotIds.push(slotId);
    
    try {
      let response = await (
        await window.fetch('/api/admin/slots/remove', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            slotIds: slotIds,
            userId: user.userId,
            expired: true, //so that will not be shown in home/myshift
          }),
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      } else {
        getSlots();
      }
    } catch (err) {
      console.log(err);
    }
    
  };
  
  // const [showExpired, setShowExpired] = useState(false);
  const [showImages, setShowImages] = useState(false);
  
  if (slots.length === 0) {
    return (
      <div></div>
    );
  }
  
  return (
    <div>
      <Table
        striped
        bordered
        hover
        responsive="sm"
      >
        <thead> 
          <tr className="border-0"
            // style={{
            //   borderWidth: '2px 0px'
            // }}
          >
            <th
              colSpan={4}
              className="assignment-header-wrapper"
            >
              <div
                className="location-header mt-4 align-items-center"
                style={{
                  backgroundColor: location.bgColor,
                }}
              >
                <h4 className="m-0">{location.name}</h4>
                <FormGroup className="d-flex flex-row">
                  <Switch checked={showImages} color="default" onChange={e => setShowImages(e.target.checked)} />
                </FormGroup>
                <div className="description" style={{
                  'display': showImages ? 'block' : 'none'
                }}>
                  {location.description}
                  {location.imagePath &&
                    location.imagePath.split(',').length > 0 && (
                      <ImageSlider data={location.imagePath.split(',')} />
                    )}
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(groupedSlots).filter(([date, slots]) => {
            if (showExpired) {
              return true;
            }
            let filteredSlots = slots.filter(slot => !slot.formatted.expired && !slot.users.find(userA => userA.userId === user.userId).canceled);
            return filteredSlots.length > 0
          }).map(([date, slots]) => {
            return (
              <React.Fragment key={date}>
                <tr>
                  <td colSpan={2} className="font-weight-bold font-bigger">{localization[slots[0].formatted.dayOfWeek]}, {localization[slots[0].formatted.month]} {slots[0].formatted.dayYear}</td>
                </tr>
                {slots.filter(slot => {
                  console.log('slot', slot)
                  if (showExpired) {
                    return true;
                  }
                  return !slot.formatted.expired && !slot.users.find(userA => userA.userId === user.userId).canceled;
                }).map(slot => {
                  return (
                    <tr key={slot.slotId}>
                      <td className="font-weight-bold no-border-right">
                        {slot.description}
                      </td>
                      <td className="text-end no-border-left">
                        {!slot.formatted.expired ?
                          <React.Fragment>
                            {slot.users.find(userA => userA.userId === user.userId).canceled ?
                              <div>{localization['Canceled']}</div>
                            :
                              <Button
                                variant="outline-danger"
                                onClick={(e) =>
                                  cancel(
                                    e,
                                    slot.users.find(userA => userA.userId === user.userId).slotId,
                                    slot.description,
                                    moment.utc(slot.sTime).tz(user.timezone).format('YYYY-MM-DD'),
                                    slot.sTime
                                  )
                                }
                              >
                                {localization['Cancel']}
                              </Button>
                            }
                          </React.Fragment>
                        :
                          <React.Fragment>
                            {slot.users.find(userA => userA.userId === user.userId).canceled ?
                              <div>{localization['Canceled']}</div>
                            :
                              <div>{localization['Expired']}</div>
                            }
                            {user.admin != 0 && (
                              <Button
                                variant="outline-danger"
                                className="mt-2 mb-2"
                                onClick={(e) =>
                                  removeAfterExpiration(
                                    slot.users.find(userA => userA.userId === user.userId).slotId
                                  )
                                }
                              >
                                {localization['Remove']}
                              </Button>
                            )}
                          </React.Fragment>
                        }
                      </td>
                    </tr>
                  );
                })}
              </React.Fragment>
            );  
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default Assignments;
