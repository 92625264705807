let Translation = {
  "en": {
    "Language": "Language",
    "Phone Number": "Phone Number",
    "+1...": "+1...",
    "Password": "Password",
    "Login": "Login",
    "Name": "Name",
    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December",
    "Shift": "Shift",
    "Date": "Date",
    "Month": "Month",
    "Day": "Day",
    "Person": "Person",
    "Driver": "Driver",
    "Logout": "Logout",
    "Request": "Request",
    "Open": "Open",
    "Add": "Add",
    "Edit": "Edit",
    "User": "User",
    "Users": "Users",
    "Enter": "Enter",
    "Admin": "Admin",
    "Admin Panel": "Admin Panel",
    "Save": "Save",
    "Make": "Make",
    "Make Admin": "Make Admin",
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "Sunday": "Sunday",
    "Something went wrong": "Something went wrong",
    "Home": "Home",
    "Canceled": "Canceled",
    "Cancel": "Cancel",
    "Restore": "Restore",
    "Slots": "Slots",
    "Remove": "Remove",
    "Delete": "Delete",
    "Delete User": "Delete User",
    "Are you sure you want to delete the user?": "Are you sure you want to delete the user?",
    "Are you sure you want to cancel?": "Are you sure you want to cancel?",
    "Number of people": "Number of people",
    "Remove Location": "Remove Location",
    "Shift cancel message": "Shift cancel message",
    "Assignments": "Assignments",
    "Choose Location": "Choose Location",
    "Choose Group": "Choose Group",
    "Choose Language": "Choose Language",
    "Choose Shift": "Choose Shift",
    "Choose Images": "Choose Images",
    "Choose Email Notification": "Choose Email Notification",
    "Group": "Group",
    "Groups": "Groups",
    "Group Name": "Group Name",
    "Are you sure you want to delete this group?": "Are you sure you want to delete this group?",
    "Gender": "Gender",
    "Choose Gender": "Choose Gender",
    "Today": "Today",
    "Next Day": "Next Day",
    "Previous Day": "Previous Day",
    "Repeat": "Repeat",
    "Choose Date & Slots": "Choose Date & Slots",
    "Picture": "Picture",
    "Location Description": "Location Description",
    "Email": "Email",
    "Do you want to book this slot?\n(It will be possible to cancel the duty no later than 2 hours before it starts)": "Do you want to book this slot?\n(It will be possible to cancel the duty no later than 2 hours before it starts)",
    "Create New Location": "Create New Location",
    "Edit Location": "Edit Location",
    "Positions": "Positions",
    "Status": "Status",
    "Timeframe": "Timeframe",
    "Background Color": "Background Color",
    "Start Time": "Start Time",
    "End Time": "End Time",
    "Back": "Back",
    "Image removed": "Image removed",
    "No slots booked": "No slots booked",
    "created succesfully": "created succesfully",
    "Email Notification": "Email Notification",
    "Resend": "Resend",
    "Send": "Send",
    "Login with credentials": "Login with credentials",
    "Automatic login": "Automatic login",
    "Unavailable": "Unavailable",
    "Expired": "Expired",
    "Start time cannot be before current time": "Start time cannot be before current time",
    "End time cannot be before start time": "End time cannot be before start time",
    "Do you want to call this person?": "Do you want to call this person?",
    "You": "You",
    "Are you sure you want to cancel this slot?": "Are you sure you want to cancel this slot?",
    "Are you sure? \nThis action will delete the historical record for users previously at this location/ time/day on their ‘Assignments’ page.": "Are you sure? \nThis action will delete the historical record for users previously at this location/ time/day on their ‘Assignments’ page.",
    "Are you sure? \nThis will permanently delete this slot and user data completely from the database. This action is irreversible.": "Are you sure? \nThis will permanently delete this slot and user data completely from the database. This action is irreversible.",
    "Show All": "Show All",
    "Male": "Male",
    "Female": "Female",
    "Activate": "Activate",
    "Deactivate": "Deactivate",
    "Next 7 Day": "Next 7 Day",
    "Previous 7 Day": "Previous 7 Day",
    "Notifications": "Notifications",
    "This will open external link in new window": "This will open external link in new window",
    "The URL looks invalid": "The URL looks invalid",
    "We've sent you an email. Please click the link in the email to accept receiving notifications from i68.com.au.": "We've sent you an email. Please click the link in the email to accept receiving notifications from i68.com.au.",
    "Assignment begins in 2 hours": "Assignment begins in 2 hours",
    "Hello": "Hello",
    "This is a reminder that your assignment": "This is a reminder that your assignment",
    "Time": "Time",
    "Location": "Location",
    "Will begin in one hour from now.": "Will begin in one hour from now.",
    "Regards": "Regards",
    "Slot Booked": "Slot Booked",
    "Your ASSIGNMENT has been CREATED at": "Your ASSIGNMENT has been CREATED at",
    "My Assignments": "My Assignments",
    "Slot Canceled": "Slot Canceled",
    "Your ASSIGNMENT has been CANCELLED at": "Your ASSIGNMENT has been CANCELLED at",
    "Slot Deleted": "Slot Deleted",
    "Your ASSIGNMENT has been DELETED at": "Your ASSIGNMENT has been DELETED at",
    "Slot Restored": "Slot Restored",
    "Your ASSIGNMENT has been RESTORED at": "Your ASSIGNMENT has been RESTORED at",
    "Account Created": "Account Created",
    "Your account has been created in the i68 App. Please click the button below to login.": "Your account has been created in the i68 App. Please click the button below to login.",
    "Your account has been created in the i68 App. You can use the following details to login.": "Your account has been created in the i68 App. You can use the following details to login.",
    "You can login using the following button": "You can login using the following button",
    "Please allow notifications from i68.com.au.": "Please allow notifications from i68.com.au.",
    "Will begin in 2 hours from now.": "Will begin in 2 hours from now.",
    "will begin in 2 hours from now.": "will begin in 2 hours from now.",
    "will begin in 2 hours or less.": "will begin in 2 hours or less.",
    "Your assignment on location": "Your assignment on location",
    "Your assignment at location": "Your assignment at location",
    "Select Groups": "Select Groups",
    "Donate": "Donate",
    "Primary Group": "Primary Group",
    "Search": "Search",
    "Support": "Support",
    "My Account": "My Account",
    "Repeat Weekly": "Repeat Weekly",
    "Create": "Create",
    "Active": "Active",
    "Disabled": "Disabled",
    "Minimum duration is 30 minutes": "Minimum duration is 30 minutes",
    "Maximum duration is 12 hours": "Maximum duration is 12 hours",
    "Change Your Timezone": "Change Your Timezone",
    "Account Settings": "Account Settings",
    "Account": "Account",
    "Your Timezone": "Your Timezone",
    "Are you sure you want to restore?": "Are you sure you want to restore?",
    "more": "more",
    "expand": "expand",
    "Details": "Details",
    "Show Expired": "Show Expired",
    "Full": "Full",
    "Available": "Available",
    "Change": "Change",
    "Update Location": "Update Location",
    "Location Updated successfully": "Location Updated successfully",
    "Move Up": "Move Up",
    "Move Down": "Move Down",
    "Order": "Order",
    "Confirm Request": "Confirm Request",
    "ASSIGNMENT STARTING SOON at": "ASSIGNMENT STARTING SOON at",
    "ASSIGNMENT CREATED at": "ASSIGNMENT CREATED at",
    "ASSIGNMENT CANCELED at": "ASSIGNMENT CANCELED at",
    "ASSIGNMENT RESTORED at": "ASSIGNMENT RESTORED at",
    "To view or manage your assignments click here": "To view or manage your assignments click here",
    "Your ASSIGNMENT is STARTING SOON at": "Your ASSIGNMENT is STARTING SOON at",
    "ASSIGNMENT BEGINS tomorrow at": "ASSIGNMENT BEGINS tomorrow at",
    "Your ASSIGNMENT BEGINS tomorrow at": "Your ASSIGNMENT BEGINS tomorrow at",
    "Position": "Position",
    "Download": "Download",
    "Locations": "Locations",
    "My events": "My events",
    "Settings": "Settings",
    "List": "List",
    "Map": "Map",
    "Calendar": "Calendar",
    "Events in next week": "Events in next week",
    "Other events in": "Other events in",
    "Coming soon events": "Coming soon events",
    "more required": "more required",
    "Open in Google maps": "Open in Google maps",
    "Date range": "Date range",
    "Next week at": "Next week at",
    "Also at": "Also at",
    "Coming soon at": "Coming soon at",
    "in": "in",
    "Geo-Location": "Geo-Location",
    "Place Name": "Place Name",
    "Directions": "Directions",
    "Registered users": "Registered users",
    "There is no registered users yet.": "There is no registered users yet.",
    "Attendees": "Attendees",
    "There are no attendees yet.": "There are no attendees yet.",
    "Cancel Event": "Cancel Event",
    "Join Event": "Join Event",
    "Joining": "Joining",
    "Cancelling": "Cancelling",
    "Danger message": "Danger message",
    "Warning message": "Warning message",
    "Successfully message": "Successfully message",
    "Missing slotId": "Missing slotId",
    "Missing userId": "Missing userId",
    "Slot not found.": "Slot not found.",
    "Slot occupied.": "Slot occupied.",
    "You joined the group!": "You joined the group!",
    "You cancelled the group.": "You cancelled the group.",
    "Something went wrong.": "Something went wrong.",
    "Are you sure you want to cancel this event?": "Are you sure you want to cancel this event?",
    "Missing slot.": "Missing slot.",
    "Missing location.": "Missing location.",
    "My Events": "My Events",
    "Leave Event": "Leave Event",
    "Leaving": "Leaving",
    "Image": "Image",
    "Menu": "Menu",
    "Your assignment has been created": "Your assignment has been created",
    "successfully": "successfully",
    "To view and manage your assignments click on button below": "To view and manage your assignments click on button below",
    "View my assignments": "View my assignments",
    "Description": "Description",
    "There is no description for this event.": "There is no description for this event.",
    "Redirecting error. Try again later.": "Redirecting error. Try again later.",
    "Join Beta": "Join Beta",
    "Join": "Join",
    "Application Version": "Application Version",
    "Beta": "Beta",
    "Cannot cancel as less than 2 hours left": "Cannot cancel as less than 2 hours left"
  },
  "pl": {
    "Language": "Język",
    "Phone Number": "Numer telefonu",
    "+1...": "+48...",
    "Password": "Hasło",
    "Login": "Zaloguj się  ",
    "Name": "Imię",
    "January": "Styczeń",
    "February": "Luty",
    "March": "Marzec",
    "April": "Kwiecień",
    "May": "Maj",
    "June": "Czerwiec",
    "July": "Lipiec",
    "August": "Sierpień",
    "September": "Wrzesień",
    "October": "Październik",
    "November": "Listopad",
    "December": "Grudzień",
    "Shift": "Zmiana",
    "Date": "Data",
    "Month": "Miesiąc",
    "Day": "Dzień",
    "Person": "Osoba",
    "Driver": "Kierowca",
    "Logout": "Wyloguj",
    "Request": "Rezerwuj",
    "Open": "Otwarty",
    "Add": "Dodaj",
    "Edit": "Edytuj  ",
    "User": "Użytkownik",
    "Users": "Użytkownicy",
    "Enter": "Zapisz",
    "Admin": "Admin",
    "Admin Panel": "Panel Administratora",
    "Save": "Zapisz",
    "Make": "Zrób",
    "Make Admin": "Zrób adminem",
    "Monday": "Poniedziałek",
    "Tuesday": "Wtorek",
    "Wednesday": "Środa",
    "Thursday": "Czwartek",
    "Friday": "Piątek",
    "Saturday": "Sobota",
    "Sunday": "Niedziela",
    "Something went wrong": "Coś poszło nie tak",
    "Home": "Grafik",
    "Canceled": "Odwołany",
    "Cancel": "Anuluj",
    "Restore": "Przywróć",
    "Slots": "Miejsce",
    "Remove": "Usuń",
    "Delete": "Usuń",
    "Delete User": "Usuń Użytkownika",
    "Are you sure you want to delete the user?": "Czy na pewno chcesz usunąć użytkownika?",
    "Are you sure you want to cancel?": "Czy na pewno chcesz anulować?",
    "Number of people": "Liczba osób na jednej zmianie",
    "Remove Location": "Usuń lokalizację",
    "Shift cancel message": "Nie można usunąć - pozostało mniej niż 2 godziny",
    "Assignments": "Dyżury",
    "Choose Location": "Wybierz Lokalizacja",
    "Choose Group": "Wybierz grupę",
    "Choose Language": "Wybierz język",
    "Choose Shift": "Wybierz zmianę",
    "Choose Images": "Wybierz obrazy",
    "Choose Email Notification": "Wybierz powiadomienie e-mail",
    "Group": "Grupa",
    "Groups": "Grupy",
    "Group Name": "Nazwa grupy",
    "Are you sure you want to delete this group?": "Czy na pewno chcesz usunąć tę grupę?",
    "Gender": "płeć",
    "Choose Gender": "Wybierz płeć",
    "Today": "Dziś",
    "Next Day": "Następny dzień",
    "Previous Day": "Poprzedni dzień",
    "Repeat": "Powtarzaj  ",
    "Choose Date & Slots": "Wybierz datę i miejsce",
    "Picture": "Obrazek",
    "Location Description": "Opis lokalizacji",
    "Email": "poczta elektroniczna",
    "Do you want to book this slot?\n(It will be possible to cancel the duty no later than 2 hours before it starts)": "Chcesz zarezerwować ten dyżur?\n(Anulowanie dyżuru będzie możliwe najpóźniej na 2 godziny przed jego rozpoczęciem)",
    "Create New Location": "Utwórz nową lokalizację",
    "Edit Location": "Edytuj lokalizację",
    "Positions": "Pozycje",
    "Status": "Status",
    "Timeframe": "Ramy czasowe",
    "Background Color": "Kolor tła",
    "Start Time": "Czas rozpoczęcia",
    "End Time": "Koniec czasu",
    "Back": "Wróć",
    "Image removed": "Obraz usunięty",
    "No slots booked": "Brak zarezerwowanych dyżurów",
    "created succesfully": "Utworzone pomyślnie",
    "Email Notification": "Powiadomienie e-mail",
    "Resend": "Wyślij ponownie",
    "Send": "Wyślij  ",
    "Login with credentials": "Zaloguj się z poświadczeniami",
    "Automatic login": "Automatyczne logowanie",
    "Unavailable": "Niedostępne",
    "Expired": "Wygasły",
    "Start time cannot be before current time": "Czas rozpoczęcia nie może być wcześniejszy niż czas bieżący",
    "End time cannot be before start time": "Czas zakończenia nie może być wcześniejszy niż czas rozpoczęcia",
    "Do you want to call this person?": "Chcesz zadzwonić do tej osoby?",
    "You": "Ty",
    "Are you sure you want to cancel this slot?": "Czy na pewno chcesz anulować to miejsce?",
    "Are you sure? \nThis action will delete the historical record for users previously at this location/ time/day on their ‘Assignments’ page.": "Jesteś pewny? \nTa czynność usunie dane użytkowników, którzy byli wcześniej w tej lokalizacji/godzinie/dniu na stronie ‘Dyżury’",
    "Are you sure? \nThis will permanently delete this slot and user data completely from the database. This action is irreversible.": "Jesteś pewny? \nSpowoduje to trwałe usunięcie tego miejsca i danych użytkownika z bazy danych. To działanie jest nieodwracalne.",
    "Show All": "Pokaż wszystko",
    "Male": "Mężczyzna",
    "Female": "Kobieta",
    "Activate": "Aktywuj",
    "Deactivate": "Deaktywuj",
    "Next 7 Day": "Następny 7 dzień",
    "Previous 7 Day": "Poprzedni 7 dzień",
    "Notifications": "Powiadomienie",
    "This will open external link in new window": "This will open external link in new window",
    "The URL looks invalid": "The URL looks invalid",
    "We've sent you an email. Please click the link in the email to accept receiving notifications from i68.com.au.": "Wysłaliśmy Ci wiadomość e-mail. Kliknij łącze w wiadomości e-mail, aby zaakceptować otrzymywanie powiadomień od i68.com.au.",
    "Assignment begins in 2 hours": "Dyżur rozpocznie się za 2 godz",
    "Hello": "Dzień dobry",
    "This is a reminder that your assignment": "To jest przypomnienie o nadchodzącym dyżurze",
    "Time": "Godzina",
    "Location": "Miejsce",
    "Will begin in one hour from now.": "Rozpocznie się za godzinę.",
    "Regards": "Pozdrowienia",
    "Slot Booked": "Zarezerwowano dyżur",
    "Your ASSIGNMENT has been CREATED at": "Twoja REZERWACJA została pomyślnie UTWORZONA. Znajdziesz ją w zakładce",
    "My Assignments": "Moje Dyżury",
    "Slot Canceled": "Anulowano dyżur",
    "Your ASSIGNMENT has been CANCELLED at": "Twoje ZADANIE zostało usunięte",
    "Slot Deleted": "Usunięto dyżur",
    "Your ASSIGNMENT has been DELETED at": "Twoje ZADANIE zostało usunięte",
    "Slot Restored": "Przywrócono dyżur",
    "Your ASSIGNMENT has been RESTORED at": "Twoje ZADANIE zostało przywrócone",
    "Account Created": "Utworzono konto",
    "Your account has been created in the i68 App. Please click the button below to login.": "Twoje konto zostało utworzone w aplikacji i68. Kliknij poniższy link, aby się zalogować.",
    "Your account has been created in the i68 App. You can use the following details to login.": "Twoje konto zostało utworzone w aplikacji i68. Aby się zalogować, możesz użyć następujących danych.",
    "You can login using the following button": "Możesz również zalogować się za pomocą następującego linku",
    "Please allow notifications from i68.com.au.": "Zezwól na powiadomienia z i68.com.au.",
    "Will begin in 2 hours from now.": "Rozpocznie się za 2 godziny lub mniej",
    "will begin in 2 hours from now.": "rozpocznie się za 2 godziny lub mniej",
    "will begin in 2 hours or less.": "rozpocznie się za 2 godziny lub mniej",
    "Your assignment on location": "Twoje zadanie na miejscu",
    "Your assignment at location": "Twoje zadanie na miejscu",
    "Select Groups": "Wybierz Grupy",
    "Donate": "Wesprzyj datkiem",
    "Primary Group": "Główna grupa",
    "Search": "Szukaj",
    "Support": "Wsparcie",
    "My Account": "Moje Konto",
    "Repeat Weekly": "Powtarzaj co tydzień",
    "Create": "Tworzyć",
    "Active": "Aktywny",
    "Disabled": "Wyłączony",
    "Minimum duration is 30 minutes": "Minimalny czas trwania to 30 minut",
    "Maximum duration is 12 hours": "Maksymalny czas trwania to 12 godzin",
    "Change Your Timezone": "Zmień swoją strefę czasową",
    "Account Settings": "Ustawienia Konta",
    "Account": "Konto",
    "Your Timezone": "Twoja strefa czasowa",
    "Are you sure you want to restore?": "Czy na pewno chcesz przywrócić?",
    "more": "więcej",
    "expand": "zwiększać",
    "Details": "Detale",
    "Show Expired": "Pokaż Wygasły",
    "Full": "Pełny",
    "Available": "Wolne",
    "Change": "Zmiana",
    "Update Location": "Zaktualizuj Lokalizację",
    "Location Updated successfully": "Lokalizacja Została Pomyślnie Zaktualizowana",
    "Move Up": "Podnieść",
    "Move Down": "Padnij",
    "Order": "Uszeregować",
    "Confirm Request": "Potwierdź",
    "ASSIGNMENT STARTING SOON at": "TWOJE ZADANIE NIEDŁUGO SIĘ ROZPOCZNIE ",
    "ASSIGNMENT CREATED at": "ZADANIE UTWORZONE",
    "ASSIGNMENT CANCELED at": "ZADANIE USUNIĘTE",
    "ASSIGNMENT RESTORED at": "ZADANIE PRZYWRÓCONE",
    "To view or manage your assignments click here": "Żeby zobaczyć lub zmienić twoje zadanie naciśnij tutaj",
    "Your ASSIGNMENT is STARTING SOON at": "Twoje ZADANIE niedługo się rozpocznie ",
    "ASSIGNMENT BEGINS tomorrow at": "ZADANIE ROZPOCZNIE SIĘ jutro",
    "Your ASSIGNMENT BEGINS tomorrow at": "Twoje ZADANIE ROZPOCZNIE SIĘ jutro",
    "Position": "Położenie",
    "Download": "Ściąganie",
    "Locations": "Locations",
    "My events": "My events",
    "Settings": "Settings",
    "List": "List",
    "Map": "Map",
    "Calendar": "Calendar",
    "Events in next week": "Events in next week",
    "Other events in": "Other events in",
    "Coming soon events": "Coming soon events",
    "more required": "more required",
    "Open in Google maps": "Open in Google maps",
    "Date range": "Date range",
    "Next week at": "Next week at",
    "Also at": "Also at",
    "Coming soon at": "Coming soon at",
    "in": "in",
    "Geo-Location": "Geo-Location",
    "Place Name": "Place Name",
    "Directions": "Directions",
    "Registered users": "Registered users",
    "There is no registered users yet.": "There is no registered users yet.",
    "Attendees": "Attendees",
    "There are no attendees yet.": "There are no attendees yet.",
    "Cancel Event": "Cancel Event",
    "Join Event": "Join Event",
    "Joining": "Joining",
    "Cancelling": "Cancelling",
    "Danger message": "Danger message",
    "Warning message": "Warning message",
    "Successfully message": "Successfully message",
    "Missing slotId": "Missing slotId",
    "Missing userId": "Missing userId",
    "Slot not found.": "Slot not found.",
    "Slot occupied.": "Slot occupied.",
    "You joined the group!": "You joined the group!",
    "You cancelled the group.": "You cancelled the group.",
    "Something went wrong.": "Something went wrong.",
    "Are you sure you want to cancel this event?": "Are you sure you want to cancel this event?",
    "Missing slot.": "Missing slot.",
    "Missing location.": "Missing location.",
    "My Events": "My Events",
    "Leave Event": "Leave Event",
    "Leaving": "Leaving",
    "Image": "Image",
    "Menu": "Menu",
    "Your assignment has been created": "Your assignment has been created",
    "successfully": "successfully",
    "To view and manage your assignments click on button below": "To view and manage your assignments click on button below",
    "View my assignments": "View my assignments",
    "Description": "Description",
    "There is no description for this event.": "There is no description for this event.",
    "Redirecting error. Try again later.": "Redirecting error. Try again later.",
    "Join Beta": "Join Beta",
    "Join": "Join",
    "Application Version": "Application Version",
    "Beta": "Beta",
    "Cannot cancel as less than 2 hours left": "Nie można anulować, ponieważ pozostało mniej niż 2 godziny"
  },
  "sv": {
    "Language": "Språk",
    "Phone Number": "Telefonnummer",
    "+1...": "+48...",
    "Password": "Lösenord",
    "Login": "Logga in",
    "Name": "Namn",
    "January": "Januari",
    "February": "Februari",
    "March": "Mars",
    "April": "April",
    "May": "Maj",
    "June": "Juni",
    "July": "Juli",
    "August": "Augusti",
    "September": "September",
    "October": "Oktober",
    "November": "November",
    "December": "December",
    "Shift": "Pass",
    "Date": "Datum",
    "Month": "Månad",
    "Day": "Dag",
    "Person": "Person",
    "Driver": "Förare",
    "Logout": "Logga ut",
    "Request": "Boka",
    "Open": "Öppet",
    "Add": "Lägg till",
    "Edit": "Redigera",
    "User": "Användare",
    "Users": "Användarnas",
    "Enter": "Enter",
    "Admin": "Admin",
    "Admin Panel": "Administratörspanel",
    "Save": "Spara",
    "Make": "Göra",
    "Make Admin": "Göra admin",
    "Monday": "Måndag",
    "Tuesday": "Tisdag",
    "Wednesday": "Onsdag",
    "Thursday": "Torsdag",
    "Friday": "Fredag",
    "Saturday": "Lördag",
    "Sunday": "Söndag",
    "Something went wrong": "Något gick fel",
    "Home": "Schema",
    "Canceled": "Avbokat",
    "Cancel": "Radera",
    "Restore": "Återställa",
    "Slots": "Plats",
    "Remove": "Ta bort",
    "Delete": "Ta bort",
    "Delete User": "Ta bort användare",
    "Are you sure you want to delete the user?": "Är du säker att ta bort användare?",
    "Are you sure you want to cancel?": "Är du säker att radera?",
    "Number of people": "Antal av personer per skift",
    "Remove Location": "Ta bort plats",
    "Shift cancel message": "Du kan avboka passet max 2 timmar innan det börjar.",
    "Assignments": "Skift",
    "Choose Location": "Välj plats",
    "Choose Group": "Välj grupp",
    "Choose Language": "Välj språk",
    "Choose Shift": "Välj skift",
    "Choose Images": "Välj bilder",
    "Choose Email Notification": "Wybierz powiadomienie e-mail",
    "Group": "Grupp",
    "Groups": "Grupper",
    "Group Name": "Grupp namn",
    "Are you sure you want to delete this group?": "Är du säker att ta bort gruppen?",
    "Gender": "Kön",
    "Choose Gender": "Välj kön",
    "Today": "Idag",
    "Next Day": "Nästa dag",
    "Previous Day": "Dag innan",
    "Repeat": "Repetera",
    "Choose Date & Slots": "Välj datum och plats",
    "Picture": "Bild",
    "Location Description": "Plats beskrivning",
    "Email": "Email",
    "Do you want to book this slot?\n(It will be possible to cancel the duty no later than 2 hours before it starts)": "Vill du boka den här pass? (du kan avboka max 2 timmar innan passet börjar)",
    "Create New Location": "Skapa ny plats",
    "Edit Location": "Redigera plats",
    "Positions": "Placering",
    "Status": "Status",
    "Timeframe": "Tid gränser",
    "Background Color": "Bakgrundsfärg",
    "Start Time": "Starttid",
    "End Time": "Stopptid",
    "Back": "Backa",
    "Image removed": "Bilden har tagits bort",
    "No slots booked": "Inga inbokade skift",
    "created succesfully": "Skapat framgångsrikt",
    "Email Notification": "Underrättelse e-mail.",
    "Resend": "Skicka igen",
    "Send": "Skicka",
    "Login with credentials": "Logga in med autentisering",
    "Automatic login": "Logga in automatisk",
    "Unavailable": "Ej tillgänglig",
    "Expired": "Utgå",
    "Start time cannot be before current time": "Starttiden kan inte vara före aktuell tid",
    "End time cannot be before start time": "Stopptiden kan inte vara före aktuell tid",
    "Do you want to call this person?": "Vill du ringa den person?",
    "You": "Du  ",
    "Are you sure you want to cancel this slot?": "Vill du radera den här passet?",
    "Are you sure? \nThis action will delete the historical record for users previously at this location/ time/day on their ‘Assignments’ page.": "Är du säker? \nDen här åtgärden kommer att radera data från användare som tidigare var på denna plats/tid/dag på sidan Skift",
    "Are you sure? \nThis will permanently delete this slot and user data completely from the database. This action is irreversible.": "Är du säker? \nDetta kommer att radera detta utrymme och användaredata permanent från databasen. Denna åtgärd är oåterkallelig.",
    "Show All": "Visa allt.",
    "Male": "Man",
    "Female": "Kvinna",
    "Activate": "Aktivera",
    "Deactivate": "Deaktivera",
    "Next 7 Day": "Nästa vecka",
    "Previous 7 Day": "Före vecka",
    "Notifications": "Meddelanden",
    "This will open external link in new window": "Detta öppnar extern länk i ny flik.",
    "The URL looks invalid": "Webadressen ser gilltig ut.",
    "We've sent you an email. Please click the link in the email to accept receiving notifications from i68.com.au.": "Vi har skickat ett meddelande till dig. Klicka på länken i e-postmeddelandet för att acceptera meddelanden från i68 boknings system.",
    "Assignment begins in 2 hours": "Din pass kommer att börja i 2 timmar.",
    "Hello": "Hej",
    "This is a reminder that your assignment": "Påminnelse om kommande pass.",
    "Time": "Tid  ",
    "Location": "Plats",
    "Will begin in one hour from now.": "Börjar om en timme.",
    "Regards": "Hälsningar.",
    "Slot Booked": "Du har bokat pass.",
    "Your ASSIGNMENT has been CREATED at": "Din BOKNING har SKAPATS. Du hittar den i fliken ",
    "My Assignments": "Mina pass",
    "Slot Canceled": "Du har avbrutit pass.",
    "Your ASSIGNMENT has been CANCELLED at": "Din PASS är AVBRUTIT av",
    "Slot Deleted": "Pass har raderat",
    "Your ASSIGNMENT has been DELETED at": "Din PASS är RADERAT av",
    "Slot Restored": "Pass återställd",
    "Your ASSIGNMENT has been RESTORED at": "Din PASS är ÅTERSTÄLLD av",
    "Account Created": "Konto skapat",
    "Your account has been created in the i68 App. Please click the button below to login.": "Ditt konto har skapat i i68 bokningssytem. Klicka på knappen nedan för att logga in.",
    "Your account has been created in the i68 App. You can use the following details to login.": "Ditt konto har skapat i i68 bokningssystem. Du kan använda följande uppgifter för att logga in.",
    "You can login using the following button": "Du kan logga in med följande knappen.",
    "Please allow notifications from i68.com.au.": "Tillåt meddelanden från i68.com.au.",
    "Will begin in 2 hours from now.": "Börjar om 2 timmar eller mindre",
    "will begin in 2 hours from now.": "börjar om 2 timmar eller mindre",
    "will begin in 2 hours or less.": "börjar om 2 timmar eller mindre",
    "Your assignment on location": "Ditt uppdrag på plats",
    "Your assignment at location": "Ditt uppdrag på plats",
    "Select Groups": "Välj Grupper",
    "Donate": "Donera",
    "Primary Group": "Primärgrupp",
    "Search": "Sök",
    "Support": "Support",
    "My Account": "Mitt konto",
    "Repeat Weekly": "Upprepa varje vecka",
    "Create": "Skapa",
    "Active": "Aktiva",
    "Disabled": "Inaktiverad",
    "Minimum duration is 30 minutes": "Minsta varaktighet är 30 minuter",
    "Maximum duration is 12 hours": "Maximal varaktighet är 12 timmar",
    "Change Your Timezone": "Ändra din tidszon",
    "Account Settings": "Kontoinställningar",
    "Account": "Konto",
    "Your Timezone": "Min tidszon",
    "Are you sure you want to restore?": "Är du säker på att du vill återställa?",
    "more": "Mer",
    "expand": "visa mer",
    "Details": "Detaljer",
    "Show Expired": "Visa Utgången",
    "Full": "Fullständig",
    "Available": "Anträffbar",
    "Change": "Förändra",
    "Update Location": "Uppdatera Plats",
    "Location Updated successfully": "Platsen Har Uppdaterats",
    "Move Up": "Flytta upp",
    "Move Down": "Flytta ner",
    "Order": "Uppställning",
    "Confirm Request": "Bekräfta Begäran",
    "ASSIGNMENT STARTING SOON at": "UPPDRAG STARTAR SNART kl",
    "ASSIGNMENT CREATED at": "UPPGIFT SKAPAD kl",
    "ASSIGNMENT CANCELED at": "UPPGIFT INSTÄLLT kl",
    "ASSIGNMENT RESTORED at": "UPPGIFT ÅTERSTÄLLD kl",
    "To view or manage your assignments click here": "Klicka här för att se eller hantera dina uppdrag",
    "Your ASSIGNMENT is STARTING SOON at": "Ditt UPPDRAG STARTAR SNART kl",
    "ASSIGNMENT BEGINS tomorrow at": "UPPGIFTET STARTAR imorgon kl",
    "Your ASSIGNMENT BEGINS tomorrow at": "Ditt UPPGIFT STARTAR imorgon kl",
    "Position": "Placera",
    "Download": "Ladda ner",
    "Locations": "Locations",
    "My events": "My events",
    "Settings": "Settings",
    "List": "List",
    "Map": "Map",
    "Events in next week": "Events in next week",
    "Other events in": "Other events in",
    "Coming soon events": "Coming soon events",
    "more required": "more required",
    "Open in Google maps": "Open in Google maps",
    "Date range": "Date range",
    "Next week at": "Next week at",
    "Also at": "Also at",
    "Coming soon at": "Coming soon at",
    "in": "in",
    "Geo-Location": "Geo-Location",
    "Place Name": "Place Name",
    "Directions": "Directions",
    "Registered users": "Registered users",
    "There is no registered users yet.": "There is no registered users yet.",
    "Attendees": "Attendees",
    "There are no attendees yet.": "There are no attendees yet.",
    "Cancel Event": "Cancel Event",
    "Join Event": "Join Event",
    "Joining": "Joining",
    "Cancelling": "Cancelling",
    "Danger message": "Danger message",
    "Warning message": "Warning message",
    "Successfully message": "Successfully message",
    "Missing slotId": "Missing slotId",
    "Missing userId": "Missing userId",
    "Slot not found.": "Slot not found.",
    "Slot occupied.": "Slot occupied.",
    "You joined the group!": "You joined the group!",
    "You cancelled the group.": "You cancelled the group.",
    "Something went wrong.": "Something went wrong.",
    "Are you sure you want to cancel this event?": "Are you sure you want to cancel this event?",
    "Missing slot.": "Missing slot.",
    "Missing location.": "Missing location.",
    "My Events": "My Events",
    "Leave Event": "Leave Event",
    "Leaving": "Leaving",
    "Image": "Image",
    "Menu": "Menu",
    "Your assignment has been created": "Your assignment has been created",
    "successfully": "successfully",
    "To view and manage your assignments click on button below": "To view and manage your assignments click on button below",
    "View my assignments": "View my assignments",
    "Description": "Description",
    "There is no description for this event.": "There is no description for this event.",
    "Redirecting error. Try again later.": "Redirecting error. Try again later.",
    "Join Beta": "Join Beta",
    "Join": "Join",
    "Application Version": "Application Version",
    "Beta": "Beta",
    "Cannot cancel as less than 2 hours left": "Det går inte att avbryta eftersom det är mindre än 2 timmar kvar"
  },
  "ua": {
    "Language": "Мова",
    "Phone Number": "Номер телефону",
    "+1...": "+380...",
    "Password": "Пароль",
    "Login": "Логін",
    "Name": "Ім'я",
    "January": "січня",
    "February": "лютого",
    "March": "березня",
    "April": "квітня",
    "May": "травня",
    "June": "червня",
    "July": "липня",
    "August": "серпня",
    "September": "вересня",
    "October": "жовтня",
    "November": "листопада",
    "December": "грудня",
    "Shift": "Зміна",
    "Date": "Дата",
    "Month": "місяць",
    "Day": "день",
    "Person": "особа ",
    "Driver": "Водій",
    "Logout": "Вийти",
    "Request": "Записатися",
    "Open": "Відкрити",
    "Add": "Додати",
    "Edit": "Редагувати",
    "User": "Користувач",
    "Users": "Користувачі",
    "Enter": "Ввести",
    "Admin": "Адмін",
    "Admin Panel": "Панель адміністратора",
    "Save": "Зберегти",
    "Make": "Зробити",
    "Make Admin": "Зробити адміністратором",
    "Monday": "понеділок",
    "Tuesday": "вівторок",
    "Wednesday": "середа",
    "Thursday": "четвер",
    "Friday": "п'ятниця",
    "Saturday": "субота",
    "Sunday": "неділя",
    "Something went wrong": "Щось пішло не так",
    "Home": "Додому",
    "Canceled": "Скасовано",
    "Cancel": "Скасувати",
    "Restore": "Відновити",
    "Slots": "Час",
    "Remove": "Прибрати",
    "Delete": "Видалити",
    "Delete User": "Видалити користувача",
    "Are you sure you want to delete the user?": "Ви впевнені, що хочете видалити користувача?",
    "Are you sure you want to cancel?": "Ви впевнені, що хочете скасувати?",
    "Number of people": "Кількість людей",
    "Remove Location": "Видалити місцезнаходження",
    "Shift cancel message": "Повідомлення про скасування зміни",
    "Assignments": "Призначення",
    "Choose Location": "Виберіть розташування",
    "Choose Group": "Виберіть групу",
    "Choose Language": "Виберіть мову",
    "Choose Shift": "Виберіть зміну",
    "Choose Images": "Виберіть зображення",
    "Choose Email Notification": "Виберіть \"Сповіщення електронною поштою\"",
    "Group": "Група",
    "Groups": "Групи",
    "Group Name": "Назва групи",
    "Are you sure you want to delete this group?": "Ви впевнені, що хочете видалити цю групу?",
    "Gender": "Стать",
    "Choose Gender": "Виберіть стать",
    "Today": "Сьогодні",
    "Next Day": "Наступний день",
    "Previous Day": "Попередній день",
    "Repeat": "Повторіть",
    "Choose Date & Slots": "Виберіть дату та час ",
    "Picture": "Фото",
    "Location Description": "Опис місцерозташування",
    "Email": "Електронна пошта",
    "Do you want to book this slot?\n(It will be possible to cancel the duty no later than 2 hours before it starts)": "Бажаєте забронювати цей час служіння?\n(чи можна не пізніше ніж за 2 години до початку вашої зміни)",
    "Create New Location": "Створити нове місцерозташування",
    "Edit Location": "Редагувати місцерозташування",
    "Positions": "Кількість людей",
    "Status": "Статус",
    "Timeframe": "Часовий проміжок",
    "Background Color": "Колір фону",
    "Start Time": "Час початку",
    "End Time": "Час завершення",
    "Back": "Назад",
    "Image removed": "Зображення видалено",
    "No slots booked": "Часи служіння не заброньовано",
    "created succesfully": "Створено успішно",
    "Email Notification": "Сповіщення електронною поштою",
    "Resend": "Надіслати повторно",
    "Send": "Надіслати",
    "Login with credentials": "Увійдіть з обліковими даними",
    "Automatic login": "Автоматичний вхід",
    "Unavailable": "Недоступно",
    "Expired": "Термін дії минув",
    "Start time cannot be before current time": "Час початку не може бути раніше поточного часу",
    "End time cannot be before start time": "Час завершення не може передувати часу початку",
    "Do you want to call this person?": "Ви хочете подзвонити цій людині?",
    "You": "ви",
    "Are you sure you want to cancel this slot?": "Ви впевнені, що бажаєте скасувати цей час служіння?",
    "Are you sure? \nThis action will delete the historical record for users previously at this location/ time/day on their ‘Assignments’ page.": "Ви впевнені? \nЦя дія видалить історію записів користувачів, які раніше були в цьому місці та в цей час і день, на їхній сторінці «Моє служіння».",
    "Are you sure? \nThis will permanently delete this slot and user data completely from the database. This action is irreversible.": "Ви впевнені? \nЦя дія назавжди видалить цей час служіння і дані користувача з бази даних. Ця дія незворотна.",
    "Show All": "Показати все",
    "Male": "чоловік",
    "Female": "жінка",
    "Activate": "Активувати",
    "Deactivate": "Деактивувати",
    "Next 7 Day": "Наступні 7 днів",
    "Previous 7 Day": "Попередні 7 днів",
    "Notifications": "Сповіщення",
    "This will open external link in new window": "Зовнішнє посилання відкриється в новому вікні",
    "The URL looks invalid": "URL-адреса виглядає недійсною",
    "We've sent you an email. Please click the link in the email to accept receiving notifications from i68.com.au.": "Ми надіслали вам електронний лист. Натисніть посилання в електронному листі, щоб отримувати сповіщення від i68.com.au.",
    "Assignment begins in 2 hours": "Початок служіння через 2 години",
    "Hello": "Привіт",
    "This is a reminder that your assignment": "Це нагадування про те, що ваше служіння ",
    "Time": "Час",
    "Location": "Місцезнаходження",
    "Will begin in one hour from now.": "Почнеться через годину.",
    "Regards": "З повагою",
    "Slot Booked": "Час служіння заброньовано",
    "Your ASSIGNMENT has been CREATED at": "ЗАПИС про ваше служіння СТВОРЕНО в",
    "My Assignments": "Моє служіння",
    "Slot Canceled": "Час служіння скасовано",
    "Your ASSIGNMENT has been CANCELLED at": "Ваше СЛУЖІННЯ СКАСОВАНО о",
    "Slot Deleted": "Час служіння видалено",
    "Your ASSIGNMENT has been DELETED at": "Ваше СЛУЖІННЯ було ВИДАЛЕНО о",
    "Slot Restored": "Час служіння відновлено",
    "Your ASSIGNMENT has been RESTORED at": "Ваше СЛУЖІННЯ було ВІДНОВЛЕНО о",
    "Account Created": "Обліковий запис створено",
    "Your account has been created in the i68 App. Please click the button below to login.": "Ваш обліковий запис створено в програмі i68. Натисніть кнопку нижче, щоб увійти.",
    "Your account has been created in the i68 App. You can use the following details to login.": "Ваш обліковий запис створено в програмі i68. Ви можете використовувати наступні дані для входу.",
    "You can login using the following button": "Ви можете увійти за допомогою цієї кнопки",
    "Please allow notifications from i68.com.au.": "Дозволити сповіщення від i68.com.au.",
    "Will begin in 2 hours from now.": "Розпочнеться через 2 години.",
    "will begin in 2 hours from now.": "розпочнеться через 2 години.",
    "will begin in 2 hours or less.": "розпочнеться через 2 години або менше.",
    "Your assignment on location": "Ваше служіння на",
    "Your assignment at location": "Ваше служіння у",
    "Select Groups": "Обрати групи",
    "Donate": "Пожертвувати",
    "Primary Group": "Основна група",
    "Search": "Пошук",
    "Support": "Підтримати",
    "My Account": "Мій обліковий запис",
    "Repeat Weekly": "Повторювати щотижня",
    "Create": "Створити",
    "Active": "Час достуний",
    "Disabled": "Час недоступний",
    "Minimum duration is 30 minutes": "Мінімальний час служіння",
    "Maximum duration is 12 hours": "Максимальний час служіння",
    "Change Your Timezone": "Змінити часовий пояс",
    "Account Settings": "Налаштування облікового запису",
    "Account": "Профіль",
    "Your Timezone": "Мій часовий пояс",
    "Are you sure you want to restore?": "Ви впевнені, що хочете відновити? ",
    "more": "ще",
    "expand": "розгорнути",
    "Details": "Подробиці",
    "Show Expired": "Показати Термін дії минув",
    "Full": "Повний",
    "Available": "доступний",
    "Change": "Зміна",
    "Update Location": "Оновити місцезнаходження",
    "Location Updated successfully": "Розташування успішно оновлено",
    "Move Up": "Pухатися вгору",
    "Move Down": "Pухатися вниз",
    "Order": "Чпорядковувати",
    "Confirm Request": "Підтвердити запит",
    "ASSIGNMENT STARTING SOON at": "Скоро ПОЧАТОК СЛУЖІННЯ у",
    "ASSIGNMENT CREATED at": "СЛУЖІННЯ ЗАПЛАНОВАНЕ у ",
    "ASSIGNMENT CANCELED at": "СЛУЖІННЯ СКАСОВАНЕ у",
    "ASSIGNMENT RESTORED at": "СЛУЖІННЯ ВІДНОВЛЕНЕ у",
    "To view or manage your assignments click here": "Для перегляду і редагування свого служіння натисни тут",
    "Your ASSIGNMENT is STARTING SOON at": "Твоє  СЛУЖІННЯ СКОРО РОЗПОЧНЕТЬСЯ у",
    "ASSIGNMENT BEGINS tomorrow at": "СЛУЖІННЯ РОЗПОЧНЕТЬСЯ завтра у",
    "Your ASSIGNMENT BEGINS tomorrow at": "Твоє СЛУЖІННЯ РОЗПОЧНЕТЬСЯ завтра у",
    "Position": "Позиція",
    "Download": "Загруджати",
    "Locations": "Locations",
    "My events": "My events",
    "Settings": "Settings",
    "List": "List",
    "Map": "Map",
    "Calendar": "Calendar",
    "Events in next week": "Events in next week",
    "Other events in": "Other events in",
    "Coming soon events": "Coming soon events",
    "more required": "more required",
    "Open in Google maps": "Open in Google maps",
    "Date range": "Date range",
    "Next week at": "Next week at",
    "Also at": "Also at",
    "Coming soon at": "Coming soon at",
    "in": "in",
    "Geo-Location": "Geo-Location",
    "Place Name": "Place Name",
    "Directions": "Directions",
    "Registered users": "Registered users",
    "There is no registered users yet.": "There is no registered users yet.",
    "Attendees": "Attendees",
    "There are no attendees yet.": "There are no attendees yet.",
    "Cancel Event": "Cancel Event",
    "Join Event": "Join Event",
    "Joining": "Joining",
    "Cancelling": "Cancelling",
    "Danger message": "Danger message",
    "Warning message": "Warning message",
    "Successfully message": "Successfully message",
    "Missing slotId": "Missing slotId",
    "Missing userId": "Missing userId",
    "Slot not found.": "Slot not found.",
    "Slot occupied.": "Slot occupied.",
    "You joined the group!": "You joined the group!",
    "You cancelled the group.": "You cancelled the group.",
    "Something went wrong.": "Something went wrong.",
    "Are you sure you want to cancel this event?": "Are you sure you want to cancel this event?",
    "Missing slot.": "Missing slot.",
    "Missing location.": "Missing location.",
    "My Events": "My Events",
    "Leave Event": "Leave Event",
    "Leaving": "Leaving",
    "Image": "Image",
    "Menu": "Menu",
    "Your assignment has been created": "Your assignment has been created",
    "successfully": "successfully",
    "To view and manage your assignments click on button below": "To view and manage your assignments click on button below",
    "View my assignments": "View my assignments",
    "Description": "Description",
    "There is no description for this event.": "There is no description for this event.",
    "Redirecting error. Try again later.": "Redirecting error. Try again later.",
    "Join Beta": "Join Beta",
    "Join": "Join",
    "Application Version": "Application Version",
    "Beta": "Beta",
    "Cannot cancel as less than 2 hours left": "Не можна скасувати менш ніж за 2 години"
  },
  "sr": {
    "Language": "Jezik",
    "Phone Number": "Broj telefona",
    "+1...": "+381...",
    "Password": "Šifra",
    "Login": "Prijavi se",
    "Name": "Ime",
    "January": "Januar",
    "February": "Februar",
    "March": "Mart",
    "April": "April",
    "May": "Maj",
    "June": "Jun",
    "July": "Jul",
    "August": "Avgust",
    "September": "Septembar",
    "October": "Oktobar",
    "November": "Novembar",
    "December": "Decembar",
    "Shift": "Smena",
    "Date": "Datum",
    "Month": "Mesec",
    "Day": "Dan",
    "Person": "Osoba",
    "Driver": "Vozač",
    "Logout": "Odjavi se",
    "Request": "Zahtev",
    "Open": "Otvori",
    "Add": "Dodaj",
    "Edit": "Izmeni",
    "User": "Korisnik",
    "Users": "Korisnici",
    "Enter": "Unesi",
    "Admin": "Admin",
    "Admin Panel": "Admin Panel",
    "Save": "Sačuvaj",
    "Make": "Napraviti",
    "Make Admin": "Napravi Administratora",
    "Monday": "Ponedeljak",
    "Tuesday": "Utorak",
    "Wednesday": "Sreda",
    "Thursday": "Četvrtak",
    "Friday": "Petak",
    "Saturday": "Subota",
    "Sunday": "Nedelja",
    "Something went wrong": "Greška",
    "Home": "Početna",
    "Canceled": "Otkazano",
    "Cancel": "Otkaži",
    "Restore": "Obnovi",
    "Slots": "Slotovi",
    "Remove": "Ukolni",
    "Delete": "Obriši",
    "Delete User": "Obriši Korisnika",
    "Are you sure you want to delete the user?": "Da li ste sigurni da želite da obrišete korisnika?",
    "Are you sure you want to cancel?": "Da li ste sigurni da želite da otkažete?",
    "Number of people": "Broj ljudi",
    "Remove Location": "Ukloni Lokaciju",
    "Shift cancel message": "Poruka otkazivanja smene",
    "Assignments": "Zadaci",
    "Choose Location": "Odaberi Lokaciju",
    "Choose Group": "Odaberi Grupu",
    "Choose Language": "Odaberi Jezik",
    "Choose Shift": "Odaberi Smenu",
    "Choose Images": "Odaberi Sliku",
    "Choose Email Notification": "Odaberi Email Obaveštenje",
    "Group": "Grupa",
    "Groups": "Grupe",
    "Group Name": "Naziv Grupe",
    "Are you sure you want to delete this group?": "Da li ste sigurni da želite da obrišete grupu?",
    "Gender": "Pol",
    "Choose Gender": "Odaberi Pol",
    "Today": "Danas",
    "Next Day": "Sutra",
    "Previous Day": "Juče",
    "Repeat": "Ponovi",
    "Choose Date & Slots": "Odaberi Datum i Slot",
    "Picture": "Slika",
    "Location Description": "Opis Lokacije",
    "Email": "Email",
    "Do you want to book this slot?\n(It will be possible to cancel the duty no later than 2 hours before it starts)": "Da li želite da rezervišete ovaj slot? \n(Možete otkazati najkasnije 2 sata pre početka)",
    "Create New Location": "Kreiraj Novu Lokaciju",
    "Edit Location": "Izmeni Lokaciju",
    "Positions": "Pozicija",
    "Status": "Status",
    "Timeframe": "Vremenski Okvir",
    "Background Color": "Boja Pozadine",
    "Start Time": "Vreme Početka",
    "End Time": "Vreme Završetka",
    "Back": "Nazad",
    "Image removed": "Slika je uklonjena",
    "No slots booked": "Nema zakazanih slotova",
    "created succesfully": "Uspešno kreirano",
    "Email Notification": "Email Obaveštenja",
    "Resend": "Pošalji ponovo",
    "Send": "Pošalji",
    "Login with credentials": "Prijavi se",
    "Automatic login": "Automatska Prijava",
    "Unavailable": "Nedostupno",
    "Expired": "Isteklo",
    "Start time cannot be before current time": "Vreme početka ne može biti pre trenutnog vremena",
    "End time cannot be before start time": "Vreme završetka ne može biti pre vremena početka",
    "Do you want to call this person?": "Da li želite da pozovete ovu osobu?",
    "You": "Vi",
    "Are you sure you want to cancel this slot?": "Da li ste sigurni da želite da otkažete ovaj slot?",
    "Are you sure? \nThis action will delete the historical record for users previously at this location/ time/day on their ‘Assignments’ page.": "Da li ste sigurni? \n Ova akcija će obrisati istoriju zapisa korisnika za ovu lokaciju/vreme/dan na njihovoj stranici ‘Zadaci’",
    "Are you sure? \nThis will permanently delete this slot and user data completely from the database. This action is irreversible.": "Da li ste sigurni? \nOvim trajno brišete slot i korisničke podatke iz baze. Ova akcija je nepovratna.",
    "Show All": "Prikaži Sve",
    "Male": "Muško",
    "Female": "Žensko",
    "Activate": "Aktiviraj",
    "Deactivate": "Deaktiviraj",
    "Next 7 Day": "Sledećih 7 dana",
    "Previous 7 Day": "Prethodnih 7 dana",
    "Notifications": "Obaveštenja",
    "This will open external link in new window": "Ova akcija će otvoriti link u novom prozoru",
    "The URL looks invalid": "URL nije validan",
    "We've sent you an email. Please click the link in the email to accept receiving notifications from i68.com.au.": "Email je poslat. Kako biste odobrili primanje obaveštenja od i68.com.au. potrebno je da kliknete na link u mailu.",
    "Assignment begins in 2 hours": "Zadatak počinje za 2 sata",
    "Hello": "Zdravo",
    "This is a reminder that your assignment": "Ovo je podsetnik za vaš zadatak",
    "Time": "Vreme",
    "Location": "Lokacija",
    "Will begin in one hour from now.": "Počinje za 1 sat",
    "Regards": "Pozdrav",
    "Slot Booked": "Slot je zakazan",
    "Your ASSIGNMENT has been CREATED at": "Vaš ZADATAK je DODELJEN u",
    "My Assignments": "Moji Zadaci",
    "Slot Canceled": "Slot je otkazan",
    "Your ASSIGNMENT has been CANCELLED at": "Tvoj ZADATAK je OTAKAZAN u",
    "Slot Deleted": "Slot je obrisan",
    "Your ASSIGNMENT has been DELETED at": "Tvoj ZADATAK je OBRISAN u",
    "Slot Restored": "Slot je obnovljen",
    "Your ASSIGNMENT has been RESTORED at": "Tvoj ZADATAK je OBNOVLJEN u",
    "Account Created": "Nalog je kreiran",
    "Your account has been created in the i68 App. Please click the button below to login.": "Vaš nalog na i68 je kreiran. Klikni dugme ispod za prijavu.",
    "Your account has been created in the i68 App. You can use the following details to login.": "Vaš nalog na i68 je kreiran. Klikni dugme ispod za prijavu.",
    "You can login using the following button": "Prijavi putem sledećeg dugmeta",
    "Please allow notifications from i68.com.au.": "Želim da dobijam notifikacije od i68.com.au.",
    "Will begin in 2 hours from now.": "Počinje za 2 sata.",
    "will begin in 2 hours from now.": "Počinje za 2 sata.",
    "will begin in 2 hours or less.": "Počinje za manje od 2 sata.",
    "Your assignment on location": "Tvoji zadaci na lokaciji",
    "Your assignment at location": "Tvoji zadaci na lokaciji",
    "Select Groups": "Odaberi Grupe",
    "Donate": "Doniraj",
    "Primary Group": "Primarna Grupa",
    "Search": "Pretraži",
    "Support": "Pomoć",
    "My Account": "Moj Nalog",
    "Repeat Weekly": "Ponavljaj Nedeljno",
    "Create": "Kreiraj",
    "Active": "Aktiviraj",
    "Disabled": "Onemogućeno",
    "Minimum duration is 30 minutes": "Minimalna dužina trajanja je 30 minuta",
    "Maximum duration is 12 hours": "Maksimalna dužina trajanja je 12 sati",
    "Change Your Timezone": "Promeni Vremensku Zonu",
    "Account Settings": "Podaci Naloga",
    "Account": "Nalog",
    "Your Timezone": "Vaša Vremenska Zona",
    "Are you sure you want to restore?": "Da li ste sigurni da želite da obnoviti ovaj slot?",
    "more": "više",
    "expand": "proširi",
    "Details": "Detalji",
    "Show Expired": "Prikaži Istekle",
    "Full": "Puno",
    "Available": "Dostupno",
    "Change": "Promeni",
    "Update Location": "Sačuvaj Lokaciju",
    "Location Updated successfully": "Lokacija je uspešno promenjena",
    "Move Up": "Pomeri Iznad",
    "Move Down": "Pomeri Dole",
    "Order": "Redni Broj",
    "Confirm Request": "Potvrdi Zahtev",
    "ASSIGNMENT STARTING SOON at": "ZADATAK POČINJE USKORO u",
    "ASSIGNMENT CREATED at": "ZADATAK DODELJEN u",
    "ASSIGNMENT CANCELED at": "ZADATAK OTKAZAN u",
    "ASSIGNMENT RESTORED at": "ZADATAK OBNOVLJEN u",
    "To view or manage your assignments click here": "Da bi ste videli vaše zadatke kliknite ovde",
    "Your ASSIGNMENT is STARTING SOON at": "Vaš ZADATAK POČINJE USKORO u",
    "ASSIGNMENT BEGINS tomorrow at": "ZADATAK POČINJE sutra u",
    "Your ASSIGNMENT BEGINS tomorrow at": "Vaš ZADATAK POČINJE sutra u",
    "Position": "Pozicija",
    "Download": "Preuzmi",
    "Locations": "Locations",
    "My events": "My events",
    "Settings": "Settings",
    "List": "List",
    "Map": "Map",
    "Calendar": "Calendar",
    "Events in next week": "Events in next week",
    "Other events in": "Other events in",
    "Coming soon events": "Coming soon events",
    "more required": "more required",
    "Open in Google maps": "Open in Google maps",
    "Date range": "Date range",
    "Next week at": "Next week at",
    "Also at": "Also at",
    "Coming soon at": "Coming soon at",
    "in": "in",
    "Geo-Location": "Geo-Location",
    "Place Name": "Place Name",
    "Directions": "Directions",
    "Registered users": "Registered users",
    "There is no registered users yet.": "There is no registered users yet.",
    "Attendees": "Attendees",
    "There are no attendees yet.": "There are no attendees yet.",
    "Cancel Event": "Cancel Event",
    "Join Event": "Join Event",
    "Joining": "Joining",
    "Cancelling": "Cancelling",
    "Danger message": "Danger message",
    "Warning message": "Warning message",
    "Successfully message": "Successfully message",
    "Missing slotId": "Missing slotId",
    "Missing userId": "Missing userId",
    "Slot not found.": "Slot not found.",
    "Slot occupied.": "Slot occupied.",
    "You joined the group!": "You joined the group!",
    "You cancelled the group.": "You cancelled the group.",
    "Something went wrong.": "Something went wrong.",
    "Are you sure you want to cancel this event?": "Are you sure you want to cancel this event?",
    "Missing slot.": "Missing slot.",
    "Missing location.": "Missing location.",
    "My Events": "My Events",
    "Leave Event": "Leave Event",
    "Leaving": "Leaving",
    "Image": "Image",
    "Menu": "Menu",
    "Your assignment has been created": "Your assignment has been created",
    "successfully": "successfully",
    "To view and manage your assignments click on button below": "To view and manage your assignments click on button below",
    "View my assignments": "View my assignments",
    "Upcoming": "Upcoming",
    "Gallery": "Gallery",
    "Upcoming events": "Upcoming events",
    "Upcoming at": "Upcoming at",
    "Join": "Join",
    "Application Version": "Application Version",
    "Beta": "Beta",
    "Cannot cancel as less than 2 hours left": "Cannot cancel as less than 2 hours left"
  },
  "ru": {
    "Language": "Язык",
    "Phone Number": "Номер телефона",
    "+1...": "+7...",
    "Password": "Пароль",
    "Login": "Логин",
    "Name": "Имя",
    "January": "января",
    "February": "февраля",
    "March": "марта",
    "April": "апреля",
    "May": "мая",
    "June": "июня",
    "July": "июля",
    "August": "августа",
    "September": "сентября",
    "October": "октября",
    "November": "ноября",
    "December": "декабря",
    "Shift": "Смена",
    "Date": "Дата",
    "Month": "месяц",
    "Day": "день",
    "Person": "человек",
    "Driver": "Водитель",
    "Logout": "Выйти",
    "Request": "Сделать запрос",
    "Open": "Открыть",
    "Add": "Добавить",
    "Edit": "Редактировать",
    "User": "Пользователь",
    "Users": "Пользователи",
    "Enter": "Ввести",
    "Admin": "Админ",
    "Admin Panel": "Панель администратора",
    "Save": "Сохранить",
    "Make": "Сделать",
    "Make Admin": "Сделать администратором",
    "Monday": "понедельник",
    "Tuesday": "вторник",
    "Wednesday": "среда",
    "Thursday": "четверг",
    "Friday": "пятница",
    "Saturday": "суббота",
    "Sunday": "воскресенье",
    "Something went wrong": "Что-то пошло не так",
    "Home": "Главная",
    "Canceled": "Отменено",
    "Cancel": "Отменить",
    "Restore": "Восстановить",
    "Slots": "Время",
    "Remove": "Убрать",
    "Delete": "Удалить",
    "Delete User": "Удалить пользователя",
    "Are you sure you want to delete the user?": "Вы уверены, что хотите удалить поьзователя?",
    "Are you sure you want to cancel?": "Вы уверены, что хотите отменить?",
    "Number of people": "Количество людей",
    "Remove Location": "Удалить месторасположение",
    "Shift cancel message": "Сообщение об отмене смены",
    "Assignments": "Назначения",
    "Choose Location": "Выберите месторасположение",
    "Choose Group": "Выберите группу",
    "Choose Language": "Выберите язык",
    "Choose Shift": "Выберите смену",
    "Choose Images": "Выберите изображение",
    "Choose Email Notification": "Выберите уведомление эл. почтой",
    "Group": "Группа",
    "Groups": "Группы",
    "Group Name": "Название группы",
    "Are you sure you want to delete this group?": "Вы уверенны, что хотите удалить эту группу?",
    "Gender": "Пол",
    "Choose Gender": "Выберите пол",
    "Today": "Сегодня",
    "Next Day": "Следующий день",
    "Previous Day": "Предыдущий день",
    "Repeat": "Повторите",
    "Choose Date & Slots": "Виберите дату и время",
    "Picture": "Фото",
    "Location Description": "Описание месторасположения",
    "Email": "Электронная почта",
    "Do you want to book this slot?\n(It will be possible to cancel the duty no later than 2 hours before it starts)": "Желаете забронировать это время служения?\n(Отменить служение можна будет не позже чем за 2 часа до его начала)",
    "Create New Location": "Создать новое месторасположение",
    "Edit Location": "Редактировать месторасположение",
    "Positions": "Количество людей",
    "Status": "Статус",
    "Timeframe": "Промежуток времени",
    "Background Color": "Цвет фона",
    "Start Time": "Время начала",
    "End Time": "Время конца",
    "Back": "Назад",
    "Image removed": "Изображение удалено",
    "No slots booked": "Время служения не забронировано",
    "created succesfully": "Создано успешно",
    "Email Notification": "Уведомление по эл. почте",
    "Resend": "Отправить повторно",
    "Send": "Отправить",
    "Login with credentials": "Ввойдите через учетную запись",
    "Automatic login": "Автоматический вход",
    "Unavailable": "Недоступно",
    "Expired": "Срок вышел",
    "Start time cannot be before current time": "Время начала не может быть раньше текущего времени",
    "End time cannot be before start time": "Время конца не может быть раньше начала",
    "Do you want to call this person?": "Вы хотите позвонить этому человеку?",
    "You": "Вы",
    "Are you sure you want to cancel this slot?": "Вы уверены, что хотите отменить это время служения?",
    "Are you sure? \nThis action will delete the historical record for users previously at this location/ time/day on their ‘Assignments’ page.": "Вы уверены? \nЭто действие удалит историю записей пользователей, которые раньше были в этом месте и в этот день и время, с их страницы \"Моё служение\"",
    "Are you sure? \nThis will permanently delete this slot and user data completely from the database. This action is irreversible.": "Вы уверены? \nЭто действие навсегда удалит это время служения и данные пользователя из базы данных. Это дейсвие необратимо.",
    "Show All": "Показать всё",
    "Male": "мужчина",
    "Female": "женщина",
    "Activate": "Активировать",
    "Deactivate": "Деактивировать",
    "Next 7 Day": "Следующие 7 дней",
    "Previous 7 Day": "Предыдущие 7 дней",
    "Notifications": "Уведомления",
    "This will open external link in new window": "Внешняя ссылка откроется в новом окне",
    "The URL looks invalid": "URL-адрес выглядит недействительным",
    "We've sent you an email. Please click the link in the email to accept receiving notifications from i68.com.au.": "Мы отправили вам эл. письмо. Нажмите на ссылку в эл. письме, чтоб получать уведомления от i68.com.au.",
    "Assignment begins in 2 hours": "Начало служения через 2 часа",
    "Hello": "Привет",
    "This is a reminder that your assignment": "Это напоминание о том, что твоё служение",
    "Time": "Время",
    "Location": "Месторасположение",
    "Will begin in one hour from now.": "Начнется через час.",
    "Regards": "С уважением",
    "Slot Booked": "Время служения забронировано",
    "Your ASSIGNMENT has been CREATED at": "ЗАПИСЬ о вашем служении СОЗДАНА в",
    "My Assignments": "Служение",
    "Slot Canceled": "Время служения отменено",
    "Your ASSIGNMENT has been CANCELLED at": "Ваше СЛУЖЕНИЕ ОТМЕНЕНО в",
    "Slot Deleted": "Время служения удалено",
    "Your ASSIGNMENT has been DELETED at": "Ваше СЛУЖЕНИЕ было УДАЛЕНО в",
    "Slot Restored": "Время служения восстановлено",
    "Your ASSIGNMENT has been RESTORED at": "Ваше СЛУЖЕНИЕ ВОССТАНОВЛЕНО в",
    "Account Created": "Учётная запись создана",
    "Your account has been created in the i68 App. Please click the button below to login.": "Ваша учётная запись создана в программе i68. Нажмите на кнопку ниже, чтоб ввойти.",
    "Your account has been created in the i68 App. You can use the following details to login.": "Ваша учётная запись создана в программе i68. Вы можете использовать следующие данные для входа.",
    "You can login using the following button": "Вы можете ввойти с помощью этой кнопки.",
    "Please allow notifications from i68.com.au.": "Разрешить уведомления от i68.com.au.",
    "Will begin in 2 hours from now.": "Начнётся через 2 часа.",
    "will begin in 2 hours from now.": "начнётся через 2 часа.",
    "will begin in 2 hours or less.": "начнётся через 2 часа или раньше.",
    "Your assignment on location": "Ваше служение на",
    "Your assignment at location": "Ваше служение в",
    "Select Groups": "Выбрать группы",
    "Donate": "Пожертвовать",
    "Primary Group": "Основная группа",
    "Search": "Поиск",
    "Support": "Поддержать",
    "My Account": "Профиль",
    "Repeat Weekly": "Повторять каждую неделю",
    "Create": "Создать",
    "Active": "Время доступно",
    "Disabled": "Время недоступно",
    "Minimum duration is 30 minutes": "Минимальное время служения",
    "Maximum duration is 12 hours": "Максимальное время служения",
    "Change Your Timezone": "Сменить часовой пояс",
    "Account Settings": "Настройки учётной записи",
    "Account": "Профиль",
    "Your Timezone": "Мой часовой пояс",
    "Are you sure you want to restore?": "Вы уверены, что хотите возобновить?",
    "more": "ещё",
    "expand": "развернуть",
    "Details": "Подробнее",
    "Show Expired": "Показать истёкший срок",
    "Full": "Полный",
    "Available": "доступный",
    "Change": "Смена",
    "Update Location": "Обновить месторасположение",
    "Location Updated successfully": "Месторасположение успешно обновлено",
    "Move Up": "придвигаться",
    "Move Down": "опускать",
    "Order": "Упорядочить",
    "Confirm Request": "Подтвердить запрос",
    "ASSIGNMENT STARTING SOON at": "Скоро НАЧНЕТСЯ СЛУЖЕНИЕ в",
    "ASSIGNMENT CREATED at": "СЛУЖЕНИЕ ЗАПЛАНИРОВАНО в",
    "ASSIGNMENT CANCELED at": "СЛУЖЕНИЕ ОТМЕНЕНО в",
    "ASSIGNMENT RESTORED at": "СЛУЖЕНИЕ ВОЗОБНОВЛЕНО в",
    "To view or manage your assignments click here": "Чтоб просмотреть или внести изменения в своем служении нажми здесь",
    "Your ASSIGNMENT is STARTING SOON at": "Твоё СЛУЖЕНИЕ НАЧНЁТСЯ СКОРО в ",
    "ASSIGNMENT BEGINS tomorrow at": "СЛУЖЕНИЕ НАЧНЁТСЯ завтра в ",
    "Your ASSIGNMENT BEGINS tomorrow at": "Твоё СЛУЖЕНИЕ НАЧНЁТСЯ завтра в ",
    "Position": "Позиция",
    "Download": "Скачать",
    "Locations": "Locations",
    "My events": "My events",
    "Settings": "Settings",
    "List": "List",
    "Map": "Map",
    "Calendar": "Calendar",
    "Events in next week": "Events in next week",
    "Other events in": "Other events in",
    "Coming soon events": "Coming soon events",
    "more required": "more required",
    "Open in Google maps": "Open in Google maps",
    "Date range": "Date range",
    "Next week at": "Next week at",
    "Also at": "Also at",
    "Coming soon at": "Coming soon at",
    "in": "in",
    "Geo-Location": "Geo-Location",
    "Place Name": "Place Name",
    "Directions": "Directions",
    "Registered users": "Registered users",
    "There is no registered users yet.": "There is no registered users yet.",
    "Attendees": "Attendees",
    "There are no attendees yet.": "There are no attendees yet.",
    "Cancel Event": "Cancel Event",
    "Join Event": "Join Event",
    "Joining": "Joining",
    "Cancelling": "Cancelling",
    "Danger message": "Danger message",
    "Warning message": "Warning message",
    "Successfully message": "Successfully message",
    "Missing slotId": "Missing slotId",
    "Missing userId": "Missing userId",
    "Slot not found.": "Slot not found.",
    "Slot occupied.": "Slot occupied.",
    "You joined the group!": "You joined the group!",
    "You cancelled the group.": "You cancelled the group.",
    "Something went wrong.": "Something went wrong.",
    "Are you sure you want to cancel this event?": "Are you sure you want to cancel this event?",
    "Missing slot.": "Missing slot.",
    "Missing location.": "Missing location.",
    "My Events": "My Events",
    "Leave Event": "Leave Event",
    "Leaving": "Leaving",
    "Image": "Image",
    "Menu": "Menu",
    "Your assignment has been created": "Your assignment has been created",
    "successfully": "successfully",
    "To view and manage your assignments click on button below": "To view and manage your assignments click on button below",
    "View my assignments": "View my assignments",
    "Description": "Description",
    "There is no description for this event.": "There is no description for this event.",
    "Redirecting error. Try again later.": "Redirecting error. Try again later.",
    "Join Beta": "Join Beta",
    "Join": "Join",
    "Application Version": "Application Version",
    "Beta": "Beta",
    "Cannot cancel as less than 2 hours left": "Нельзя отменить менее чем за 2 часа"
  },
  "es": {
    "Language": "Lenguaje",
    "Phone Number": "Numero telefonico ",
    "+1...": "+34...",
    "Password": "Contraseña ",
    "Login": "Acceder",
    "Name": "Nombre",
    "January": "Enero ",
    "February": "Febrero ",
    "March": "Marzo ",
    "April": "Abril ",
    "May": "Mayo ",
    "June": "Junio",
    "July": "Julio",
    "August": "Agosto",
    "September": "Septiembre",
    "October": "Octubre",
    "November": "Noviembre",
    "December": "Diciembre",
    "Shift": "Turno",
    "Date": "Fecha",
    "Month": "Mes",
    "Day": "Dia ",
    "Person": "Persona ",
    "Driver": "Conductor",
    "Logout": "Salir",
    "Request": "Solicitud",
    "Open": "Abrir",
    "Add": "Anadir",
    "Edit": "Editar",
    "User": "Usuario",
    "Users": "Usuarios",
    "Enter": "Ingresar",
    "Admin": "Administrador ",
    "Admin Panel": "Panel de control",
    "Save": "Guardar",
    "Make": "Solicitar ",
    "Make Admin": "Administrador de solicitud",
    "Monday": "Lunes ",
    "Tuesday": "Martes",
    "Wednesday": "Miercoles",
    "Thursday": "Jueves",
    "Friday": "Viernes",
    "Saturday": "Sabado",
    "Sunday": "Domingo",
    "Something went wrong": "Algo salio mal",
    "Home": "Principal ",
    "Canceled": "Cancelado",
    "Cancel": "Cancelar",
    "Restore": "Restablecer",
    "Slots": "Espacios",
    "Remove": "Remover",
    "Delete": "Eliminar",
    "Delete User": "Eliminar usuario",
    "Are you sure you want to delete the user?": "Seguro que quiere eliminar el usuario?",
    "Are you sure you want to cancel?": "Seguro que quiere cancelar?",
    "Number of people": "Numero de personas ",
    "Remove Location": "Remover ubicacion",
    "Shift cancel message": "Mensaje de cancelacion de turno ",
    "Assignments": "Asignacion ",
    "Choose Location": "Seleccione ubicacion ",
    "Choose Group": "Seleccione grupo",
    "Choose Language": "Seleccione lenguaje",
    "Choose Shift": "Seleccione turno",
    "Choose Images": "Seleccione imagenes",
    "Choose Email Notification": "Seleccione notificacion de correo ",
    "Group": "Grupo",
    "Groups": "Grupos",
    "Group Name": "Nombre del grupo",
    "Are you sure you want to delete this group?": "Seguro que quiere eliminar el grupo?",
    "Gender": "Genero",
    "Choose Gender": "Seleccione genero",
    "Today": "Hoy",
    "Next Day": "Proximo dia",
    "Previous Day": "Dia anterior",
    "Repeat": "Repetir",
    "Choose Date & Slots": "Seleccione fecha & espacios",
    "Picture": "Imagen",
    "Location Description": "Descripcion de la ubicacion ",
    "Email": "Correo electronico ",
    "Do you want to book this slot?\n(It will be possible to cancel the duty no later than 2 hours before it starts)": "Desea reservar este espacio? \n(Es posible cancelarlo hasta 2 horas antes de la reservacion)",
    "Create New Location": "Crear una nueva ubicacion ",
    "Edit Location": "Editar ubicacion ",
    "Positions": "Posiciones ",
    "Status": "Estado",
    "Timeframe": "Periodo de tiempo",
    "Background Color": "Color del fondo",
    "Start Time": "Hora de comienzo",
    "End Time": "Hora de finalizacion ",
    "Back": "Regresar",
    "Image removed": "Imagen removida ",
    "No slots booked": "Espacios disponibles ",
    "created succesfully": "Creado exitosamente ",
    "Email Notification": "Notificacion de correo",
    "Resend": "Reenviar ",
    "Send": "Enviar",
    "Login with credentials": "Ingrese con credenciales ",
    "Automatic login": "Ingreso automatico ",
    "Unavailable": "No disponible",
    "Expired": "Expirado ",
    "Start time cannot be before current time": "Hora de comienzo no puede ser antes que la hora actual",
    "End time cannot be before start time": "Hora de finalizacion no puede ser antes de la hora de comienzo",
    "Do you want to call this person?": "Desea llamar a esta persona?",
    "You": "Usted",
    "Are you sure you want to cancel this slot?": "Seguro que quiere cancelar este espacio?",
    "Are you sure? \nThis action will delete the historical record for users previously at this location/ time/day on their ‘Assignments’ page.": "Esta seguro? \nEsta accion eliminara el historial de los usuarios previos en esta ubicacion/hora/dia en su pagina de asignaciones ",
    "Are you sure? \nThis will permanently delete this slot and user data completely from the database. This action is irreversible.": "Esta seguro? \nEsta accion eliminara de forma permanente este espacio y los datos de usuario de la base de datos. Esta accion es irreversible",
    "Show All": "Mostrar todo ",
    "Male": "Masculino",
    "Female": "Femenino",
    "Activate": "Activar ",
    "Deactivate": "Desactivar ",
    "Next 7 Day": "Proximos 7 dias ",
    "Previous 7 Day": "Anteriores 7 dias",
    "Notifications": "Notificaciones ",
    "This will open external link in new window": "Esto abrira el enlace externo en una nueva ventana ",
    "The URL looks invalid": "El URL parece invalido ",
    "We've sent you an email. Please click the link in the email to accept receiving notifications from i68.com.au.": "Le hemos enviado un correo. Por favor, ingrese al enlace para aceptar la recepcion de notificaciones de i68.com.au.",
    "Assignment begins in 2 hours": "Asignacion empieza en 2 horas ",
    "Hello": "Hola ",
    "This is a reminder that your assignment": "Recordatorio de su asignacion",
    "Time": "Hora",
    "Location": "Ubicacion ",
    "Will begin in one hour from now.": "Empezara dentro de una hora ",
    "Regards": "Saludos cordiales ",
    "Slot Booked": "Espacio reservado ",
    "Your ASSIGNMENT has been CREATED at": "Su ASIGNACION ha sido CREADA en",
    "My Assignments": "Mis asignaciones",
    "Slot Canceled": "Espacio cancelado",
    "Your ASSIGNMENT has been CANCELLED at": "Su ASIGNACION ha sido CANCELADA en ",
    "Slot Deleted": "Espacio eliminado ",
    "Your ASSIGNMENT has been DELETED at": "Su ASIGNACION ha sido ELIMINADA en",
    "Slot Restored": "Espacio restablecido ",
    "Your ASSIGNMENT has been RESTORED at": "Su ASIGNACION ha sido RESTABLECIDA en",
    "Account Created": "Cuenca creada",
    "Your account has been created in the i68 App. Please click the button below to login.": "Su cuenta ha sido creada en i68. Por favor, presione el boton de abajo para ingresar ",
    "Your account has been created in the i68 App. You can use the following details to login.": "Su cuenta ha sido creada en i68. Use los siguientes datos para acceder ",
    "You can login using the following button": "Puede ingresar en el siguiente boton ",
    "Please allow notifications from i68.com.au.": "Por favor, permitir a i68.com.au. enviarle notificaciones ",
    "Will begin in 2 hours from now.": "Empezara dentro de 2 horas",
    "will begin in 2 hours from now.": "Empezara dentro de 2 horas",
    "will begin in 2 hours or less.": "Empezara en menos de 2 horas ",
    "Your assignment on location": "Su asignacion en la ubicacion ",
    "Your assignment at location": "Su asignacion en la ubicacion ",
    "Select Groups": "Seleccione grupos",
    "Donate": "Donacion",
    "Primary Group": "Grupo primario",
    "Search": "Buscar ",
    "Support": "Soporte",
    "My Account": "Mi cuenta",
    "Repeat Weekly": "Repetir semanalmente ",
    "Create": "Crear",
    "Active": "Activar",
    "Disabled": "Inhabilitado ",
    "Minimum duration is 30 minutes": "Duracion minima de 30 minutos",
    "Maximum duration is 12 hours": "Duracion maxima de 12 horas ",
    "Change Your Timezone": "Cambiar zona horaria ",
    "Account Settings": "Configuraciones ",
    "Account": "Cuenta",
    "Your Timezone": "Su zona horaria ",
    "Are you sure you want to restore?": "Seguro que desea restablecer?",
    "more": "Mas",
    "expand": "Expandir",
    "Details": "Detalles",
    "Show Expired": "Mostrar expirado",
    "Full": "Lleno",
    "Available": "Disponible ",
    "Change": "Cambiar",
    "Update Location": "Actualizar ubicacion ",
    "Location Updated successfully": "Ubicacion actualizada exitosamente",
    "Move Up": "Mover arriba",
    "Move Down": "Mover abajo",
    "Order": "Orden ",
    "Confirm Request": "Confirme solicitud ",
    "ASSIGNMENT STARTING SOON at": "ASIGNACION EMPEZARA PRONTO",
    "ASSIGNMENT CREATED at": "ASIGNACION CREADA",
    "ASSIGNMENT CANCELED at": "ASIGNACION CANCELADA",
    "ASSIGNMENT RESTORED at": "ASIGNACION RESTABLECIDA",
    "To view or manage your assignments click here": "Para ver o cambiar sus asignaciones ingrese aqui ",
    "Your ASSIGNMENT is STARTING SOON at": "Su ASIGNACION EMPIEZA PRONTO",
    "ASSIGNMENT BEGINS tomorrow at": "ASIGNACION EMPIEZA manana",
    "Your ASSIGNMENT BEGINS tomorrow at": "Su ASIGNACION EMPIEZA manana",
    "Position": "Posición",
    "Download": "Descargar",
    "Locations": "Locations",
    "My events": "My events",
    "Settings": "Settings",
    "List": "List",
    "Map": "Map",
    "Calendar": "Calendar",
    "Events in next week": "Events in next week",
    "Other events in": "Other events in",
    "Coming soon events": "Coming soon events",
    "more required": "more required",
    "Open in Google maps": "Open in Google maps",
    "Date range": "Date range",
    "Next week at": "Next week at",
    "Also at": "Also at",
    "Coming soon at": "Coming soon at",
    "in": "in",
    "Geo-Location": "Geo-Location",
    "Place Name": "Place Name",
    "Directions": "Directions",
    "Registered users": "Registered users",
    "There is no registered users yet.": "There is no registered users yet.",
    "Attendees": "Attendees",
    "There are no attendees yet.": "There are no attendees yet.",
    "Cancel Event": "Cancel Event",
    "Join Event": "Join Event",
    "Joining": "Joining",
    "Cancelling": "Cancelling",
    "Danger message": "Danger message",
    "Warning message": "Warning message",
    "Successfully message": "Successfully message",
    "Missing slotId": "Missing slotId",
    "Missing userId": "Missing userId",
    "Slot not found.": "Slot not found.",
    "Slot occupied.": "Slot occupied.",
    "You joined the group!": "You joined the group!",
    "You cancelled the group.": "You cancelled the group.",
    "Something went wrong.": "Something went wrong.",
    "Are you sure you want to cancel this event?": "Are you sure you want to cancel this event?",
    "Missing slot.": "Missing slot.",
    "Missing location.": "Missing location.",
    "My Events": "My Events",
    "Leave Event": "Leave Event",
    "Leaving": "Leaving",
    "Image": "Image",
    "Menu": "Menu",
    "Your assignment has been created": "Your assignment has been created",
    "successfully": "successfully",
    "To view and manage your assignments click on button below": "To view and manage your assignments click on button below",
    "View my assignments": "View my assignments",
    "Description": "Description",
    "There is no description for this event.": "There is no description for this event.",
    "Redirecting error. Try again later.": "Redirecting error. Try again later.",
    "Join Beta": "Join Beta",
    "Join": "Join",
    "Application Version": "Application Version",
    "Beta": "Beta",
    "Cannot cancel as less than 2 hours left": "No se puede cancelar porque quedan menos de 2 horas"
  },
  "de": {
    "Language": "Sprache",
    "Phone Number": "Telefon Nummer ",
    "+1...": "+49...",
    "Password": "Kennwort ",
    "Login": "Login/ Anmeldung ",
    "Name": "Name",
    "January": "Januar",
    "February": "Februar",
    "March": "März ",
    "April": "April",
    "May": "Mai ",
    "June": "Juni",
    "July": "Juli",
    "August": "August",
    "September": "September",
    "October": "Oktober",
    "November": "November ",
    "December": "Dezember ",
    "Shift": "Schicht",
    "Date": "Datum",
    "Month": "Monat",
    "Day": "Tag",
    "Person": "Person",
    "Driver": "Fahrer",
    "Logout": "sich ausloggen",
    "Request": "Anfrage",
    "Open": "Öffnen ",
    "Add": "Hinzufügen ",
    "Edit": "Aendern/ Bearbeiten",
    "User": "Nutzer ",
    "Users": "Nutzer",
    "Enter": "eingeben/ eintragen ?",
    "Admin": "Admin",
    "Admin Panel": "Admin- Panel",
    "Save": "speichern",
    "Make": "machen ",
    "Make Admin": "Admin machen",
    "Monday": "Montag ",
    "Tuesday": "Dienstag",
    "Wednesday": "Mittwoch ",
    "Thursday": "Donnerstag ",
    "Friday": "Freitag",
    "Saturday": "Samstag ",
    "Sunday": "Sonntag",
    "Something went wrong": "etwas lief schief",
    "Home": "Startseite",
    "Canceled": "abgesagt",
    "Cancel": "absagen",
    "Restore": "wiederherstellen ",
    "Slots": "Slots",
    "Remove": "entfernen ",
    "Delete": "löschen",
    "Delete User": "Benutzer löschen ",
    "Are you sure you want to delete the user?": "Sind Sie sicher,  dass Sie den Benutzer löschen wollen?",
    "Are you sure you want to cancel?": "Sind Sie sicher, dass Sie absagen wollen?",
    "Number of people": "Anzahl der Personen ",
    "Remove Location": "Standort entfernen ",
    "Shift cancel message": "Schicht Nachricht absagen",
    "Assignments": "Aufgaben ",
    "Choose Location": "Standort wählen ",
    "Choose Group": "Gruppe wählen ",
    "Choose Language": "Sprache wählen ",
    "Choose Shift": "Schicht wählen",
    "Choose Images": "Fotos wählen ",
    "Choose Email Notification": "E-Mail Benachrichtigungen auswählen ",
    "Group": "Gruppe",
    "Groups": "Gruppen",
    "Group Name": "Gruppenname",
    "Are you sure you want to delete this group?": "Sind Sie sicher, dass Sie diese Gruppe löschen wollen?",
    "Gender": "Geschlecht ",
    "Choose Gender": "Geschlecht wählen ",
    "Today": "Heute",
    "Next Day": "nächster Tag",
    "Previous Day": "vorheriger Tag",
    "Repeat": "wiederholen ",
    "Choose Date & Slots": "Datum & Slots auswählen",
    "Picture": "Foto",
    "Location Description": "Standort Beschreibung ",
    "Email": "E-Mail",
    "Do you want to book this slot?\n(It will be possible to cancel the duty no later than 2 hours before it starts)": "Möchten Sie diesen Termin / Slot buchen? <br> (Es ist möglich, den Schicht bis spätestens 2 Stunden vor Beginn abzusagen)",
    "Create New Location": "Neuen Standort erstellen ",
    "Edit Location": "Standort ändern ",
    "Positions": "Positionen / Stellen",
    "Status": "Status",
    "Timeframe": "Zeitrahmen ",
    "Background Color": "Hintergrund Farbe",
    "Start Time": "Startzeit ",
    "End Time": "Endzeit ",
    "Back": "zurück ",
    "Image removed": "Foto entfernt",
    "No slots booked": "Keine Slots gebucht ",
    "created succesfully": "erfolgreich erstellt ",
    "Email Notification": "E-Mail Benachrichtigungen ",
    "Resend": "erneut senden",
    "Send": "senden",
    "Login with credentials": "login mit Anmeldedaten",
    "Automatic login": "automatisches Einloggen ",
    "Unavailable": "nicht verfügbar ",
    "Expired": "Abgelaufen ",
    "Start time cannot be before current time": "Die Startzeit kann nicht vor der aktuellen Zeit liegen",
    "End time cannot be before start time": "Die Endzeit kann nicht vor der Startzeit liegen",
    "Do you want to call this person?": "Möchten Sie diese Person anrufen?",
    "You": "Sie ",
    "Are you sure you want to cancel this slot?": "Sind Sie sicher, dass Sie diesen Termin stornieren möchten?",
    "Are you sure? \nThis action will delete the historical record for users previously at this location/ time/day on their ‘Assignments’ page.": "Sind Sie sicher ? ",
    "Are you sure? \nThis will permanently delete this slot and user data completely from the database. This action is irreversible.": "Sind Sie sicher? Dadurch werden dieser Slot und die Benutzerdaten vollständig aus der Datenbank gelöscht. Dieser Vorgang ist unumkehrbar.",
    "Show All": "ale anzeigen",
    "Male": "männlich ",
    "Female": "weiblich",
    "Activate": "Aktivieren",
    "Deactivate": "Deaktivieren ",
    "Next 7 Day": "Nächste 7 Tage",
    "Previous 7 Day": "Vorherige 7 Tage",
    "Notifications": "Benachrichtigungen",
    "This will open external link in new window": "Es wird externer Link im neuen Fenster öffnen ",
    "The URL looks invalid": "die URL scheint ungültig zu sein",
    "We've sent you an email. Please click the link in the email to accept receiving notifications from i68.com.au.": "Wir haben Ihnen eine E-Mail geschickt. Bitte klicken Sie auf den Link in der E-Mail, um den Erhalt von Benachrichtigungen von i68.com.au zu akzeptieren",
    "Assignment begins in 2 hours": "Aufgabe beginnt in 2 Stunden ",
    "Hello": "Hallo",
    "This is a reminder that your assignment": "Dies ist eine Erinnerung, dass Ihr Auftrag / Ihre Aufgabe",
    "Time": "die Zeit",
    "Location": "der Ort",
    "Will begin in one hour from now.": "Es beginnt in eine Stunde ab jetzt ",
    "Regards": "Grüße ",
    "Slot Booked": "Slot gebucht",
    "Your ASSIGNMENT has been CREATED at": "Ihre/  Deine AUFGABE wurde ERSTELLT in",
    "My Assignments": "Meine Aufgaben",
    "Slot Canceled": "Slot abgesagt",
    "Your ASSIGNMENT has been CANCELLED at": "Ihre / Deine AUFGABE wurde ABGESAGT in",
    "Slot Deleted": "Slot gelöscht ",
    "Your ASSIGNMENT has been DELETED at": "Deine AUFGABE wurde GELÖSCHT in",
    "Slot Restored": "Slot wiederhergestellt ",
    "Your ASSIGNMENT has been RESTORED at": "Ihre / Deine AUFGABE wurde WIEDERHERGESTELLT in",
    "Account Created": "Ihr Konto/ Benutzerkonto/ Account  wurde erstellt. ",
    "Your account has been created in the i68 App. Please click the button below to login.": "Ihr Konto/ Benutzerkonto/ Account wurde in i68 App erstellt. Drucken Sie die Taste unten zu beginnen. ",
    "Your account has been created in the i68 App. You can use the following details to login.": "Ihr Konto/ Benutzerkonto/ Account wurde i68 App. erstellt.  Nutzen Sie folgende Daten zum Einloggen. ",
    "You can login using the following button": "Sie können sich einloggen- drücken Sie diese Taste ",
    "Please allow notifications from i68.com.au.": "Bitte die Benachrichtigung vom i.68.com.au zulassen ",
    "Will begin in 2 hours from now.": "Es beginnt in 2 Stunden ab jetzt ",
    "will begin in 2 hours from now.": "es beginnt in 2 Stunden ab jetzt ",
    "will begin in 2 hours or less.": "es beginnt in 2 Stunden oder weniger ",
    "Your assignment on location": "Deine Aufgabe beginnt in ( Standort)",
    "Your assignment at location": "Deine Zuordnung in (Standort)",
    "Select Groups": "Gruppe wählen ",
    "Donate": "spenden ",
    "Primary Group": "Beginner Gruppe ",
    "Search": "Suchen",
    "Support": "Unterstützung ",
    "My Account": "Mein Account ",
    "Repeat Weekly": "wiederhole wöchentlich ",
    "Create": "Erstellen",
    "Active": "aktiv",
    "Disabled": "behindert ",
    "Minimum duration is 30 minutes": "Maximale Laufzeit beträgt 3 Stunden ",
    "Maximum duration is 12 hours": "Maximale Laufzeit beträgt 12 Stunden ",
    "Change Your Timezone": "Deine Zeitzone ändern ",
    "Account Settings": "Account Einstellungen ",
    "Account": "Account ",
    "Your Timezone": "deine Zeitzone",
    "Are you sure you want to restore?": "Möchten Sie wiederherstellen?",
    "more": "mehr",
    "expand": "erweitern ",
    "Details": "Details/ Informationen ",
    "Show Expired": "zeige Abgelaufen",
    "Full": "voll / komplett ",
    "Available": "verfügbar ",
    "Change": "ändern / Änderung ",
    "Update Location": "Standort aktualisieren ",
    "Location Updated successfully": "Standort wurde erfolgreich aktualisiert ",
    "Move Up": "Nach Oben",
    "Move Down": "Nach Unten",
    "Order": "Bestellung ",
    "Confirm Request": "Anfrage bestätigen ",
    "ASSIGNMENT STARTING SOON at": "AUFGABE BEGINNT BALD IN",
    "ASSIGNMENT CREATED at": "AUFGABE ERSTELLT IN",
    "ASSIGNMENT CANCELED at": "AUFGABE ABGESAGT IN",
    "ASSIGNMENT RESTORED at": "AUFGABE WIEDERHERGESTELLT IN",
    "To view or manage your assignments click here": "Drucken Sie hier um deine Aufgaben zu sehen oder ändern ",
    "Your ASSIGNMENT is STARTING SOON at": "Deine AUFGABE beginnt BALD in",
    "ASSIGNMENT BEGINS tomorrow at": "AUFGABE BEGINNT Morgen um ",
    "Your ASSIGNMENT BEGINS tomorrow at": "Deine AUFGABE BEGINNT Morgen um",
    "Position": "Position ",
    "Download": "Herunterladen",
    "Locations": "Locations",
    "My events": "My events",
    "Settings": "Settings",
    "List": "List",
    "Map": "Map",
    "Calendar": "Calendar",
    "Events in next week": "Events in next week",
    "Other events in": "Other events in",
    "Coming soon events": "Coming soon events",
    "more required": "more required",
    "Open in Google maps": "Open in Google maps",
    "Date range": "Date range",
    "Next week at": "Next week at",
    "Also at": "Also at",
    "Coming soon at": "Coming soon at",
    "in": "in",
    "Geo-Location": "Geo-Location",
    "Place Name": "Place Name",
    "Directions": "Directions",
    "Registered users": "Registered users",
    "There is no registered users yet.": "There is no registered users yet.",
    "Attendees": "Attendees",
    "There are no attendees yet.": "There are no attendees yet.",
    "Cancel Event": "Cancel Event",
    "Join Event": "Join Event",
    "Joining": "Joining",
    "Cancelling": "Cancelling",
    "Danger message": "Danger message",
    "Warning message": "Warning message",
    "Successfully message": "Successfully message",
    "Missing slotId": "Missing slotId",
    "Missing userId": "Missing userId",
    "Slot not found.": "Slot not found.",
    "Slot occupied.": "Slot occupied.",
    "You joined the group!": "You joined the group!",
    "You cancelled the group.": "You cancelled the group.",
    "Something went wrong.": "Something went wrong.",
    "Are you sure you want to cancel this event?": "Are you sure you want to cancel this event?",
    "Missing slot.": "Missing slot.",
    "Missing location.": "Missing location.",
    "My Events": "My Events",
    "Leave Event": "Leave Event",
    "Leaving": "Leaving",
    "Image": "Image",
    "Menu": "Menu",
    "Your assignment has been created": "Your assignment has been created",
    "successfully": "successfully",
    "To view and manage your assignments click on button below": "To view and manage your assignments click on button below",
    "View my assignments": "View my assignments",
    "Description": "Description",
    "There is no description for this event.": "There is no description for this event.",
    "Redirecting error. Try again later.": "Redirecting error. Try again later.",
    "Join Beta": "Join Beta",
    "Join": "Join",
    "Application Version": "Application Version",
    "Beta": "Beta",
    "Cannot cancel as less than 2 hours left": "Eine Stornierung ist nicht möglich, da weniger als 2 Stunden übrig sind"
  },
  "it": {
    "Language": "Lingua",
    "Phone Number": "Numero Telefonico",
    "+1...": "+39...",
    "Password": "Parola d'ordine",
    "Login": "Accesso",
    "Name": "Nome",
    "January": "Gennaio",
    "February": "Febbraio",
    "March": "Marzo",
    "April": "Aprile",
    "May": "Maggio",
    "June": "Giugno",
    "July": "Luglio",
    "August": "Agosto",
    "September": "Settembre",
    "October": "Ottobre",
    "November": "Novembre",
    "December": "Dicembre",
    "Shift": "Turno",
    "Date": "Data",
    "Month": "Mese",
    "Day": "Giorno",
    "Person": "Persona",
    "Driver": "Autista",
    "Logout": "Disconnttersi",
    "Request": "Richiesta",
    "Open": "Aperto",
    "Add": "Aggiungere",
    "Edit": "Modificare",
    "User": "Usare",
    "Users": "Utente",
    "Enter": "Entrare",
    "Admin": "Amministratore",
    "Admin Panel": "Panello Amministrazione",
    "Save": "Salvare",
    "Make": "Creare",
    "Make Admin": "Creare Amministrazione",
    "Monday": "Lunedi",
    "Tuesday": "Martedi",
    "Wednesday": "Mercoledi",
    "Thursday": "Giovedi",
    "Friday": "Venerdi",
    "Saturday": "Sabato",
    "Sunday": "Domenica",
    "Something went wrong": "Qualcosa e andato storto",
    "Home": "Casa",
    "Canceled": "Annullata",
    "Cancel": "Annulla",
    "Restore": "Ristabilire",
    "Slots": "Posti",
    "Remove": "Rimuovere",
    "Delete": "Eliminare",
    "Delete User": "Eliminare Utente",
    "Are you sure you want to delete the user?": "Sei sicuro Di volere eliminare l'utente",
    "Are you sure you want to cancel?": "Sei sicuro Di volere eliminare l'utente",
    "Number of people": "Numero di persone",
    "Remove Location": "Rimuovere Localita",
    "Shift cancel message": "messaggio di turno annullato",
    "Assignments": "Compiti",
    "Choose Location": "Scegliere Localita",
    "Choose Group": "Scegliere gruppo",
    "Choose Language": "Scegliere lingua",
    "Choose Shift": "Scegliere turno",
    "Choose Images": "Scegliere imagine",
    "Choose Email Notification": "Scegliere indirizzo E-mail per Notifiche",
    "Group": "Gruppo",
    "Groups": "Gruppi",
    "Group Name": "Nome Gruppo",
    "Are you sure you want to delete this group?": "Sei sicuro di volere eliminare questo gruppo?",
    "Gender": "Genere",
    "Choose Gender": "Scegliere Genere",
    "Today": "Oggi",
    "Next Day": "Il Giorno Dopo",
    "Previous Day": "Giorno Precedente",
    "Repeat": "Ripetere",
    "Choose Date & Slots": "Scegliere la Data è il Turno",
    "Picture": "Foto",
    "Location Description": "Descrizione Località",
    "Email": "Email",
    "Do you want to book this slot?\n(It will be possible to cancel the duty no later than 2 hours before it starts)": "Vuoi prenotare questo turno?<br/>(sarà possibile annullare il turno massimo 2 ore prima dell'inizio turno)",
    "Create New Location": "Creare Una Località Nuova",
    "Edit Location": "Modificare Località ",
    "Positions": "Posizione",
    "Status": "Stato",
    "Timeframe": "Lasso di Tempo",
    "Background Color": "Colore di Sfondo",
    "Start Time": "Ora di Inizio",
    "End Time": "Ora di Fine",
    "Back": "Indietro",
    "Image removed": "Rimuovere Roto",
    "No slots booked": "Nessun Posto Prenotato ",
    "created succesfully": "Creato Con Successo ",
    "Email Notification": "Notifica Email",
    "Resend": "Rinvia ",
    "Send": "Invia",
    "Login with credentials": "Credenziali per Accedere",
    "Automatic login": "Accedere in Modo Automatico ",
    "Unavailable": "Non Disponibile ",
    "Expired": "Scaduto",
    "Start time cannot be before current time": "Ora di inizio non puoi essere precedente all'ora corrente",
    "End time cannot be before start time": "L'ora di fine non puo essere precedente all'ora di inizio",
    "Do you want to call this person?": "Vuoi chiamare questa persona",
    "You": "Tuo",
    "Are you sure you want to cancel this slot?": "Sei sicuro di volere annullare questo posto?",
    "Are you sure? \nThis action will delete the historical record for users previously at this location/ time/day on their ‘Assignments’ page.": "Sei sicuro?<br / > Questa azione eliminera il record storico per gli utenti precedentemente in questa posizione/ora/giorno nella pagina delle loro assegnazioni",
    "Are you sure? \nThis will permanently delete this slot and user data completely from the database. This action is irreversible.": "Sei sicuro?\n Wuesto eliminera definitivamente questo posto ei dati dell'uttente completamente dal datbase. Questa azione e irriversibile.",
    "Show All": "Mostra Tutto",
    "Male": "Uomo",
    "Female": "Uomo",
    "Activate": "Donna",
    "Deactivate": "Disattivare",
    "Next 7 Day": "Il Prossimo 7 Giorno",
    "Previous 7 Day": "Il 7 Giorno Precedente",
    "Notifications": "Notifiche",
    "This will open external link in new window": "Questo aprira il collegamento esterno in una nuova pagina",
    "The URL looks invalid": "l'URL Sembra non valido",
    "We've sent you an email. Please click the link in the email to accept receiving notifications from i68.com.au.": "Ti abbiamo inviato un email. Da cliccare sul collegamento nell'email per accettare di ricevere notifiche da i68.com.au.",
    "Assignment begins in 2 hours": "Incarico inizierà in 2 ore",
    "Hello": "Ciao",
    "This is a reminder that your assignment": "Questo e un promemoria per il tuo incarico",
    "Time": "Ora",
    "Location": "Localita",
    "Will begin in one hour from now.": "Tra un'ora iniziera",
    "Regards": "Saluti",
    "Slot Booked": "Posizione prenotata",
    "Your ASSIGNMENT has been CREATED at": "Il tuo COMPITO è stato CREATO in",
    "My Assignments": "I Miei Incarichi",
    "Slot Canceled": "Annullato Posto",
    "Your ASSIGNMENT has been CANCELLED at": "Il tuo COMPITO e stato CANCE alle",
    "Slot Deleted": "Posto Annullato",
    "Your ASSIGNMENT has been DELETED at": "Il tuo COMPITO è stato ANULLATTO alle",
    "Slot Restored": "Restaurato Posto",
    "Your ASSIGNMENT has been RESTORED at": "Il tuo COMPITO e stato RESTURATO alle",
    "Account Created": "Creato Conto",
    "Your account has been created in the i68 App. Please click the button below to login.": "Il to conto è stato creato nell'app i68. Cliccare sul pulsante in basso per accedere.",
    "Your account has been created in the i68 App. You can use the following details to login.": "Il to conto e stato creato nell'app i68. E possibile utilizzare i seguenti dettagli per accedere.",
    "You can login using the following button": "Puoi accedere utilizzando il seguente pulsante",
    "Please allow notifications from i68.com.au.": "Si prega di consentire le notifiche da i68.com.au.",
    "Will begin in 2 hours from now.": "Inizierà tra 2 ore.",
    "will begin in 2 hours from now.": "Inizierà tra 2 ore.",
    "will begin in 2 hours or less.": "Inizierà in meno di 2 ore.",
    "Your assignment on location": "Il tuo incarico sul posto",
    "Your assignment at location": "Il tuo incarico e posizione",
    "Select Groups": "Selezionare i Gruppi",
    "Donate": "Donare",
    "Primary Group": "Gruppo Primario",
    "Search": "Ricerca",
    "Support": "Supporto",
    "My Account": "Il Mio Conto",
    "Repeat Weekly": "Repetere Settimanalmente",
    "Create": "Creare",
    "Active": "Attivo",
    "Disabled": "Disabilitato",
    "Minimum duration is 30 minutes": "La durata minima e di 30 minuti",
    "Maximum duration is 12 hours": "La durata massima e di 12 ore",
    "Change Your Timezone": "Cambia il tuo fuso orario",
    "Account Settings": "Impostazioni del tuo conto",
    "Account": "Conto",
    "Your Timezone": "Il tuo fuso orario",
    "Are you sure you want to restore?": "Sei sicuro di volere restaurarlo?",
    "more": "piu",
    "expand": "espandere",
    "Details": "Dettagli",
    "Show Expired": "Mostra scaduto",
    "Full": "Pieno",
    "Available": "Disponibile ",
    "Change": "Cambio",
    "Update Location": "Aggiorna Posizione",
    "Location Updated successfully": "Posizione Aggiornata con Successo",
    "Move Up": "Muovere in su",
    "Move Down": "Muovere in basso",
    "Order": "Ordinare",
    "Confirm Request": "Conferma di richiesta",
    "ASSIGNMENT STARTING SOON at": "ASSEGNAZIONE IN INIZIO A BREVE presso",
    "ASSIGNMENT CREATED at": "ASSEGNAMENTO CREATO presso",
    "ASSIGNMENT CANCELED at": "ASSEGNAMENTO ANNULLATO a",
    "ASSIGNMENT RESTORED at": "ASSEGNAMENTO RIPRISTINO a",
    "To view or manage your assignments click here": "Per visualizzare o gestire i tuoi incarichi cliccare qui",
    "Your ASSIGNMENT is STARTING SOON at": "Il tuo ASSEGNAMENTO sta PER INIZIARE PRESTO alle",
    "ASSIGNMENT BEGINS tomorrow at": "L'ASSEGNAZIONE INIZIA domani alle",
    "Your ASSIGNMENT BEGINS tomorrow at": "Il tuo ASSEGNAMENTO INIZIA domani alle",
    "Position": "Posizione",
    "Download": "Caricare",
    "Locations": "Locations",
    "My events": "My events",
    "Settings": "Settings",
    "List": "List",
    "Map": "Map",
    "Calendar": "Calendar",
    "Events in next week": "Events in next week",
    "Other events in": "Other events in",
    "Coming soon events": "Coming soon events",
    "more required": "more required",
    "Open in Google maps": "Open in Google maps",
    "Date range": "Date range",
    "Next week at": "Next week at",
    "Also at": "Also at",
    "Coming soon at": "Coming soon at",
    "in": "in",
    "Geo-Location": "Geo-Location",
    "Place Name": "Place Name",
    "Directions": "Directions",
    "Registered users": "Registered users",
    "There is no registered users yet.": "There is no registered users yet.",
    "Attendees": "Attendees",
    "There are no attendees yet.": "There are no attendees yet.",
    "Cancel Event": "Cancel Event",
    "Join Event": "Join Event",
    "Joining": "Joining",
    "Cancelling": "Cancelling",
    "Danger message": "Danger message",
    "Warning message": "Warning message",
    "Successfully message": "Successfully message",
    "Missing slotId": "Missing slotId",
    "Missing userId": "Missing userId",
    "Slot not found.": "Slot not found.",
    "Slot occupied.": "Slot occupied.",
    "You joined the group!": "You joined the group!",
    "You cancelled the group.": "You cancelled the group.",
    "Something went wrong.": "Something went wrong.",
    "Are you sure you want to cancel this event?": "Are you sure you want to cancel this event?",
    "Missing slot.": "Missing slot.",
    "Missing location.": "Missing location.",
    "My Events": "My Events",
    "Leave Event": "Leave Event",
    "Leaving": "Leaving",
    "Image": "Image",
    "Menu": "Menu",
    "Your assignment has been created": "Your assignment has been created",
    "successfully": "successfully",
    "To view and manage your assignments click on button below": "To view and manage your assignments click on button below",
    "View my assignments": "View my assignments",
    "Description": "Description",
    "There is no description for this event.": "There is no description for this event.",
    "Redirecting error. Try again later.": "Redirecting error. Try again later.",
    "Join Beta": "Join Beta",
    "Join": "Join",
    "Application Version": "Application Version",
    "Beta": "Beta",
    "Cannot cancel as less than 2 hours left": "Meno di 2 ore non è possibile cancellare"
  }
};

module.exports = Translation;