import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CacheBuster from 'react-cache-buster';
import pkg from '../package.json';

ReactDOM.render(
  <CacheBuster
    currentVersion={pkg.version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
  >
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </CacheBuster>,
  document.getElementById('root')
);

window.addEventListener('load', () => {
  window.history.pushState({}, '');
});

window.addEventListener('popstate', (event) => {
  window.history.pushState({}, '');
});

function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);
  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
// window.registerWorkers = () => {
//   serviceWorkerRegistration.register({
//     scope: '/'
//   });
// };
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
