import LocalizedStrings from 'react-localization';
import Cookies from 'universal-cookie';

let Translation = require('./translation');

for (let [language] of Object.entries(Translation)) {
  for (let [original] of Object.entries(Translation[language])) {
    if (!Translation['en'][original]) {
      Translation['en'][original] = original;
    }
  }
}

const cookies = new Cookies();

let localization = new LocalizedStrings(Translation);

localization.setLanguage(cookies.get('language') || 'en');
localization.getLanguage(() => cookies.get('language'));
localization.setCookieLanguage = (language) => {
  cookies.set('language', language, { path: '/' });
  localization.setLanguage(language);
};

export default localization;